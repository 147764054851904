import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import * as boardSummary from '../board-summary';
import { getUserName, sortMembers } from '../../components/utils';
import merge from 'lodash-es/merge';
import sortBy from 'lodash-es/sortBy';
import orderBy from 'lodash-es/orderBy';
import uniq from 'lodash-es/uniq';
import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as routerSelectors from '../router/selectors.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as user from '../user';
import * as signup from '../signup';
import * as columnsSelectors from '../columns/selectors.js';
import * as boardUtils from './utils.js';
import Permission from './permission';
import { getIdWoPrefix } from '../../utils';
import { createSelector } from 'reselect';
import isEqual from 'lodash-es/isEqual.js';
import first from 'lodash-es/first.js';
import { state } from 'lit-element';
import { create } from 'lodash-es';
/**
 * Select board basic attributes
 */
export const attrs = createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'boards', boardId || router.selectors.pageBoardId(state)),
  (state, boardId) => firestoreRedux.selectors.doc(state, 'board-move-requests', `bmr_${getIdWoPrefix({ id: boardId, prefix: 'brd_' })}`),
  (_attrs, _moveRequestData) => {
    return boardUtils.attrs(_attrs, _moveRequestData);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @returns {Boolean} `true` when board is active.
 */
export const isBoardActive = createSelector(
  (state, boardId) => attrs(state, boardId),
  (attrs) => {
    return boardUtils.isBoardActive(attrs);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 100,
    },
  });

/**
 * @returns {Object} Hash of given board's team members. Key is the user Id & value is it's role in the board.
 */
export const members = createSelector(
  (state, boardId) =>
    firestoreRedux.selectors.docsByQueryResult(
      state,
      `board-team-members_boardId-${boardId || router.selectors.pageBoardId(state)}`,
      'board-team-members'
    ),
  (docs) => {
    let _members = {};
    forEach(docs, (doc) => {
      _members[doc.userId] = doc.role;
    });
    return _members;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * Sort members by roles and name.
 * @param {Array} aMembers - Passed members array.
 * @protected
 */
const _oRolesNumber = {
  "VISITOR": 1,
  "TEAM_MEMBER": 2,
  "BOARD_ADMIN": 3,
  "BOARD_OWNER": 4
};

function _sortMembers(aMembers) {
  return aMembers.sort((m1, m2) => {
    let role1 = m1.role[0];
    let role2 = m2.role[0];

    if (role1 && role2 && _oRolesNumber[role1] < _oRolesNumber[role2]) {
      return 1;
    }
    if (role1 && role2 && _oRolesNumber[role1] > _oRolesNumber[role2]) {
      return -1;
    }


    if (!m1.user || !m2.user) {
      return -1
    }

    let sFullName1 = m1.user.firstName || '';
    if (m1.user.lastName) {
      sFullName1 = sFullName1 + ' ' + m1.user.lastName;
    }

    let sFullName2 = m2.user.firstName || '';
    if (m2.user.lastName) {
      sFullName2 = sFullName2 + ' ' + m2.user.lastName;
    }

    if (sFullName1.toLowerCase() < sFullName2.toLowerCase()) {
      return -1;
    }
    if (sFullName1.toLowerCase() > sFullName2.toLowerCase()) {
      return 1;
    }
    return 0;
  });
}

export const membersLists2 = createSelector(
  (state, members, ownerId, containerType) => members,
  (state, members, ownerId, containerType) => ownerId,
  (state, members, ownerId, containerType) => containerType,
  (state, members, ownerId, containerType) => firestoreRedux.selectors.collection(state, 'users'),
  (members, ownerId, containerType, _users) => {
    if(isEmpty(members)) {
      return;
    }

    let aAssignments = [];
    let hasBoardOwner = false;
    forEach(members, (role, memberId) => {
      let sRole = role
      let oUser = _users && _users[memberId] || {};
      if (!isEmpty(oUser)) {
        if (ownerId === oUser.id) {
          let role = ['BOARD_OWNER'];
          if (sRole) {
            role.push(sRole);
          }
          aAssignments.push({ user: oUser, role: uniq(role) });
          hasBoardOwner = true;
        } else {
          aAssignments.push({user: oUser, role:uniq([sRole])});
        }
      }
    });

    // When Board Owner is not in members list, Render board owner in Board item.
    if (containerType === 'BOARD_ITEM' && !hasBoardOwner) {
      const ownerDetail = _users && _users[ownerId] || {};
      aAssignments.push({ user: ownerDetail, role: ['BOARD_OWNER'] });
    }

    return _sortMembers(aAssignments);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @param {*}
 * 	@property {Object} state Redux state.
 * 	@property { String } boardId - Board ID that you want give board members model/array.
 * @return { Array } - Board members model.
 *
 * E.g.: return value
 *  `[{members1}, {members2}, {memebrs3}]`
 */
export const membersList = createSelector(
  members,
  (state) => firestoreRedux.selectors.collection(state, 'users'),
  (_members, _users) => {
    let list = [];
    forEach(_members, (role, id) => {
      const userAttrs = get(_users, id);
      let service = '';
      if (userAttrs) {
        service = find(get(userAttrs, `auths`), {
          id: userAttrs.primaryAuthId,
        });
      }

      const name = getUserName(userAttrs);
      const email = (userAttrs && userAttrs.email) || '';
      list.push({ id, role, user: { ...userAttrs, service }, name, email, pending: get(userAttrs, 'pending') });
    });
    return sortMembers(list || []);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param {*}
 * 	@property {Object} state Redux state.
 * 	@property {String} boardId Board Id
 * @return list of board team including Board Owner whether it's part of team or not.
 *
 */
export const boardTeam = createSelector(
  membersList,
  attrs,
  (state) => firestoreRedux.selectors.collection(state, 'users'),
  (members, boardAttrs, users) => {
    const ownerId = get(boardAttrs, 'ownerId');
    const ownerAttrs = get(users, ownerId);
    const owner = {
      id: ownerId,
      role: 'BOARD_OWNER',
      user: ownerAttrs,
      name: getUserName(ownerAttrs),
      isOwner: true,
      email: get(ownerAttrs, 'email', ''),
    };
    if (members && owner) {
      let allMembers = [];
      for (let member of members) {
        if (member.user) {
          if (member.id != owner.id) {
            member.allEmails = userEmails(member.user);
            member.additionalEmails = userEmails(member.user, false);
            allMembers.push(member);
          } else {
            owner.role = member.role;
            owner.allEmails = userEmails(member.user);
            owner.additionalEmails = userEmails(member.user, false);
          }
        }
      }
      const sortedList = sortBy(allMembers, (o) => o.user.pending);
      sortedList.unshift(owner);
      return sortedList;
    }
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);


/**
 * Determines if the board has a team associated with it.
 * @param {Object} state - The Redux state.
 * @returns {boolean} - True if the board has a team, false otherwise.
 */
export const hashTeam = createSelector(
  boardTeam,
  (team) => {
    return team && team.length > 1;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {Array} selected cardIds from board selected models.
 */
export const selectedCards = (state) => get(state, 'board.selectedCards');

/**
 * @returns {Number} selected column id.
 */
export const selectedColumn = (state) => get(state, `board.selectedColumn`);

/**
 * @returns {Number} last selected card Id from board selected models.
 */
export const lastSelectedCardId = createSelector(selectedCards, (_selectedCards) => {
  return isEmpty(_selectedCards) ? 0 : _selectedCards[_selectedCards.length - 1];
});

/**
 * @returns {Boolean} board stared or not.
 */
export const dragStarted = (state) => get(state, 'board.cardDrag.start');

/**
 * @returns {Array} board drag cards ids.
 */
export const dragCards = (state) => get(state, 'board.cardDrag.cards');

/**
 * @returns {Array} List of cards for given column. e.g. [{id, order}, ...]
 */
export const cardsByColumn = createSelector(
  (state, columnId, boardId) => columnId,
  (state, columnId, boardId) => columnsSelectors.column(state, columnId),
  (state, columnId, boardId) => firestoreRedux.selectors.queryStatus(state, `cards_${boardId || router.selectors.pageBoardId(state)}`),
  (state, columnId, boardId) => firestoreRedux.selectors.queryStatus(state, `done_cards_${boardId || router.selectors.pageBoardId(state)}`),
  (state, columnId, boardId) => firestoreRedux.selectors.queryStatus(state, `trash_cards_${boardId || router.selectors.pageBoardId(state)}`),
  (state, columnId, boardId) => otherCards(state, boardId || router.selectors.pageBoardId(state)),
  (state, columnId, boardId) => doneCards(state, boardId || router.selectors.pageBoardId(state)),
  (state, columnId, boardId) => trashCards(state, boardId || router.selectors.pageBoardId(state)),
  (columnId, column, otherCardsReqStatus, doneCardsReqStatus, trashCardsReqStatus, otherCards, doneCards, trashCards) => {
    if(column && column.type === 'DONE') {
      const cards = orderBy(filter(doneCards, { columnId }), ['lastMovedAt', 'columnOrder'], ['desc', 'asc']);
      return (!doneCardsReqStatus || doneCardsReqStatus === 'PENDING') && isEmpty(cards) ? undefined: cards;
    }

    if(column && column.type === 'TRASH') {
      const cards = orderBy(filter(trashCards, { columnId }), ['lastMovedAt', 'columnOrder'], ['desc', 'asc']);
      return (!trashCardsReqStatus || trashCardsReqStatus === 'PENDING') && isEmpty(cards) ? undefined: cards;
    }
    const cards = sortBy(filter(otherCards, { columnId }), ['columnOrder']);
    return (!otherCardsReqStatus || otherCardsReqStatus === 'PENDING') && isEmpty(cards) ? undefined: cards;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @returns {Array} List of other columns cards for given board. e.g. [{id, order}, ...]
 */
export const otherCards = createSelector(
  (state, boardId) => firestoreRedux.selectors.docsByQueryResult(state, `cards_${boardId || router.selectors.pageBoardId(state)}`, 'cards'),
  (cards) => {
    return cards;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @returns {Array} List of done cards for given board. e.g. [{id, order}, ...]
 */
export const doneCards = createSelector(
  (state, boardId) => firestoreRedux.selectors.docsByQueryResult(state, `done_cards_${boardId || router.selectors.pageBoardId(state)}`, 'cards'),
  (cards) => {
    return cards;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @returns {Array} List of done cards for given board. e.g. [{id, order}, ...]
 */
export const trashCards = createSelector(
  (state, boardId) => firestoreRedux.selectors.docsByQueryResult(state, `trash_cards_${boardId || router.selectors.pageBoardId(state)}`, 'cards'),
  (cards) => {
    return cards;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @return {Number} Count of cards in given column.
 */
export const cardsByColumnCount = createSelector(
  cardsByColumn,
  (cards) => {
    return cards && cards.length || 0;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @params {*}
 * 	@property {Object} state Redux state.
 * 	@property {String} boardId Board Id
 * @returns {Object} first board card details.
 */
export const firstColumnCardDetails = createSelector(
  cardsByColumn,
  (cards) => {
    cards = cards || [];
    const card = first(cards);
    if (isEmpty(card)) {
      return;
    }
    return { cardId: card.id, columnId: card.columnId };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 100,
    },
  }
);

/**
 * first card of given column.
 */
export const firstColumnCardId = createSelector(
  (state, columnId) => firstColumnCardDetails(state, columnId),
  (card) => {
    return card && card.cardId || '';
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {String} type of board for given boardId.
 * 									 Possible value is `TASKBOARD` and `WHITEBOARD`.
 */
export const boardType = (state, boardId) => {
  boardId = boardId || router.selectors.pageBoardId(state);
  const doc = firestoreRedux.selectors.doc(state, 'boards', boardId);
  return get(doc, 'type');
};

/**
 * @param { String } boardId - Id of board for which check data is loaded or not.
 * `true` when board's required data is loaded. e.g. board's attrs & columns.
 */
export const loaded = (state, boardId) => {
  boardId = boardId || router.selectors.pageBoardId(state);
  return get(state, `board.loaded.${boardId}`);
};

/**
 * @returns {Boolean} `true` when board is private to user.
 */
export const privateBoard = (state) => router.selectors.privatePage(state);

/**
 * @param {*}
 * 	@property { Object } state - Redux state.
 * 	@property { String } boardId - Id of board which columns should be return in sort order
 * @returns {Array} sorted list of board columns.
 */
export const columns = createSelector(
  (state, boardId) =>
    firestoreRedux.selectors.docsByQueryResult(state, `board_columns_${boardId || router.selectors.pageBoardId(state)}`, 'columns'),
  (_columns) => {
    let newColumns = sortBy(_columns || [], ['order', 'name']);

    //Sorting bassed on column type.
    var typeOrder = ['OTHER', 'DONE', 'TRASH'];
    newColumns.sort(function (column1, column2) {
      const type1 = column1 && column1.type || 'OTHER';
      const type2 = column2 && column2.type || 'OTHER';
      return typeOrder.indexOf(type1) - typeOrder.indexOf(type2);
    });

    return newColumns;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state - Redux state
 * @param {string} boardId - Id of the board
 * @returns {Array} - List of columns without hidden columns
 */
export const withoutHiddenColumns = createSelector(
  (state, boardId) => app.selectors.wideLayout(state),
  (state, boardId) => columns(state, boardId),
  (state, boardId) => columnsSelectors.hiddenColumns(state, boardId),
  (wideLayout, columns, hiddenColumns) => {
    return columns.reduce((visibleColumns, column) => {
      const columnType = column?.type || 'OTHER';
      const hiddenColumn = hiddenColumns.find(hc => hc?.columnId === column.id);
      const isHidden = hiddenColumn
        ? hiddenColumn.hidden ?? false
        : !columnsSelectors.defaultColumnVisibility(columnType, wideLayout);

      if (!isHidden) {
        visibleColumns.push({ ...column, hidden: false });
      }
      return visibleColumns;
    }, []);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {Boolean} `true` when board has given column.
 */
export const hasColumn = createSelector(
  (state, boardId, columnId) => columns(state, boardId || router.selectors.pageBoardId(state)),
  (state, boardId, columnId) => columnId,
  (_columns, columnId) => {
    let _hasColumn = false;
    forEach(_columns, (column) => {
      if(column && column.id && column.id === columnId){
        _hasColumn = true;
        return false;
      }
    });
    return _hasColumn;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param {*}
 * 	@property {Object} state Redux state
 * 	@property {String} boardId - Id of board which effective permission will be computed
 * @return {String} - Role of current user into given board.
 */
export const currentUserBoardRole = createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'boards', boardId),
  (state) => auth.selectors.currentUserId(state),
  (state, boardId) => firestoreRedux.selectors.docsByQueryResult(state, `board-team-members_boardId-${boardId}`, `board-team-members`),
  (boardDetails, userId, teamMembers) => {
    if (isEmpty(boardDetails) || !userId) {
      return '';
    }

    if (userId === boardDetails.ownerId) {
      return 'BOARD_OWNER';
    }

    const doc = find(teamMembers, { userId });
    return (doc && doc.role) || '';
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param {*}
 * 	@property {Objct} state - Redux state.
 * 	@property {String} boardId - Id of board which effective permission will be computed
 * @return {Object} - Perission instance.
 */
export const boardEffectivePermission = createSelector(
  attrs,
  auth.selectors.currentUserId,
  auth.selectors.impersonatedUser,
  currentUserBoardRole,
  auth.selectors.accessibleAccounts,
  (state) => firestoreRedux.selectors.docsByQueryResult(state, `accessible-accounts`, `account-team-members`),
  (state) => firestoreRedux.selectors.collection(state, 'account-cloud-stores'),
  (boardDetails, userId, impersonatedUser, boardRole, accessibleAccounts, accTeamMembers, accountCloudStores) => {
    const accountId = get(boardDetails, 'accountId');
    const accTeamMember = find(accTeamMembers, { accountId });
    const nextPreferredStorage = get(accountCloudStores, `acs_${getIdWoPrefix({ id: accountId, prefix: 'acc_' })}.nextPreferredStore`);

    if (get(boardDetails, 'privacy') === 'PUBLIC' && (!user || !accessibleAccounts.includes(accountId))) {
      return new Permission('READ');
    }

    if (!userId || isEmpty(boardDetails) || (isEmpty(accTeamMember) && boardRole !== 'BOARD_OWNER')) {
      return new Permission('NONE');
    }

    const accountRole = get(accTeamMember, 'role');
    let permission = 'NONE';
    //Find permission based on user's role on board, board privacy, board owner and account team
    if (boardRole === 'BOARD_OWNER') {
      permission = 'OWNER';
    } else if (boardRole === 'BOARD_ADMIN') {
      permission = 'ADMIN';
    } else if (boardRole === 'TEAM_MEMBER') {
      permission = 'WRITE';
    } else if (
      boardRole === 'VISITOR' ||
      boardDetails.privacy === 'PUBLIC' ||
      (boardDetails.privacy === 'MY_ACCOUNT_TEAM' && accountRole)
    ) {
      permission = 'READ';
    }
    //If user has write permission but board is marked as archived or trashed
    if (boardDetails.status === 'TRASHED' || boardDetails.status === 'ARCHIVED') {
      permission = 'READ';
    }
    //If board move is in progress or failed.
    if (boardDetails.movingStatus === 'ACCEPTED' || boardDetails.movingStatus === 'FAILED') {
      permission = 'READ';
    }

    if (!!nextPreferredStorage) {
      permission = 'READ';
    }

    if(impersonatedUser) {
      permission = 'READ';
    }

    if(boardDetails.initialized === false) {
      permission = 'READ';
    }
    
    return new Permission(permission);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * Start board toolbar "zoom" action selector.
 */
export const isZoomOutMode = (state) => router.selectors.zoomOutMode(state);

/**
 *
 * @param {Object} state Redux state
 * @param {String} boardId Board Id.
 * @returns {Boolean}
 */
export const autoNumberEnabled = createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'boards', boardId),
  (doc) => get(doc, 'showCardRefNo'),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param
 *  @property {Object} state Redux state
 *  @property {String} templateId Template Id.
 * @returns {Boolean} `true` when current user can use the given template.
 */
export const useTemplate = createSelector(
  ({ state, templateId }) => firestoreRedux.selectors.doc(state, 'boards', templateId || router.selectors.pageBoardId(state)),
  ({ state, templateId }) => boardEffectivePermission(state, templateId || router.selectors.pageBoardId(state)),
  ({ state, accountId }) => accountEffectivePermission({ state, accountId }),
  ({ state }) => auth.selectors.currentUserId(state),
  ({ state }) => auth.selectors.writableAccessibleAccounts(state),
  (templateDetail, permission, accountPermission, userId, writableAccessibleAccounts) => {
    //If Template detail is not loaded OR current detail is not template detail.
    if (isEmpty(templateDetail) || !templateDetail.template) {
      return false;
    }

    //If template is archived or trashed.
    if (templateDetail.status !== 'ACTIVE') {
      return false;
    }

    if (userId && (!writableAccessibleAccounts || writableAccessibleAccounts.length === 0)) {
      return false;
    }

    //If template is public
    if (templateDetail.privacy === 'PUBLIC') {
      return true;
    }

    //If current user is owner of given template
    if (templateDetail.ownerId === userId) {
      return true;
    }

    if (permission && permission.hasWrite()) {
      return true;
    }

    return (accountPermission && accountPermission.hasWrite()) || false;
  },
  {
    memoizeOptions: {
      maxSize: 200,
    },
  }
);

/**
 * @param {*}
 * 	@property {Object} state Redux state
 *  @property {String} accountId Account Id.
 * @returns {Object} Instance of `Permisson`.
 */
export const accountEffectivePermission = createSelector(
  ({ state, accountId }) => accountId || router.selectors.accountId(state),
  ({ state }) => auth.selectors.currentUserAccessibleAccount(state),
  ({ state }) => auth.selectors.currentUserOwnedAccounts(state),
  (accountId, accounts, ownedAccounts) => {
    let permission = 'NONE';
    if (isEmpty(accounts) && isEmpty(ownedAccounts)) {
      return new Permission(permission);
    }

    if (ownedAccounts.includes(accountId)) {
      permission = 'OWNER';
    } else if (get(accounts, accountId) === 'ACCOUNT_ADMIN') {
      permission = 'ADMIN';
    } else if (get(accounts, accountId) === 'TEAM_MEMBER') {
      permission = 'WRITE';
    } else if (get(accounts, accountId) === 'VISITOR') {
      permission = 'READ';
    }
    return new Permission(permission);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @returns Additionl Email details e.g `{count: 3, emails: "t3@gmail.com, t4@gail.com"}`
 * @param {Object} userId User attrs
 * @allEmails {Boolean} If `true` returns all emails otherwise only additional emails.
 */
const userEmails = createSelector(
  (user) => user,
  (user, allEmails = true) => allEmails,
  (user, allEmails) => {
    const auths = user.auths;
    let count = 0;
    let emails = [];
    forEach(auths, (auth) => {
      if (allEmails || user.email !== auth['email']) {
        count += 1;
        emails.push(auth['email']);
      }
    });
    return { count, emails };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 300,
    },
  }
);

/**
 * @returns List of known users e.g ([{user, email, fullName}, ...])
 */
export const knownUsers = createSelector(
  (state) => get(state, `board.knownUsers`),
  (state) => firestoreRedux.selectors.collection(state, 'users'),
  (knownUsers, allUsers) => {
    let list = [];
    forEach(knownUsers, (knownUser) => {
      const user = allUsers[knownUser.id];
      if (user) {
        const name = getUserName(user);
        const email = user.email;
        const additionalEmails = userEmails(user, false);
        list.push({ user, email, name: name.trim() || email, additionalEmails });
      }
    });
    return list;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

/**
 * @returns Filtered list of known users. (excludes excluded users & filters it by name, primary email & additional emails)
 * @param {*}
 * 	@property {Object} state Redux state.
 * 	@property {String} email Email
 * 	@property {Array} excludedUsers Excluded Users
 */
export const filteredKnownUsers = createSelector(
  ({ state }) => knownUsers(state),
  ({ email }) => email,
  ({ excludedUsers }) => excludedUsers,
  ({ excludedEmails }) => excludedEmails,
  (_knownUsers, email, excludedUsers = [], excludedEmails = []) => {
    const filteredUsers = filter(_knownUsers, (knownUser) => {
      if (excludedUsers.includes(knownUser.user.id)) {
        return false;
      }

      const knownUserName = (knownUser && knownUser.name && knownUser.name.toLowerCase()) || '';
      const knownUserEmail = (knownUser && knownUser.email && knownUser.email.toLowerCase()) || '';
      if (!knownUserEmail) {
        return false;
      }

      if (excludedEmails.includes(knownUserEmail)) {
        return false;
      }

      if (!email) {
        return true;
      } else {
        const query = email.toLowerCase();

        //Filter on name & email.
        if (knownUserName.includes(query) || knownUserEmail.includes(query)) {
          return true;
        }

        //Filter on additional emails.
        const additionalEmails = get(knownUser, 'additionalEmails.emails', []);
        const filteredEmails = filter(additionalEmails, (email) => {
          return email.toLowerCase().includes(query);
        });
        return !isEmpty(filteredEmails);
      }
    });
    return filteredUsers;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @return Redux request detail.
 * @param {Object} state Redux state
 * @param {Number} requestId Requester Id.
 */
export const requestById = (state, requestId) => get(state, `board.requests.${requestId}`);

/**
 * @returns {Boolean} `true` when board welcome dialog presented.
 */
export const welcomeDialogPresented = createSelector(
  (state, boardId) => firestoreRedux.selectors.queryStatus(state, `user-board-ui_${boardId || routerSelectors.pageBoardId(state)}_${auth.selectors.currentUserId(state)}`),
  (state, boardId) => firestoreRedux.selectors.docsByQueryResult(state, `user-board-ui_${boardId || routerSelectors.pageBoardId(state)}_${auth.selectors.currentUserId(state)}`, `user-board-ui`),
  (userBoardUIqueryStatus, userBoardUIDetails) => {
    if (!userBoardUIqueryStatus || userBoardUIqueryStatus === 'PENDING') {
      return true;
    }

    const welcomeDialogDetails = find(userBoardUIDetails, { type: 'board-ui-welcome-dialog' });
    return (welcomeDialogDetails && welcomeDialogDetails.presented) || false;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} `true` when use template welcome dialog is open.
 */
export const useTemplateWelcomeDialogOpened = createSelector(
  auth.selectors.impersonatedUser,
  routerSelectors.pageName,
  routerSelectors.pageBoardId,
  (state) => app.selectors.isVideoAvailable(state, 'use-template'),
  (state) => isTemplate(state, routerSelectors.pageBoardId(state)),
  (state) =>
    firestoreRedux.selectors.doc(state, `user-ui`, `utwd_${getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`),
  signup.selectors.signupDetails,
  (impersonatedUser, pageName, boardId, videoAvailable, _isTemplate, useTemplateWelcomeDialogDetails, signupDetails) => {
    if(impersonatedUser) {
      return false;
    }

    if (pageName !== 'BOARD' || _isTemplate || !videoAvailable) {
      return false;
    }

    if (get(useTemplateWelcomeDialogDetails, 'presented')) {
      return false;
    }

    const signupSource = get(signupDetails, 'source');
    const signupBoardId = get(signupDetails, 'boardId');
    return signupSource === 'USE_TEMPLATE' && signupBoardId == boardId;
  }
);

/**
 * @returns {Boolean} `true` when current user is participant in given board or not and user role is not `VISITOR`.
 */
export const isCurrentUserParticipant = createSelector(
  ({ state, boardId }) => boardTeam(state, boardId),
  ({ state }) => auth.selectors.currentUserId(state),
  (members, currentUserId) => {
    let inBoardTeam = false;
    if (!isEmpty(members)) {
      forEach(members, (member) => {
        if (member && member.id == currentUserId && member.role !== 'VISITOR') {
          inBoardTeam = true;
          return false;
        }
      });
    }
    return inBoardTeam;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} taskboard participant video show or not for current board.
 */
export const needToShowTaskboardParticipantVideo = createSelector(
  routerSelectors.pageName,
  routerSelectors.pageType,
  (state) => app.selectors.isVideoAvailable(state, 'taskboard-participant'),
  signup.selectors.isNewUser,
  auth.selectors.currentUserId,
  (state) => firestoreRedux.selectors.collection(state, `user-ui`),
  (state) => isCurrentUserParticipant({ state, boardId: routerSelectors.pageBoardId(state) }),
  (_pageName, _pageType, videoAvailable, isNewUser, userId, userUIDetails, _isCurrentUserParticipant) => {
    if (_pageName !== 'BOARD' || _pageType !== 'TASKBOARD' || !videoAvailable) {
      return false;
    }

    if (!isNewUser) {
      return false;
    }

    if (!userId) {
      return false;
    }
    const taskboardParticipantVideo = find(userUIDetails, { userId, type: 'taskboard-participant-video' });
    if (taskboardParticipantVideo && taskboardParticipantVideo.watched) {
      return false;
    }

    return _isCurrentUserParticipant;
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} taskboard non participant video show or not for current user.
 */
export const needToShowTaskboardNonParticipantVideo = createSelector(
  routerSelectors.pageName,
  routerSelectors.pageType,
  (state) => app.selectors.isVideoAvailable(state, 'taskboard-non-participant'),
  signup.selectors.isNewUser,
  auth.selectors.currentUserId,
  (state) => firestoreRedux.selectors.collection(state, `user-ui`),
  (state) => isCurrentUserParticipant({ state, boardId: routerSelectors.pageBoardId(state) }),
  (_pageName, _pageType, videoAvailable, isNewUser, userId, userUIDetails, _isCurrentUserParticipant) => {
    if (_pageName !== 'BOARD' || _pageType !== 'TASKBOARD' || !videoAvailable) {
      return false;
    }

    if (!isNewUser) {
      return false;
    }

    if (!userId) {
      return false;
    }
    const taskboardParticipantVideo = find(userUIDetails, { userId, type: 'taskboard-participant-video' });
    if (taskboardParticipantVideo && taskboardParticipantVideo.watched) {
      return false;
    }

    const taskboardNonParticipantVideo = find(userUIDetails, { userId, type: 'taskboard-non-participant-video' });
    if (taskboardNonParticipantVideo && taskboardNonParticipantVideo.watched) {
      return false;
    }

    return !_isCurrentUserParticipant;
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} whiteboard participant video show or not for current board.
 */
export const needToShowWhiteboardParticipantVideo = createSelector(
  routerSelectors.pageName,
  routerSelectors.pageType,
  (state) => app.selectors.isVideoAvailable(state, 'whiteboard-participant'),
  signup.selectors.isNewUser,
  auth.selectors.currentUserId,
  (state) => firestoreRedux.selectors.collection(state, `user-ui`),
  (state) => isCurrentUserParticipant({ state, boardId: routerSelectors.pageBoardId(state) }),
  (_pageName, _pageType, videoAvailable, isNewUser, userId, userUIDetails, _isCurrentUserParticipant) => {
    if (_pageName !== 'BOARD' || _pageType !== 'WHITEBOARD' || !videoAvailable) {
      return false;
    }

    if (!isNewUser) {
      return false;
    }

    if (!userId) {
      return false;
    }
    const whiteboardParticipantVideo = find(userUIDetails, { userId, type: 'whiteboard-participant-video' });
    if (whiteboardParticipantVideo && whiteboardParticipantVideo.watched) {
      return false;
    }

    return _isCurrentUserParticipant;
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} whiteboard non participant video show or not for current user.
 */
export const needToShowWhiteboardNonParticipantVideo = createSelector(
  routerSelectors.pageName,
  routerSelectors.pageType,
  (state) => app.selectors.isVideoAvailable(state, 'whiteboard-non-participant'),
  signup.selectors.isNewUser,
  auth.selectors.currentUserId,
  (state) => firestoreRedux.selectors.collection(state, `user-ui`),
  (state) => isCurrentUserParticipant({ state, boardId: routerSelectors.pageBoardId(state) }),
  (_pageName, _pageType, videoAvailable, isNewUser, userId, userUIDetails, _isCurrentUserParticipant) => {
    if (_pageName !== 'BOARD' || _pageType !== 'WHITEBOARD' || !videoAvailable) {
      return false;
    }

    if (!isNewUser) {
      return false;
    }

    if (!userId) {
      return false;
    }
    const whiteboardParticipantVideo = find(userUIDetails, { userId, type: 'whiteboard-participant-video' });
    if (whiteboardParticipantVideo && whiteboardParticipantVideo.watched) {
      return false;
    }

    const whiteboardNonParticipantVideo = find(userUIDetails, { userId, type: 'whiteboard-non-participant-video' });
    if (whiteboardNonParticipantVideo && whiteboardNonParticipantVideo.watched) {
      return false;
    }

    return !_isCurrentUserParticipant;
  }
);

/**
 * @returns {Array} List of current board team members ids.
 */
export const boardTeamMembersIds = createSelector(
  (state) => firestoreRedux.selectors.docsByQueryResult(state, `board-team-members_boardId-${router.selectors.pageBoardId(state)}`, 'board-team-members'),
  (currentBoardMembers) => {
    let list = [];
    forEach(currentBoardMembers, (member) => {
      list.push(member.userId);
    });
    return list;
  }
);

/**
 * @returns { Boolean } `true` When current board is active, it's not created by current user &
 * 															current user is member of board team.
 */
export const needToLoadWelcomeDetail = createSelector(
  welcomeDialogPresented,
  useTemplateWelcomeDialogOpened,
  auth.selectors.currentUserId,
  auth.selectors.impersonatedUser,
  boardTeamMembersIds,
  attrs,
  (_welcomeDialogPresented, _useTemplateWelcomeDialogOpened, currentUserId, impersonatedUser, _boardTeammemberIds, _attrs) => {
    return (
      !impersonatedUser &&
      !_welcomeDialogPresented &&
      !_useTemplateWelcomeDialogOpened &&
      _attrs &&
      _boardTeammemberIds &&
      _attrs.status === 'ACTIVE' &&
      _attrs.createdBy !== currentUserId &&
      _boardTeammemberIds.includes(currentUserId) &&
      !_attrs.moveInProgress
    );
  }
);

/**
 * @returns {Object} Welcome detial. When board is not created by current user & current user has explicit role in board team.
 */
export const welcomeDetail = createSelector(
  auth.selectors.currentUserId,
  attrs,
  membersList,
  welcomeDialogPresented,
  (state) => firestoreRedux.selectors.collection(state, 'users'),
  (state, boardId) => get(state, `board.cardSummary.${boardId}`, {}),
  (currentUserId, _attrs, _team, _welcomeDialogPresented, users, cardSummary) => {
    if (
      !currentUserId ||
      isEmpty(_attrs) ||
      isEmpty(_team) ||
      _welcomeDialogPresented ||
      _attrs.status !== 'ACTIVE' ||
      currentUserId == _attrs.createdBy ||
      _attrs.moveInProgress
    ) {
      return {};
    }

    const currentUseTeamDetails = find(_team, { id: currentUserId });
    if (isEmpty(currentUseTeamDetails)) {
      return {};
    }

    let createdByName = '';
    if (_attrs.createdBy) {
      const createdBy = get(users, _attrs.createdBy);
      createdByName = getUserName(createdBy);
    }

    const currentUserRole = (currentUseTeamDetails && currentUseTeamDetails.role) || '';
    const boardAdmins = filter(_team, { role: 'BOARD_ADMIN' }) || [];
    const membersCount = (_team && _team.length) || 0;
    return merge(
      {},
      { createdBy: _attrs.createdBy, createdAt: _attrs.createdAt, type: _attrs.type },
      { card: cardSummary },
      { boardAdmins, currentUserRole, membersCount, createdByName }
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * Determines that is board being transfered or not.
 * @param {String} boardId Board Id
 */
export const isBoardLocked = createSelector(
  ({ state, boardId }) => attrs(state, boardId || router.selectors.pageBoardId(state)),
  (doc) => {
    const movingStatus = get(doc, 'movingStatus');
    const initialized = get(doc, 'initialized');
    return movingStatus === 'ACCEPTED' || movingStatus === 'FAILED';
  },
  {
    memoizeOptions: {
      maxSize: 200,
    },
  }
);

/**
 * @returns {String} Board Name
 * @param {Object} state State
 * @param {String} boardId Board Id
 */
export const boardName = (state, boardId) => get(firestoreRedux.selectors.doc(state, 'boards', boardId || router.selectors.pageBoardId(state)), 'name');

/**
 * @param {Object} state redux state
 * @param {String} templateId Template Id
 * @returns {Boolean} `true` when given id is template.
 */
export const isTemplate = (state, templateId) => {
  templateId = templateId || router.selectors.pageBoardId(state) || router.selectors.dialogBoardId(state);
  const attrs = firestoreRedux.selectors.doc(state, 'boards', templateId);
  return get(attrs, 'template', false);
};

/**
 * @returns {String} Board Description
 * @param {Object} state State
 * @param {String} boardId Board Id
 */
export const boardDescription = (state, boardId) => {
  boardId = boardId || router.selectors.pageBoardId(state);
  const doc = firestoreRedux.selectors.doc(state, 'boards', boardId);
  return get(doc, 'description', '');
};

/**
 * @returns {String} template category for given templateID.
 * @param {Object} state State
 * @param {String} templateId template Id
 */
export const templateCategory = (state, templateId) => {
  const doc = firestoreRedux.selectors.doc(state, 'boards', templateId);
  return get(doc, 'templateCategory', '');
};

/**
 * @returns {String} board language for given board.
 * @param {Object} state State
 * @param {String} boardId board Id
 */
export const lang = (state, boardId) => {
  const doc = firestoreRedux.selectors.doc(state, 'boards', boardId);
  return get(doc, 'lang', 'en') || 'en';
};

/**
 * @returns String e.g 'IN_PROGRESS' or 'SUCCESS'
 */
export const cardCopyRequestStatus = (state, columnId) => get(state, `board.requests.${columnId}.status`);

/**
 * @returns {Boolean} board copy is inprogress or not.
 */
export const boardCopyInprogress = (state) => get(state, `board.boardCopyInprogress`);

/**
 *
 * @param {Object} state Redux state
 * @returns Privacy of current board. e.g 'PRIVATE', 'PUBLIC' or 'MY_ACCOUNT_TEAM'
 */
export const privacy = (state, boardId) => {
  const doc = firestoreRedux.selectors.doc(state, 'boards', boardId);
  return get(doc, 'privacy');
};

/**
 * @returns {String} Account Id for the given board.
 */
export const accountIdOfBoard = createSelector(
  (state, boardId) => boardId || router.selectors.pageBoardId(state),
  (state) => firestoreRedux.selectors.collection(state, 'user-accessible-boards'),
  (boardId, accessibleBoards) => {
    return get(find(accessibleBoards, { boardId }), 'accountId');
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @return {Object} Fresh user wizard detail for given board.
 */
export const freshUserWizard = createSelector(
  ({ state, boardId }) => boardId || router.selectors.pageBoardId(state),
  ({ state }) => auth.selectors.currentUserId(state),
  ({ state }) => firestoreRedux.selectors.collection(state, `user-board-ui`),
  (boardId, userId, userBoardUI) => {
    return find(userBoardUI, { userId, boardId, type: 'board-ui-fresh-user-wizard' });
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {String} fresh user wizard stage for given board.
 */
export const freshUserWizardStage = createSelector(
  (state, boardId) => boardId || router.selectors.pageBoardId(state),
  signup.selectors.freshUserWizardCompleted,
  signup.selectors.signupBoardId,
  (state, boardId) => freshUserWizard({ state, boardId }),
  (state, boardId) => cardCounts({ state, boardId }),
  (boardId, wizardCompleted, signupBoardId, _freshUserWizard, _cardsCount) => {
    if (wizardCompleted) {
      return 'COMPLETED';
    }

    if(boardId != signupBoardId) {
      return 'COMPLETED';
    }

    if (_freshUserWizard) {
      return _freshUserWizard.stage;
    }

    return 'BOARD_SETUP';
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 200,
    },
  }
);

const cardsCountBySummary = createSelector(
  boardSummary.selectors.columnSummary,
  (columnSummary) => {
    let summaryCardsCount = 0; // This count is from `/column-summary` API response.
    forEach(columnSummary, (count) => {
      summaryCardsCount = summaryCardsCount + count;
    });
    return summaryCardsCount;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

const otherColumnCardsCount = createSelector(
  (state, boardId) => otherCards(state, boardId || routerSelectors.pageBoardId(state)),
  (cards) => {
    return cards && cards.length || 0;
  }
);

const trashColumnCardsCount = createSelector(
  (state, boardId) => trashCards(state, boardId || routerSelectors.pageBoardId(state)),
  (cards) => {
    return cards && cards.length || 0;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

const doneColumnCardsCount = createSelector(
  (state, boardId) => doneCards(state, boardId || routerSelectors.pageBoardId(state)),
  (cards) => {
    return cards && cards.length || 0;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

const totalColumnCardsCount = createSelector(
  (state, boardId) => otherColumnCardsCount(state, boardId || routerSelectors.pageBoardId(state)),
  (state, boardId) => trashColumnCardsCount(state, boardId || routerSelectors.pageBoardId(state)),
  (state, boardId) => doneColumnCardsCount(state, boardId || routerSelectors.pageBoardId(state)),
  (cards, trashCards, doneCards) => {
    return cards + trashCards + doneCards;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state redux state
 * @param {String} boardId
 * @returns {Number} given board card counts.
 */
export const cardCounts = createSelector(
  ({ state, boardId }) => cardsCountBySummary(state, boardId || routerSelectors.pageBoardId(state)),
  ({ state, boardId }) => totalColumnCardsCount(state, boardId || routerSelectors.pageBoardId(state)),
  (summaryCardsCount, totalCardsCount) => {
    return totalCardsCount || summaryCardsCount;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @params {*}
 *  @property {Object} state Redux state
 *  @property {String} boardId Board Id.
 * @returns {Array} uninvited members list for given board.
 */
export const uninvitedMembers = createSelector(
  ({ state, boardId }) => boardTeam(state, boardId),
  (team) => {
    return filter(team, (member) => member && member.name && !member.email);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state Redux state
 * @param {String} memberId ID of the member to retrieve
 * @param {String} boardId ID of the board to retrieve the member from
 * @returns {Object} The member object with the given ID from the board team
 */
export const member = createSelector(
  (state, memberId, boardId) => boardTeam(state, boardId || router.selectors.pageBoardId(state)),
  (state, memberId, boardId) => memberId,
  (team,  memberId) => {
    return find(team, (member) => member && member.id === memberId) || {};
  }
);

/**
 * @returns {Boolean} `true` when given user is board owner in provided board.
 */
export const isBoardOwner = createSelector(
  ({ userId }) => userId,
  ({ state, boardId }) => attrs(state, boardId || router.selectors.pageBoardId(state)),
  (userId, _attrs) => {
    return _attrs && userId === _attrs.ownerId;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @returns {Number} canvasId of the board.
 */
export const canvasId = ({ state, boardId }) => {
  boardId = boardId || router.selectors.pageBoardId(state);
  const _attrs = attrs(state, boardId);
  return _attrs && _attrs.canvasId ? +_attrs.canvasId : 0;
};

/**
 * @returns {String} role of the given user in given board.
 */
export const boardRole = createSelector(
  ({ state, boardId }) =>
    firestoreRedux.selectors.docsByQueryResult(
      state,
      `board-team-members_boardId-${boardId || router.selectors.pageBoardId(state)}`,
      'board-team-members'
    ),
  ({ userId }) => userId,
  (members, userId) => {
    return get(find(members, { userId }), 'role', '');
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @returns `true` when current board is public use case board.
 */
export const isUseCaseBoard = createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'use-case-boards', `ucb_${getIdWoPrefix({ id: boardId || routerSelectors.pageBoardId(state), prefix: 'brd_' })}`),
  (board) => {
    return !isEmpty(board);
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state Redux state
 * @param {Number} boardId Board Id.
 * @returns {Object} Welcome detail for use-case board.
 */
export const useCaseBoardWelcomeDetail = createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'use-case-boards', `ucb_${getIdWoPrefix({ id: boardId || routerSelectors.pageBoardId(state), prefix: 'brd_' })}`),
  (board) => {
    return get(board, 'welcomeDialog', {}) || {};
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when use-case-welcome-dialog is presented to the current user for current board.
 */
export const useCaseWelcomeDialogPresented = (state, boardId) => {
  boardId = boardId || routerSelectors.pageBoardId(state);
  const userId = auth.selectors.currentUserId(state);
  const userBoardUIDetails = firestoreRedux.selectors.docsByQueryResult(state, `user-board-ui_${boardId}_${userId}`, 'user-board-ui');
  const userBoardUIqueryStatus = firestoreRedux.selectors.queryStatus(state, `user-board-ui_${boardId}_${userId}`);
  if (userId) {
    if (!userBoardUIqueryStatus || userBoardUIqueryStatus === 'PENDING') {
      return true;
    }
    const welcomeDialogDetails = find(userBoardUIDetails, { userId, boardId, type: 'board-ui-usecase-welcome-dialog' });
    return (welcomeDialogDetails && welcomeDialogDetails.presented) || false;
  }

  const _useCaseWelcomeDialogPresented = window.localStorage.getItem(`board-user-ui.${boardId}.useCaseWelcomeDialogPresented`);
  return _useCaseWelcomeDialogPresented === 'true' || _useCaseWelcomeDialogPresented === true;
};

/**
 * @returns {Boolean} `true` when current board is use-case board & use case welcome dialog is not presetend to the user ever.
 */
export const isUseCaseWelcomeDialogOpen = (state, boardId) => {
  boardId = boardId || router.selectors.pageBoardId(state);
  const signupCompleted = signup.selectors.signupCompleted(state);
  const _isUseCaseBoard = isUseCaseBoard(state, boardId);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const _useCaseWelcomeDialogPresented = useCaseWelcomeDialogPresented(state, boardId);
  if (signupCompleted || impersonatedUser || !_isUseCaseBoard || _useCaseWelcomeDialogPresented) {
    return false;
  }

  const _useCaseBoardWelcomeDetail = useCaseBoardWelcomeDetail(state, boardId)
  return !isEmpty(_useCaseBoardWelcomeDetail);
};

/**
 * @returns {Boolean} fresh user wizard is completed on same session.
 */
export const freshUserWizardCompletedInThisSession = (state) => get(state, 'board.freshUserWizardCompletedInThisSession', false);

export const addMemberTip = createSelector(
  (state, userId) => member(state, userId),
  (state, userId) => get(state, `board-team.lastAddedMembers`,  {}),
  (member, lastAddedMembers) => {
    if(isEmpty(member) || isEmpty(lastAddedMembers) || !lastAddedMembers.tip) {
      return '';
    }

    const emails = lastAddedMembers.emails || [];
    const email = emails[0];
    return email && member.email && member.email === email ? lastAddedMembers.tip : '';
  }
);

/**
 * @param {Object} state 
 * @param {String} userId 
 * @returns {String} change-role tip name.
 */
export const changeRoleTip = (state, userId) => get(state, `board-team.changeRole.${userId}.tip`, '');