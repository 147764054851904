import { put, takeEvery, call, select, all, delay } from 'redux-saga/effects';
import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as amplitude from '../../analytics/amplitude.js';
import * as subscriptionActions from '../subscription/actions.js';
import * as knownFeaturesSelectors from '../known-features/selectors.js';
import * as knownFeaturesActions from '../known-features/actions.js';
import * as signup from '../signup';
import * as boardSummary from '../board-summary';
import { fbReader, read as firebaseRead } from '@dw/firebase-redux/firebase-redux.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import entityIdProvider from '../../entity-id-provider';
import { requestApi, isNetworkError } from "../../request-api"
import { membersList, knownUsers } from './selectors';
import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import without from 'lodash-es/without';
import forEach from 'lodash-es/forEach';
import find from 'lodash-es/find';
import forEachRight from 'lodash-es/forEachRight';
import map from 'lodash-es/map';
import cloneDeep from 'lodash-es/cloneDeep';
import { show as showSnackBar } from '../../components/kerika-snackbar';
import i18next from '@dw/i18next-esm';
import { store } from '../../store';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import { favoriteBoard, accessibleBoards } from '../../redux/board-explorer/selectors';
import * as actions from './actions';
import * as selectors from './selectors';
import { getIdWoPrefix } from '../../utils';
import * as columns from '../columns/selectors.js';
import * as unreadHighlightsActions from '../unread-highlights/actions.js';


function* loadCurrentBoardDetail() {
  let state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const accountId = router.selectors.accountId(state);
  const accessibleAccounts = auth.selectors.accessibleAccounts(state);
  const loadAccountTeam = accessibleAccounts && accessibleAccounts.includes(accountId);
  const userId = auth.selectors.currentUserId(state);
  const type = get(state, 'router.page.params.type');
  const privatePage = get(state, 'router.page.params.private');
  const dialogName = router.selectors.dialogName(state);

  if (privatePage || dialogName === 'CARD') {
    return;
  }

  if (!boardId || !accountId) {
    throw new Error('Board or Account id is not found.');
  }

  try {
    let allPromises = [];
    console.time(`Board Load Time : ${boardId}`);

    //Loads login user's card summary for current board.
    if(userId && type === 'TASKBOARD') {
      try {
        const cardSummaryPromise = requestApi(`/card/cards/card-summary?boardId=${boardId}`, {excludeErrors: [403]});
        const columnSummaryPromise = requestApi(`/card/cards/column-summary?boardId=${boardId}`, {excludeErrors: [403]});
        allPromises.push(cardSummaryPromise, columnSummaryPromise);
      } catch (error) {}
    }

    const requesterId = `board-page-${boardId}`;

    // Loads board attrs.
    const attrQuery = firestoreRedux.getDocById('boards', boardId, { requesterId });
    let attrs = selectors.attrs(state, boardId);
    if(isEmpty(attrs)) {
      allPromises.push(attrQuery.waitTillResultAvailableInState());
    }

    // Loads columns & tags.
    if (type === 'TASKBOARD') {
      const columnsquery = firestoreRedux.query('columns', { id: `board_columns_${boardId}`, where: [['boardId', '==', boardId]], requesterId });
      if(userId){
        const hiddenColumnsQuery = firestoreRedux.query('hidden-columns', { id: `hidden-columns-${boardId}`, where: [['boardId', '==', boardId], ['userId', '==', userId]], requesterId });
        if(isEmpty(firestoreRedux.selectors.queryResult(state, `hidden-columns-${boardId}`))) {
          allPromises.push(hiddenColumnsQuery.waitTillResultAvailableInState());
        }
      }
      if(isEmpty(firestoreRedux.selectors.queryResult(state, `board_columns_${boardId}`))){
        allPromises.push(columnsquery.waitTillResultAvailableInState());
      }

      firestoreRedux.query('board-tags', { id: `board-tags-${boardId}`, where: [['boardId', '==', boardId]], requesterId });
      firestoreRedux.getDocById('tag-colors', 'tc_0', { requesterId });
    }

    // Loads board team membes.
    const teamQuery = firestoreRedux.query('board-team-members', { id: `board-team-members_boardId-${boardId}`, where: [['boardId', '==', boardId]], requesterId });
    if(isEmpty(firestoreRedux.selectors.queryResult(state, `board-team-members_boardId-${boardId}`))){
      allPromises.push(teamQuery.waitTillResultAvailableInState()); //Note: wait till result is updated in redux state.
    }

    // Loads all cards
    const cardsQueryId = `cards_${boardId}`;
    const cardsQuery = firestoreRedux.query('cards', { id: cardsQueryId, where: [['boardId', '==', boardId], ['columnType', '==', 'OTHER']], requesterId });
    const cardsQueryResult = firestoreRedux.selectors.queryResult(state, cardsQueryId);
    if(isEmpty(cardsQueryResult)){
      allPromises.push(cardsQuery.waitTillResultAvailableInState());
    }

    // Card summary
    const cardsSummaryQueryId = `cards_summary_${boardId}`;
    const cardsSummaryQuery = firestoreRedux.query('cards-summary', { id: cardsSummaryQueryId, where: [['boardId', '==', boardId], ['columnType', '==', 'OTHER']], orderBy: [['columnOrder']], requesterId });
    const cardsSummaryQueryResult = firestoreRedux.selectors.queryResult(state, cardsSummaryQueryId);
    if(isEmpty(cardsSummaryQueryResult)) {
      allPromises.push(cardsSummaryQuery.waitTillResultAvailableInState());
    }

    if(userId) {
      //Loads user board-ui details
      const userBoardUiQuery = firestoreRedux.query('user-board-ui', { id: `user-board-ui_${boardId}_${userId}`, where: [['boardId', '==', boardId], ['userId', '==', userId]], requesterId });
      if(isEmpty(firestoreRedux.selectors.queryResult(state, `user-board-ui_${boardId}_${userId}`))){
        allPromises.push(userBoardUiQuery.waitTillResultAvailableInState());
      }

      // Loads user unread details. 
      firestoreRedux.query('card-unread-user-details', { id: `board_card_unread_${boardId}`, where: [['boardId', '==', boardId], ['userId', '==', userId], ['anyUnread', '==', true]], requesterId });
      firestoreRedux.query('column-unread-user-details', { where: [['boardId', '==', boardId], ['userId', '==', userId], ['anyUnread', '==', true]], requesterId });
    }

    // Subscribe account / board team members & loads its attrs.
    yield call(subscribeBoardMembers, { boardId, loadAccountTeam });
    yield call(subscribeAccountId);

    const allPromiseResult = yield Promise.all(allPromises);
    attrs = attrs || get(allPromiseResult, (userId && type === 'TASKBOARD') ? '2' : '0');

    // Gets columns & cards summary from APIs responses.
    let columnSummary = {};
    let boardCardSummary = {};
    if(userId && type === 'TASKBOARD'){
      boardCardSummary = get(allPromiseResult, '0');
      columnSummary = get(allPromiseResult, '1');
    }

    state =  yield select();
    const doneColumnId = columns.currentBoardDoneColumnId(state);
    const trashColumnId = columns.currentBoardTrashColumnId(state);

    firestoreRedux.getDocById(`boards/${boardId}/columns-summary`, `cos_${getIdWoPrefix({id: doneColumnId, prefix: 'col_'})}`, { requesterId });
    firestoreRedux.getDocById(`boards/${boardId}/columns-summary`, `cos_${getIdWoPrefix({id: trashColumnId, prefix: 'col_'})}`, { requesterId });

    console.timeEnd(`Board Load Time : ${boardId}`);
    yield put({ type: boardSummary.actions.LOAD_COLUMN_SUMMARY_DONE, summary: columnSummary, id: boardId });

    //Dispatch action to mark board as loaded on redux state
    yield put(actions.boardLoaded(boardId, boardCardSummary));

    // Track Amplitude events.
    if(attrs && attrs.template) {
      amplitude.logTemplateEvent('template opened', {}, { trackReferrer: true });
    } else {
      amplitude.logBoardEvent('board opened');
    }

    // Done & Trash Cards & summary
    if (type === 'TASKBOARD') {
      firestoreRedux.query('cards', { id: `done_cards_${boardId}`, where: [['boardId', '==', boardId], ['columnType', '==', 'DONE']], limit: 100, orderBy: [['columnOrder']], requesterId });
      firestoreRedux.query('cards', { id: `trash_cards_${boardId}`, where: [['boardId', '==', boardId], ['columnType', '==', 'TRASH']], limit: 100, orderBy: [['columnOrder']], requesterId });
      firestoreRedux.query('cards-summary', { id: `done_cards_summary_${boardId}`, where: [['boardId', '==', boardId], ['columnType', '==', 'DONE']], limit: 100, orderBy: [['columnOrder']], requesterId });
      firestoreRedux.query('cards-summary', { id: `trash_cards_summary_${boardId}`, where: [['boardId', '==', boardId], ['columnType', '==', 'TRASH']], limit: 100, orderBy: [['columnOrder']], requesterId });
    }

    if(loadAccountTeam) {
      firestoreRedux.query('account-team-members', { id: `account-team-members-${accountId}`, where: [['accountId', '==', accountId]], requesterId });
    }

    // Loads board-move-requests.
    if(!isEmpty(attrs)) {
      const bmrDocId = `bmr_${getIdWoPrefix({id: boardId, prefix: 'brd_'})}`;
      firestoreRedux.getDocById('board-move-requests', bmrDocId, { requesterId });
    }

    if(userId) {
      const boardUnreadQuery = firestoreRedux.query('board-unread-user-details', { id: `board-unread-user-details-${boardId}`, where: [['boardId', '==', boardId], ['userId', '==', userId], ['anyUnread', '==', true]], requesterId });
      const boardUnreadResults = yield boardUnreadQuery.result;
      const boardUnread = boardUnreadResults && boardUnreadResults[0] || {};
      if(boardUnread && boardUnread.anyUnread && boardUnread.unread && (boardUnread.unread.name || boardUnread.unread.created)) {
        let attributes = [];
        if(boardUnread.unread.name) {
          attributes.push('name');
        }

        if(boardUnread.unread.created) {
          attributes.push('created');
        }
        setTimeout(() => {
          if(boardId) {
            store.dispatch(unreadHighlightsActions.markAsRead(boardId, attributes));
          }
        }, 3000);
      }
    }

  } catch (error) {
    if(error && error.status === 403) {
      yield put(router.actions.updatePrivateBoard(true));
      return;
    }

    const errorString = error && error.toString && error.toString() || '';
    if (error && ((error.includes && error.includes('permission-denied')) || (errorString && errorString.includes('permission-denied')))) {
      yield put(router.actions.updatePrivateBoard(true));
    }
    console.error("current board details is not loaded due to this: ", error);
  }
}

/**
 * Show toast if member already exist.
 * Send request to add member.
 * Show toast after successfull add. If user is knownUser, show her name otherwise her email in toast.
 * @param {Object} action action payload e.g({type: 'BOARD_TEAM_ADD_MEMBER', requestId, boardId, email, role})
 */
function* addMember(action) {
  const emails = action.emails || [];
  const role = action.role || '';
  yield put({ type: actions.BOARD_TEAM_ADD_MEMBER_IN_PROGRESS, requestId: action.requestId, boardId: action.boardId, emails, role });
  const state = yield select();
  const members = membersList(state, action.boardId);
  const filteredMember = filter(members, (obj) => {
    const email = obj.user && obj.user.email;
    return emails.includes(email);
  });

  if (filteredMember && filteredMember.length) {
    yield put({ type: actions.BOARD_TEAM_ADD_MEMBER_FAILED, requestId: action.requestId, failureReason: 'ALREADY_EXIST', emails, role });
    return;
  }

  const body = [];
  forEach(emails, (email) => {
    body.push({email, role});
  });

  let config = { excludeErrors: [502, 401, 409], method: 'POST', body };
  try {
    store.dispatch(knownFeaturesActions.markAsKnown('SHARE_BOARD'));
    const response = yield call(requestApi, `/board/boards/${action.boardId}/team`, config);
    amplitude.logBoardEvent('board team member added', {'user_role': role, invited: response && response.invited || false});

    const known_users = knownUsers(state);
    const filteredKnownUser = filter(known_users, user => emails.includes(user.email));

    let toastUserNames = [];
    forEach(emails, (email) => {
      let name = email;
      forEach(filteredKnownUser, (user) => {
        if(user && user.email === email) {
          name = user.name || user.email;
          return false;
        }
      })
      toastUserNames.push(name);
    });

    if(toastUserNames && toastUserNames.length > 1) {
      toastUserNames[toastUserNames.length - 1] = i18next.t('seprated.and') + ' ' + toastUserNames[toastUserNames.length - 1];
    }
    let toastUserName = toastUserNames && toastUserNames.length > 1 ? toastUserNames.join(', '): toastUserNames[0];
    yield put({ type: actions.BOARD_TEAM_ADD_MEMBER_DONE, requestId: action.requestId, tip: knownFeaturesSelectors.boardMemberAddedTipOpened(state) ? 'NEW_MEMBER_ADDED': '', emails, role});
    if(emails && emails.length > 1) {
      showSnackBar({ message: i18next.t('board-page:teamToasts.multipleUserAdded', { userNames: toastUserName }) });
    } else {
      showSnackBar({ message: i18next.t('board-page:teamToasts.added', { userName: toastUserName }) });
    }
  } catch (err) {
    if (err.code === 'ALREADY_EXIST') {
      yield put({ type: actions.BOARD_TEAM_ADD_MEMBER_FAILED, requestId: action.requestId, failureReason: 'ALREADY_EXIST', emails, role });
    }

    if (err.code === 'NO_SUBSCRIPTION_AVAILABLE' && !app.selectors.isInstalledApp(state)) {
      yield put({ type: actions.BOARD_TEAM_ADD_MEMBER_FAILED, requestId: action.requestId, failureReason: 'NO_SUBSCRIPTION_AVAILABLE', emails, role });
      yield call (store.dispatch, subscriptionActions.updatePendingActionData({ requestId: action.requestId, boardId: action.boardId, emails, role, action: 'add', type: 'board-team'}));
      yield call(router.actions.removeActionParam, 'board-team', {'board-team-action': 'add'});
      yield call(router.actions.openManageTeamConfirmDialog, 'add', 'board-team', emails && emails.length || 1);
    }
    return;
  }
}

/**
 * Change board member role.
 * When `NO_SUBSCRIPTION_AVAILABLE`, show subscription dialog.
 * @param {Object} action action payload. e.g({type: 'BOARD_TEAM_CHANGE_MEMBER_ROLE', boardId, userId, role})
 */
function* changeMemberRole(action) {
  amplitude.logBoardEvent('board team member role changed');
  const state = yield select();
  const isBoardOwner = selectors.isBoardOwner({state, boardId: action.boardId, userId: action.userId});
  const currentRole = selectors.boardRole({state, boardId: action.boardId, userId: action.userId});
  const newRole = action.role;
  const upgrade = currentRole === 'VISITOR' && (newRole === 'BOARD_ADMIN' || newRole === 'TEAM_MEMBER');
  const downgrade = newRole === 'VISITOR' && (currentRole === 'BOARD_ADMIN' || currentRole === 'TEAM_MEMBER');
  const tip = isBoardOwner ? '': upgrade && knownFeaturesSelectors.boardMemberUpgradedTipOpened(state) ? 'MEMBER_ROLE_UPGRADE': downgrade && knownFeaturesSelectors.boardMemberDowngradedTipOpened(state) ? 'MEMBER_ROLE_DOWNGRADE': '';
  try {
    const config = { excludeErrors: [502, 401, 409], method: 'PUT', body: { role: newRole } };
    yield call(requestApi, `/board/boards/${action.boardId}/team/${action.userId}`, config);
    yield put({ type: actions.BOARD_TEAM_CHANGE_MEMBER_ROLE_DONE, boardId: action.boardId, userId: action.userId, role: newRole, upgrade, tip, downgrade });
  } catch (err) {
    const code = err && err.code || 'UNKNOWN';
    yield put({ type: actions.BOARD_TEAM_CHANGE_MEMBER_ROLE_FAILED, boardId: action.boardId, userId: action.userId, role: newRole, error: code, upgrade, downgrade });
    if (err.code === 'NO_SUBSCRIPTION_AVAILABLE' && !app.selectors.isInstalledApp(state)) {
      yield call (store.dispatch, subscriptionActions.updatePendingActionData({ boardId: action.boardId, userId: action.userId, role: action.role, email: action.email, userName: action.userName, action: 'upgrade', type: 'board-team'}));
      yield call(router.actions.removeActionParam, 'board-team', {'board-team-action': 'add'});
      yield call(router.actions.openManageTeamConfirmDialog, 'upgrade', 'board-team');
    }
  }
}

/**
 * Remove member from team
 * When user leaves team redirect her to home page.
 * @param {Object} action action payload. e.g({type: 'BOARD_TEAM_REMOVE_MEMBER', boardId, userId, useName})
 */
function* removeMember(action) {
  if(action.leave) {
    amplitude.logBoardEvent('board team leave');
  } else {
    amplitude.logBoardEvent('board team member removed');
  }

  try {
    let config = { excludeErrors: [502, 401], method: 'DELETE' };
    yield call(requestApi, `/board/boards/${action.boardId}/team/${action.userId}`, config);

    //Member removed tip-acknowledged
    if(action.tipAknowledged) {
      yield put(knownFeaturesActions.markAsKnown('MEMBER_REMOVED'));
    }
    const state = yield select();
    const currentUser = auth.selectors.currentUserId(state);
    const currentAccountId = router.selectors.accountId(state);
    const currentUserAccountId = get(auth.selectors.currentUserOwnedAccounts(state), '0');
    const pageName = router.selectors.page(state).name;
    if (action.userId == currentUser && currentAccountId !== currentUserAccountId && pageName === 'BOARD') {
      router.actions.navigate(`/${currentAccountId}/home/favorite/boards`, true);
    }
  } catch (error) {
    if(isNetworkError(error) || error.code === 504){
      return;
    }
    console.error('Board member removed > failed due to this: ', error);
  }

}

/**
 * Loads known-users for current user.
 * Loads all known-users basic attrs.
 */
function* loadKnownUsers() {
  try {
    const knownUsers = yield call(requestApi, `/account/known-users`);
    forEach(knownUsers, (user) => {
      firestoreRedux.getDocById('users', user.id, { requesterId: `known-users` });
    })
    yield put({ type: actions.LOAD_KNOWN_USERS_DONE, knownUsers });
  } catch (error) {
    console.error(`Failed to load known users`, error);
  }
}

/**
 * Load subscription info for current account.
 * @param {Object} action Action payload
 */
function* loadAccountSubscription(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const currentUserId = auth.selectors.currentUserId(state);
  if (accountId && currentUserId) {
    subscribeAccountOwner(accountId);
    firebaseRead(action.requesterId, [`/subscriptions/${accountId}/subscription`]);
  }
}

/**
 * Subscibe on account subscription path & load account owner detail.
 * @param {Number} accountId account Id
 */
const subscribeAccountOwner = (accountId) => {
  ReduxUtils.subscribe(store, `firebase.subscriptions.${accountId}.subscription`, (subscription) => {
    if (subscription && subscription.accountOwnerId) {
      firestoreRedux.getDocById('users', subscription.accountOwnerId);
    }
  });
}

/**
 * Disconnet known-users from firebase.
 * @param {Object} action Action payload
 */
function* disconnectKnownUsers(action) {
  fbReader.disconnect(action.requesterId);
}

/**
 *
 * @param {*} param0
 *  @property {String} requesterId Firestore query requester id.
 *  @property {String} boardId Board Id of which columns will be loaded.
 */
function* loadColumns({ requesterId, boardId }) {
  firestoreRedux.query('columns', { id: `board_columns_${boardId}`, where: [['boardId', '==', boardId]], requesterId });
}

/**
 *
 * @param {*} param0
 *  @property {String} requesterId Firestore query requester id.
 */
function* disconnectColumns({ requesterId }) {
  firestoreRedux.cancelQueryByRequester(requesterId);
}

/**
 * Updates board basic details.
 * @param {*} param0
 *  @property {String} name Field name which will be updated.
 *  @property {String} value New value of the given field.
 *  @property {String} boardId Board ID.
 */
function* updateBoardBasicDetail({ name, value, boardId }) {

  const state = yield select();
  const currDoc = firestoreRedux.selectors.doc(state, 'boards', boardId);
  let newDoc = cloneDeep(currDoc);
  newDoc = { ...newDoc, [name]: value };

  try {
    firestoreRedux.save('boards', newDoc, { localWrite: true });
    yield call(requestApi, `/board/boards/${boardId}/basic`, { method: 'PUT', body: newDoc });
    yield put({ type: actions.UPDATE_BASIC_DETAIL_DONE, boardId, name, value });
  } catch (err) {
    yield put({ type: actions.UPDATE_BASIC_DETAIL_FAILED, boardId, name, value });
    firestoreRedux.save('boards', currDoc, { localWrite: true });
    console.warn(err);
  }
}

/**
 * Favorites / unfavorites board.
 * @param {*} param0
 *  @property {String} boardId Board ID.
 *  @property {Boolean} hideToast When it's true, doesn't show the toast message.
 */
function* updateFavorite({ boardId, hideToast }) {
  try {
    const state = yield select();
    const favorite = !favoriteBoard(state, boardId);
    const isTemplate = selectors.isTemplate(state, boardId);
    const attrs = selectors.attrs(state, boardId);

    // Start localWrite.
    const accountId = get(attrs, 'accountId');
    const userId = auth.selectors.currentUserId(state);
    const allFavBoards = firestoreRedux.selectors.collection(state, 'favorite-boards');
    const currentFavBoardDoc = find(allFavBoards, { boardId });
    let id = get(currentFavBoardDoc, 'id');
    if (!id) {
      const uuid = entityIdProvider.getId();
      id = `ufb_${uuid}`;
    }
    const queryId = 'favorite-boards';
    if (favorite) {
      firestoreRedux.save('favorite-boards', { id, accountId, boardId, userId }, { localWrite: true, queryId });
    } else {
      firestoreRedux.delete('favorite-boards', id, { localWrite: true, queryId });
    }
    // End localWrite.

    if (!hideToast) {
      const toastText = isTemplate ? !favorite ? 'unfavoriteTemplate' : 'favoriteTemplate' : !favorite ? 'unfavoriteBoard' : 'favoriteBoard';
      yield call(showSnackBar, { id: boardId, message: i18next.t(`board-explorer:boardCard.toast.${toastText}`), actionButton: { caption: i18next.t('buttons:undo'), callback: ()=> { undoFavorite(boardId)}} });
    }
    yield call(requestApi, `/board/boards/${boardId}/favorite`, { method: 'PUT', body: { id, favorite } });
  } catch (err) {
    console.warn(err);
  }
}

/**
 * Mark welcomeDialogPresented as true.
 */
function* markWelcomeDialogAsPresented() {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);

  const presented = selectors.welcomeDialogPresented(state, boardId);
  if (presented) {
    return;
  }
  const queryId = `user-board-ui_${boardId}_${userId}`;
  firestoreRedux.save(`users/${userId}/user-board-ui`, {
    id: `buwd_${entityIdProvider.getId()}`,
    type: 'board-ui-welcome-dialog',
    userId,
    boardId,
    presented: true,
  },
  { queryId, localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Undo favorite action.
 * @param {Number} boardId Board Id
 */
function undoFavorite(boardId) {
  store.dispatch(actions.updateFavorite(boardId, true));
}

/**
 * Sends request to copy board.
 * @param {*} param0
 *  @property {String} name Board name.
 *  @property {String} accountId Destination account ID
 *  @property {String} boardId Board ID which is copied.
 *  @property {String} boardType Type of the board. e.g. 'TASKBOARD' or 'WHITEBOARD'
 */
 function* copy({ name, accountId, boardId, boardType }) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  try {
    const response = yield call(requestApi, `/board/boards/${boardId}/copy`, {
      method: 'POST',
      body: { name, accountId },
      excludeErrors: [403],
    });

    const allUserAccUiDocs = firestoreRedux.selectors.collection(state, 'user-account-ui');
    const createBoardPreference = find(allUserAccUiDocs, { accountId, userId, type: 'account-ui-create-board-preferences' }) || {};
    const lastUsedTemplate = get(createBoardPreference, `lastUsedTemplate`, {});
    const id = get(createBoardPreference, 'id', `acbp_${entityIdProvider.getId()}`);
    // Saves last used board type & last used template into user-account-ui.
    firestoreRedux.save(`users/${userId}/user-account-ui`, {
      id,
      userId,
      accountId,
      type: 'account-ui-create-board-preferences',
      lastUsedType: boardType,
      lastUsedTemplate,
    }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });

    yield call(openBoard, response.id, accountId);
  } catch (err) {
    if (err && err.code === 'NOT_A_TEAM_MEMBER') {
      yield call(showSnackBar, { message: i18next.t('board-explorer:boardCard.toast.copyToOtherAccountNotAllowed'), type: 'ERROR' });
    }
    yield put({ type: actions.BOARD_COPY_FAILED, accountId, boardId });
    console.warn(err);
  }
}

/**
 * Open board
 * @param {Number} boardId Board Id
 * @param {Number} accountId Account Id
 */
 function* openBoard(boardId, accountId) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);

  const _openCopyBoard = (state, accountId, boardId) => {
    //Remove action=copy-board, when user press browser back then explore page shown instead of copy board dialog.
    if(router.selectors.actionName(state) === 'copy-board') {
      router.actions.setQueryParams({'action': null});
    }
    router.actions.navigate(`/${accountId}/board/${boardId}`);
  }

  try {
    //Wait for board & it's member's data is ready.
    const boardRequest = firestoreRedux.getDocById('boards', boardId, { once: true, waitTillSucceed: true });
    const boardAttrs = yield boardRequest.result;

    if(boardAttrs && boardAttrs.ownerId != userId) {
      const membersQuery = firestoreRedux.query('board-team-members', {
        where: [['boardId', '==', boardId], ['userId', '==', userId]],
        once: true,
        waitTillSucceed: true,
      });
      yield membersQuery.result;
    }

    yield put({ type: actions.BOARD_COPY_DONE, accountId, boardId });
    _openCopyBoard(state, accountId, boardId);
  } catch (error) {
    yield put({ type: actions.BOARD_COPY_DONE, accountId, boardId });
    _openCopyBoard(state, accountId, boardId);
  }
}

/**
 * Loads board's basic details. (Only when board is accessible to user.)
 * @param {Object} action action payload. e.g {type: BOARDS_LOAD_BASIC_DETAILS, reuesterId, boardIds: [234234, 234234]}
 */
function* loadBoardsBasicDetails(action) {
  try {
    if (!action.requesterId || !action.boardIds) {
      console.warn('loadBoardsBasicDetails: Required data is not provided.');
      return;
    }
    const state = yield select();
    const userId = auth.selectors.currentUserId(state);
    const _accessibleBoards = accessibleBoards(state);
    if (isEmpty(_accessibleBoards)) {
      console.warn('loadBoardsBasicDetails: No accessible board found.');
      return;
    }

    forEach(action.boardIds, (boardId) => {
      if (_accessibleBoards[boardId]) {
        firestoreRedux.getDocById('boards', boardId, { requesterId: action.requesterId });
        if(userId) {
          firestoreRedux.query('board-unread-user-details', { id: `board-unread-user-details-${boardId}`, where: [['boardId', '==', boardId], ['userId', '==', userId], ['anyUnread', '==', true]], requesterId: action.requesterId });
        }
      }
    })
  } catch (error) {
    console.error("loadBoardsBasicDetails > failed due to this: ", error);
  }
}

/**
 * Disconnects boards details.
 * @param {Object} action action payload. e.g {type: BOARDS_BASIC_DETAILS_DISCONNECT, requesterId}
 */
function* disconnectBoardsBasicDetails(action) {
  if (!action.requesterId) {
    console.warn('disconnectBoardsBasicDetails: Required data is not provided.');
    return;
  }
  firestoreRedux.cancelQueryByRequester(action.requesterId);
}

/**
 * Opens board.
 * @param {Object} action action palyload. e.g `{type: 'BOARD_OPEN', boardId, accountId}`
 */
function* open(action) {
  router.actions.navigate(`/${action.accountId}/board/${action.boardId}`, true);
}

/**
 * Given card already in selected-cards then remove card from selected-cards,
 * Otherwise add card into selected-cards.
 * @param {Object} action action palyload.
 */
function* toggleCardSelection(action) {
  const state = yield select();
  const selectedColumn = selectors.selectedColumn(state);
  let selectedCards = selectors.selectedCards(state);

  if(isEmpty(selectedCards) || action.columnId !== selectedColumn) {
    store.dispatch(actions.setSelection([action.cardId], action.columnId));
    return;
  }

  if(selectedCards.indexOf(action.cardId) !== -1) {
    selectedCards = without(selectedCards, action.cardId);
  } else {
    selectedCards.push(action.cardId);
  }

  store.dispatch(actions.setSelection(selectedCards, action.columnId));
}

/**
 * Card select upto given card.
 * @param {Object} action action palyload.
 */
function* uptoCardSelection(action) {
  const state = yield select();
  const selectedCards = selectors.selectedCards(state);
  const selectedColumn = selectors.selectedColumn(state);

  if(isEmpty(selectedCards) || action.columnId !== selectedColumn) {
    store.dispatch(actions.setSelection([action.cardId], action.columnId));
    return;
  }

  const cardsByColumn = map(selectors.cardsByColumn(state, selectedColumn) || [], 'id');
  let topSideLastSelectedCard;
  let bottomSideFirstSelectedCard;

  //Find top-side last selected Card.
  forEach(cardsByColumn, (cardId) => {
    if(selectedCards.indexOf(cardId) !== -1 && cardId !== action.cardId) {
      topSideLastSelectedCard = cardId;
    }

    if(cardId === action.cardId) {
      return false;
    }
  });

  //Find bottom side first selected card.
  forEachRight(cardsByColumn, (cardId) => {
    if(selectedCards.indexOf(cardId) !== -1 && cardId !== action.cardId) {
      bottomSideFirstSelectedCard = cardId;
    }

    if(cardId === action.cardId) {
      return false;
    }
  });

  //Compute new selected card based on `topSideLastSelectedCard` and `bottomSideFirstSelectedCard`.
  let newSelectedCards = selectedCards;
  if(topSideLastSelectedCard) {
    let topCards = cardsByColumn.slice(cardsByColumn.indexOf(topSideLastSelectedCard), cardsByColumn.indexOf(action.cardId));
    newSelectedCards = [...newSelectedCards, ...topCards];
  }

  if(bottomSideFirstSelectedCard) {
    let bottomCards = cardsByColumn.slice(cardsByColumn.indexOf(action.cardId), cardsByColumn.indexOf(bottomSideFirstSelectedCard));
    newSelectedCards = [...newSelectedCards, ...bottomCards];
  }

  newSelectedCards.push(action.cardId);
  store.dispatch(actions.setSelection(newSelectedCards, selectedColumn));
}

/**
 * Selects all cards in provided column.
 * @param {Object} action action payload. e.g {type: BOARD_CARD_SELECT_ALL, columnId}
 */
function* selectAllCards({ columnId }) {
  const state = yield select();
  const cards = selectors.cardsByColumn(state, columnId) || [];
  const cardIds = map(cards, 'id');
  yield put({ type: actions.CARD_SELECTION_UPDATE, cardIds, columnId });
}

/**
 * Subscribe on selectedColumn.
 */
let lastCardsByColumn;
function* subscribeSelectedColumn() {
  let unsubscribe;
  ReduxUtils.subscribe(store, `board.selectedColumn`, (selectedColumn)=> {
    unsubscribe && unsubscribe();

    if(!selectedColumn) {
      return;
    }
    lastCardsByColumn = undefined;
    unsubscribe = ReduxUtils.subscribe(store, `firestore.docs.cards`, onSelectedColumnCardsChange);
  });
}

/**
 * Subscribe live listener for board accountId changes.
 * If accountId is change for current board and is not match with router data
 *   - change new board url with replace state
 *
 * How to change the account ID of the board?
 * - When a user transfers the board to another user and the board is transferred to the account of another user,
 *   then the accountId changes.
 */
let unsubscribeAccountIdHandler;
function* subscribeAccountId() {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const routerAccountId = router.selectors.accountId(state);

  unsubscribeAccountIdHandler && unsubscribeAccountIdHandler();

  const accountIdChangeCheck = () => {
    const boardAttrs = selectors.attrs(store.getState());
    const movingStatus = boardAttrs && boardAttrs.movingStatus || '';
    if(boardAttrs && get(boardAttrs, 'accountId') !== routerAccountId && movingStatus !== 'ACCEPTED' && movingStatus !== 'FAILED') {
      router.actions.navigate(`/${boardAttrs.accountId}/board/${boardAttrs.id}`, true);
      unsubscribeAccountIdHandler && unsubscribeAccountIdHandler();
    }
  }

  unsubscribeAccountIdHandler = ReduxUtils.subscribe(store, [`firestore.docs.boards.${boardId}`, `firestore.docs.board-move-requests.${`bmr_${getIdWoPrefix({id: boardId, prefix: 'brd_'})}`}`], () => {
    accountIdChangeCheck();
  });
}

/**
 * Loads user attrs when she is added into board team.
 */
let unsubscribeBoardMembersHandlers = {};
function* subscribeBoardMembers({boardId, loadAccountTeam, requesterId}) {
  try {
    let state = yield select();
    boardId = boardId || router.selectors.pageBoardId(state);
    requesterId = requesterId || `board-page-${boardId}`;
    // Loads board owner detals if not loaded.
    let boardAttrs = selectors.attrs(state, boardId);
    if (isEmpty(boardAttrs)) {
      const request = firestoreRedux.getDocById('boards', boardId, { once: true });
      boardAttrs = yield request.result;
    }
    const ownerId = boardAttrs && boardAttrs.ownerId;
    const accountId = boardAttrs && boardAttrs.accountId;
    const ownerDetails = firestoreRedux.selectors.doc(state, 'users', ownerId);
    if (ownerId && isEmpty(ownerDetails)) {
      firestoreRedux.getDocById('users', ownerId, { requesterId });
    }

    //Load board createdBy details.
    const createdBy = boardAttrs && boardAttrs.createdBy;
    const createdByDetails = firestoreRedux.selectors.doc(state, 'users', createdBy);
    if (createdBy && isEmpty(createdByDetails)) {
      firestoreRedux.getDocById('users', createdBy, { requesterId });
    }

    // Loads board account team members details.
    unsubscribeBoardMembersHandlers[boardId] = ReduxUtils.subscribe(store, loadAccountTeam ? 'firestore.docs.account-team-members': 'firestore.docs.board-team-members' , (members) => {
      if(loadAccountTeam) {
        members = filter(members, { accountId});
      } else {
        members = filter(members, { boardId});
      }
      if (isEmpty(members)) {
        return;
      }
      state = store.getState();
      forEach(members, (member) => {
        if (isEmpty(member) || !member.userId) {
          return;
        }
        const userDetails = firestoreRedux.selectors.doc(state, 'users', member.userId);
        if (!userDetails) {
          firestoreRedux.getDocById('users', member.userId, { requesterId });
        }
      })
    });
  } catch (error) {}
}

/**
 * Unsubscribes board team members.
 */
function* unsubscribeBoardMembers({ boardId }) {
  const state = yield select();
  boardId = boardId || router.selectors.pageBoardId(state);
  unsubscribeBoardMembersHandlers && unsubscribeBoardMembersHandlers[boardId] && unsubscribeBoardMembersHandlers[boardId]();
}

let unsubscribeAccountMembersHandlers = {};
function* subscribeAccountMembers({accountId, requesterId}) {
  try {
    requesterId = requesterId || `account-team-members-${accountId}`;
    // Loads board account team members details.
    const chunkSize = 10;
    unsubscribeAccountMembersHandlers[accountId] = ReduxUtils.subscribe(store, 'firestore.docs.account-team-members', async(members) => {
      members = filter(members, { accountId });
      if (isEmpty(members)) {
        return;
      }

      let state = store.getState();
      const _membersIds = [];
      forEach(members, (member) => {
        if (isEmpty(member) || !member.userId) {
          return;
        }
        const userDetails = firestoreRedux.selectors.doc(state, 'users', member.userId);
        if (!userDetails) {
          _membersIds.push(member.userId);
        }
      })

      let promises = [];
      for (let i = 0; i < _membersIds.length; i += chunkSize) {
        const userIds = _membersIds.slice(i, i + chunkSize);
        try {
          const userQuery = firestoreRedux.query('users', { where: [['id', 'in', userIds]] });
          promises.push(userQuery.result);
        } catch (error) {
          console.error("subscribe account members failed due to this: ", error);
        }
      }

      try {
        if (!isEmpty(promises)) {
          await Promise.all(promises);
        }
      } catch (error) {}
    });
  } catch (error) {}
}

function* unsubscribeAccountMembers({accountId}) {
  const state = yield select();
  accountId = accountId || router.selectors.accountId(state);
  unsubscribeAccountMembersHandlers && unsubscribeAccountMembersHandlers[accountId] && unsubscribeAccountMembersHandlers[accountId]();
}

/**
 * Disconnects board members when board page is closed.
 */
function* onPageClosed({id}) {
  yield call(unsubscribeBoardData, previousBoardId);
}

/**
 * Disconnects previous board data when it's changed.
 */
 function* onPageChanged({previousBoardId}) {
   yield call(unsubscribeBoardData, previousBoardId);
 }

 /**
  * Unsubscribe from board team members, account id & boad page data.
  * @param {String} boardId Board Id.
  */
function* unsubscribeBoardData(boardId) {
  yield call(unsubscribeBoardMembers, { boardId });
  unsubscribeAccountIdHandler && unsubscribeAccountIdHandler();
  firestoreRedux.cancelQueryByRequester(`board-page-${boardId}`);
}

/**
 * Invoked when selected column is changed.
 * live listen on cardsByColumn of selected column.
 *  - on change check all selected cards in column or not.
 *  - card which is not available in the column then remove it from the card selection.
 */
function onSelectedColumnCardsChange() {
  const state = store.getState();
  const selectedCards = selectors.selectedCards(state);
  const selectedColumn = selectors.selectedColumn(state);
  if(isEmpty(selectedCards) || !selectedColumn) {
    return;
  }

  const cardsByColumn = selectors.cardsByColumn(state, selectedColumn) || [];
  if(isEmpty(cardsByColumn) || isEqual(lastCardsByColumn, cardsByColumn)) {
    return;
  }

  let newSelectedCards = selectedCards;
  lastCardsByColumn = cardsByColumn;
  forEach(selectedCards, (cardId) => {
    let cardFound = false;
    forEach(cardsByColumn, (card)=> {
      if(card.id === cardId) {
        cardFound = true;
        return false;
      }
    });

    if(!cardFound) {
      newSelectedCards = without(newSelectedCards, cardId);
    }
  });

  if(isEqual(newSelectedCards, selectedCards)) {
    return;
  }

  if(isEmpty(newSelectedCards)) {
    store.dispatch(actions.clearSelection());
  } else {
    store.dispatch(actions.setSelection(newSelectedCards, selectedColumn));
  }
}

/**
 * Mark welcomeDialogPresented as true.
 */
function* markUseTemplateWelcomeDialogAsPresented() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  yield call(markWelcomeDialogAsPresented);

  firestoreRedux.save(`users/${userId}/user-ui`, {
    id: `utwd_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
    type: 'use-template-welcome-dialog',
    userId,
    presented: true,
  }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Mark taskboardParticipantVideoWatched as true.
 */
function* markTaskboardParticipantVideoWatched() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  yield call(markWelcomeDialogAsPresented);

  firestoreRedux.save(`users/${userId}/user-ui`, {
    id: `tpv_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
    type: 'taskboard-participant-video',
    userId,
    watched: true,
  }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Mark taskboardNonParticipantVideoWatched as true.
 */
function* markTaskboardNonParticipantVideoWatched() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  yield call(markWelcomeDialogAsPresented);

  firestoreRedux.save(`users/${userId}/user-ui`, {
    id: `tnpv_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
    type: 'taskboard-non-participant-video',
    userId,
    watched: true,
  }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Mark whiteboardParticipantVideoWatched as true.
 */
function* markWhiteboardParticipantVideoWatched() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  yield call(markWelcomeDialogAsPresented);

  firestoreRedux.save(`users/${userId}/user-ui`, {
    id: `wpv_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
    type: 'whiteboard-participant-video',
    userId,
    watched: true,
  }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Mark whiteboardNonParticipantVideoWatched as true.
 */
function* markWhiteboardNonParticipantVideoWatched() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  yield call(markWelcomeDialogAsPresented);

  firestoreRedux.save(`users/${userId}/user-ui`, {
    id: `wnpv_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
    type: 'whiteboard-non-participant-video',
    userId,
    watched: true,
  }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Resend invitation to given members for given board.
 */
function* resendInvitation(action) {
  const state = yield select();
  const boardId = action.boardId || router.selectors.pageBoardId(state) || router.selectors.dialogBoardId(state);
  const members = action.members || [];
  if(isEmpty(members)) {
    console.warn('Resend board invitation > empty members passed.');
    return;
  }

  try {
    yield call(requestApi, `/board/boards/${boardId}/resend-invitation`, { method: 'POST', body: { userIds: members } });
    showSnackBar({ message: i18next.t('board-page:teamToasts.resendInvitation') });
    yield put(actions._resendInvitationSuccess(boardId, members));
  } catch (error) {
    const code = error && error.code || 'UNKNOWN';
    yield put(actions._resendInvitationFailed(boardId, members, code));
    if(isNetworkError(error)){
      return;
    }
    console.error('Resend board invitation > failed due to this: ', error);
  }
}

/**
 * Marks `useCaseWelcomeDialogPresented` to true.
 */
function* markUseCaseWelcomeDialogAsPresented() {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);

  const presented = selectors.useCaseWelcomeDialogPresented(state, boardId);
  if (presented) {
    return;
  }

  if(userId) {
    const queryId = `user-board-ui_${boardId}_${userId}`;
    firestoreRedux.save(`users/${userId}/user-board-ui`, {
      id: `buuwd_${entityIdProvider.getId()}`,
      type: 'board-ui-usecase-welcome-dialog',
      userId,
      boardId,
      presented: true,
    }, { queryId, localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  } else {
    const key = `board-user-ui.${boardId}.useCaseWelcomeDialogPresented`;
    window.localStorage.setItem(key, true);
    window.dispatchEvent(new CustomEvent('storage-changed', { detail: { key, value: true } }));
  }
}

/**
 * Update fresh user wizard stage.
 */
function* updateFreshUserWizardStage(action) {
  const state = yield select();
  if(!signup.selectors.isFreshUser(state)) {
    console.error('update fresh user wizard stage failed due to: current user is not fresh user');
    return;
  }

  let stage = action.stage;
  const userId = auth.selectors.currentUserId(state);
  const boardId = router.selectors.pageBoardId(state) || signup.selectors.signupBoardId(state);

  if(!userId || !boardId) {
    console.error('update fresh user wizard stage failed due to: proper data not found', {userId, boardId});
    return;
  }

  const freshUserWizardDoc = selectors.freshUserWizard({ state, boardId });
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  try {
    const id = freshUserWizardDoc ? freshUserWizardDoc.id : `bufw_${entityIdProvider.getId()}`;
    firestoreRedux.save(`users/${userId}/user-board-ui`, {
      id,
      type: 'board-ui-fresh-user-wizard',
      userId,
      boardId,
      stage
    }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });

    if(stage === 'COMPLETED') {
      const wizardCompleted = signup.selectors.freshUserWizardCompleted(state);
      if (wizardCompleted) {
        return;
      }

      firestoreRedux.save(`users/${userId}/user-ui`, {
        id: `fuw_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
        type: 'fresh-user-wizard',
        userId,
        completed: true,
      }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
      yield put(actions.freshUserWizardCompletedInThisSession(true));
    }
  } catch (err) {
    firestoreRedux.save(`users/${userId}/user-board-ui`, freshUserWizardDoc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
    console.error('update fresh user wizard stage faile due to this:', err);
  }
}

/**
 * Manages PAGE_OPENED, PAGE_CLOSED & PAGE_CHANGED.
 */
 let previousPage;
 let previousBoardId;
 let previousDialogName;
 function* routeChangeHandler() {
  const state = yield select();
  const page = router.selectors.page(state);
  const currentPage = get(page, 'name');
  const currentBoardId = router.selectors.pageBoardId(state);
  const dialog = router.selectors.dialog(state);
  const currentDialogName = get(dialog, 'name');
  if (currentPage === 'BOARD') {
    if (previousPage !== currentPage) {
      yield put(actions._pageOpened(currentBoardId));
    } else {
      if (previousBoardId !== currentBoardId) {
        yield put(actions._pageChanged(currentBoardId, previousBoardId));
      }
    }

    // When card detail dialog opened through direct url, on close, loads board.
    if(currentDialogName !== 'CARD' && previousDialogName === 'CARD' && !selectors.loaded(state)){
      yield call(loadCurrentBoardDetail);
    }
  } else if (previousPage === 'BOARD') {
    yield put(actions._pageClosed(currentBoardId));
  }
  previousPage = currentPage;
  previousBoardId = currentBoardId;
  previousDialogName = currentDialogName;
}

/**
 * Watches router change.
 */
 function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * Init Saga.
 */
function* boardSaga() {
  yield all([
    call(watchRouter),
    call(subscribeSelectedColumn),
    takeEvery(actions.LOAD_CURRENT_BOARD, loadCurrentBoardDetail),
    takeEvery(actions.PAGE_CLOSED, onPageClosed),
    takeEvery(actions.PAGE_CHANGED, onPageChanged),
    takeEvery(actions.SUBSCRIBE_MEMBERS, subscribeBoardMembers),
    takeEvery(actions.UNSUBSCRIBE_MEMBERS, unsubscribeBoardMembers),
    takeEvery(actions.SUBSCRIBE_ACCOUNT_MEMBERS, subscribeAccountMembers),
    takeEvery(actions.UNSUBSCRIBE_ACCOUNT_MEMBERS, unsubscribeAccountMembers),
    takeEvery(actions.BOARD_TEAM_ADD_MEMBER, addMember),
    takeEvery(actions.BOARD_TEAM_CHANGE_MEMBER_ROLE, changeMemberRole),
    takeEvery(actions.BOARD_TEAM_REMOVE_MEMBER, removeMember),
    takeEvery(actions.BOARD_TEAM_LOAD_KNOWN_USERS, loadKnownUsers),
    takeEvery(actions.BOARD_TEAM_LOAD_ACCOUNT_SUBSCRIPTION, loadAccountSubscription),
    takeEvery(actions.BOARD_TEAM_DISCONNECT_KNOWN_USERS, disconnectKnownUsers),
    takeEvery(actions.LOAD_COLUMNS, loadColumns),
    takeEvery(actions.DISCONNECT_COLUMNS, disconnectColumns),
    takeEvery(actions.MARK_WELCOME_DIALOG_PRESENTED, markWelcomeDialogAsPresented),
    takeEvery(actions.UPDATE_BASIC_DETAIL, updateBoardBasicDetail),
    takeEvery(actions.BOARD_UPDATE_FAVORITE, updateFavorite),
    takeEvery(actions.BOARD_COPY, copy),
    takeEvery(actions.BOARDS_LOAD_BASIC_DETAILS, loadBoardsBasicDetails),
    takeEvery(actions.BOARDS_BASIC_DETAILS_DISCONNECT, disconnectBoardsBasicDetails),
    takeEvery(actions.OPEN, open),
    takeEvery(actions.CARD_TOGGLE_SELECTION, toggleCardSelection),
    takeEvery(actions.CARD_SELECTION_UPTO_CARD, uptoCardSelection),
    takeEvery(actions.SELECT_ALL, selectAllCards),
    takeEvery(actions.MARK_USE_TEMPLATE_WELCOME_DIALOG_PRESENTED, markUseTemplateWelcomeDialogAsPresented),
    takeEvery(actions.UPDATE_FRESH_USER_WIZARD_STAGE, updateFreshUserWizardStage),
    takeEvery(actions.MARK_TASKBOARD_PARTICIPANT_VIDEO_WATCHED, markTaskboardParticipantVideoWatched),
    takeEvery(actions.MARK_TASKBOARD_NON_PARTICIPANT_VIDEO_WATCHED, markTaskboardNonParticipantVideoWatched),
    takeEvery(actions.MARK_WHITEBOARD_PARTICIPANT_VIDEO_WATCHED, markWhiteboardParticipantVideoWatched),
    takeEvery(actions.MARK_WHITEBOARD_NON_PARTICIPANT_VIDEO_WATCHED, markWhiteboardNonParticipantVideoWatched),
    takeEvery(actions.MARK_USE_CASE_WELCOME_DIALOG_PRESENTED, markUseCaseWelcomeDialogAsPresented),
    takeEvery(actions.BOARD_TEAM_RESEND_INVITATION, resendInvitation)
  ]);
}

export default boardSaga;