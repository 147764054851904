import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import { composeRouter } from '@dw/pwa-helpers/compose-router';
import { logEvent as amplitudeLogEvent } from '../../../analytics/amplitude.js';

// Lodash
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import get from 'lodash-es/get';
import keys from 'lodash-es/keys';

import log from '@dw/loglevel';
import URI from '@dw/urijs-esm';

// Redux
import * as app from '../../../redux/app';
import * as routerSelector from '../selectors';
import * as routerActions from '../actions.js';
import * as history from '@dreamworld/web-util/history.js';

// Routers
import { convertV1ToV4 } from './convert-v1-to-v4.js';
import { convertV2V3ToV4 } from './convert-v2-v3-to-v4.js';
import { validateURL } from './validate-url.js';
import { waitForAuth } from './wait-for-auth.js';
import { loginPageRouter } from './login-page-router.js';
import { computeFullURL } from './compute-full-url.js';
import { redirectToLoginAndSignup } from './redirect-to-login-and-signup.js';
import { signupRouter } from './signup-router.js';
import { forgotPasswordRouter } from './forgot-password.js';
import { boardExplorerRouter } from './board-explorer-router.js';
import { joinBoardPageRouter } from './join-board-page-router.js';
import { boardPageRouter } from './board-page-router.js';
import { blankPageRouter } from './blank-page-router.js';
import { pageNotFoundRouter } from './page-not-found-router.js';
import { accountPreferencesRouter } from './account-preferences.js';
import { manageAccountRouter } from './manage-account-router.js';
import { amplitudeSignupEventTrackingRouter } from './amplitude-signup-event-tracking-router.js';
import { leaveAppDonePage } from './leave-app-done-page-router.js';
import { unsubscribePage } from './unsubscribe-page-router.js';

import { UPDATE_ROUTER, ACCOUNT_CHANGED, navigate } from '../actions';
import { dialogRouter } from './dialog-router';
import { viewPageRouter } from './view-page-router';
import { authorizedFileStorePage } from './authorize-file-store-router.js';
import { preferredStoreSelectionPage } from './change-preferred-store-router.js';


export class Router {
  /**
   * @constructor
   */
  constructor(store) {
    if (!store) {
      throw new 'Invalid arguments are passed.'();
    }

    this._log = log.getLogger('router');
    this._log.setLevel(3);

    //Redux store of an application.
    this._store = store;
    this._subscribeAuthData();
    const parsePage = composeRouter(accountPreferencesRouter, boardExplorerRouter, manageAccountRouter, boardPageRouter, viewPageRouter, pageNotFoundRouter);
    this._router = composeRouter(
      blankPageRouter,
      amplitudeSignupEventTrackingRouter,
      validateURL,
      leaveAppDonePage,
      unsubscribePage,
      authorizedFileStorePage,
      preferredStoreSelectionPage,
      waitForAuth,
      joinBoardPageRouter,
      loginPageRouter,
      signupRouter,
      convertV1ToV4,
      convertV2V3ToV4,
      computeFullURL,
      forgotPasswordRouter,
      redirectToLoginAndSignup,
      parsePage
    );
    this._dialogRouter = composeRouter(dialogRouter);

    history.init(this.navigate.bind(this));
    history.registerFallbackCallback(this._redirectFallBackPage.bind(this));
    history.registerInternalURLCallback(routerActions.isAppUrl);
    history.registerTransformURLCallback(routerActions.getPWAUrl);
  }

  /**
   * Find out page and dialog from current location and update redux store.
   */
  async navigate() {
    this._log.info('router navigate start: ', new Date().getTime());

    if (this._user !== undefined) {
      this._log.info('real router navigate start (auth is known): ', new Date().getTime());
    }
    try {
      this._lastRequestController && this._lastRequestController.abort();
      this._lastRequestController = new AbortController();
      const signal = this._lastRequestController.signal;
      let routerData = await this._router({
        path: decodeURIComponent(window.location.pathname),
        query: decodeURIComponent(window.location.search),
        signal: signal,
        store: this._store,
        user: this._user,
        ownedAccounts: this._ownedAccounts,
        accessibleAccounts: this._accessibleAccounts,
        accessibleBoards: this._accessibleBoards,
        lastAccessibleAccount: this._lastAccessibleAccount,
        wideLayout: app.selectors.wideLayout(this._store.getState()),
      });

      // Dialog router

      routerData.hash = window.location.href.split('#')[1];
      if (routerData.hash) {
        routerData = await this._dialogRouter(routerData);
      }

      this._lastRequestController = undefined;

      if (routerData.redirect) {
        if(typeof routerData.redirect === 'string') {
          const uri = new URI();
          const uriNew = new URI(routerData.redirect);

          if(uri.query() && !uriNew.query()) {
            uriNew.query(uri.query());
          }

          if(uri.hash() && !uriNew.hash()) {
            uriNew.hash(uri.hash());
          }

          navigate(uriNew.toString(), true);
          return;
        }
        navigate(routerData.redirect, true);
        return;
      }

      if (routerData.page) {
        this._updateRouter({
          accountId: routerData.accountId || '',
          page: {
            name: routerData.page.name || 'LOADING',
            params: routerData.page.params || {},
          },
          dialog: {
            name: (routerData.dialog && routerData.dialog.name) || '',
            params: (routerData.dialog && routerData.dialog.params) || {},
          },
        });
        this._log.info('router navigate complete: ', new Date().getTime(), routerData.page);
      }
    } catch (e) {
      this._log.warn(e);
      this._lastRequestController = undefined;
    }
  }

  /**
   * Refresh route data for the current page.
   * It's mainly used when page's context is changed. e.g. Card is moved to another board etc.
   * When such condition is detected by the UI app, it instructs router to refresh the data, so view can be updated
   * accordingly. e.g. View can read data from the updated path. Until router doesn't update the data, view can't know
   * where the card/page is moved.
   * @public
   */
  refresh() {
    this.navigate();
  }

  /**
   * Router `go` method called.
   * @param {Number} count
   * @public
   */
  go(count) {
    history.go(count);
  }

  /**
   * Router `back` method called.
   * @public
   */
  back() {
    history.back();
  }

  /**
   * Router `forward` method called.
   * @public
   */
  forward() {
    history.forward();
  }

  /**
   * @param {Object} state state for this `url`.
   * @param {String} title title of opened page
   * @param {String} url New push url.
   * Router `pushState` method called.
   * @public
   */
  pushState(state = {}, title = '', url, autoBack, autoBackCount) {
    history.navigate(url, {state, title, autoBack, autoBackCount});
  }

  /**
   * @param {Object} state state for this `url`.
   * @param {String} title title of opened page
   * @param {String} url New replace url.
   * Router `replaceState` method called.
   * @public
   */
  replaceState(state = {}, title = '', url, autoBack, autoBackCount) {
    history.navigate(url, {state, title, autoBack, replace: true, autoBackCount});
  }

  /**
   * Redirect on fallback page.
   * when action dialog is opened then closed action dialog.
   * when dialog(not action dialog) is opend then redirect on dialog context page.
   * Otherwise redirect on account home page.
   * @protected
   */
  _redirectFallBackPage() {
    if (this._isActionDialogOpened()) {
      this._closeActionDialog();
      return;
    }

    if (this._isBoardCardOpened()) {
      this._redirectToBoardPage();
      return;
    }

    if(this._isAppLevelDialog()) {
      this._closeAppLevelDialog();
      return;
    }

    this.redirectToAccountHomePage();
  }

  /**
   * @returns {Boolean} `true` when app level dialog is opened. `false` otherwise.
   * @protected
   */
  _isAppLevelDialog(){
    let state = this._store.getState();
    return !!routerSelector.dialogName(state);
  }

  /**
   * Close app level dialog.
   * @protected
   */
  _closeAppLevelDialog() {
    const uri = new URI();
    uri.hash('');
    this.replaceState({}, '', uri.toString());
  }

  /**
   * @returns {Boolean} `true` when action dialog is opened. `false` otherwise.
   * @protected
   */
  _isActionDialogOpened() {
    let state = this._store.getState();
    return !!routerSelector.actionName(state);
  }

  /**
   * Close action dialog.
   * @protected
   */
  _closeActionDialog() {
    let state = this._store.getState();
    routerActions.removeActionParam(routerSelector.actionName(state));
  }

  /**
   * @returns {Boolean} `true` when card dialog is opened in board context. `false` otherwise.
   * @protected
   */
  _isBoardCardOpened() {
    let state = this._store.getState();
    return routerSelector.dialogName(state) === 'CARD' && routerSelector.pageName(state) === 'BOARD';
  }

  /**
   * Redirect to board page.
   * @protected
   */
  _redirectToBoardPage() {
    let state = this._store.getState();
    let accountId = routerSelector.accountId(state);
    let boardId = routerSelector.dialogBoardId(state);
    this.replaceState({}, '', `/${accountId}/board/${boardId}`);
  }

  /**
   * Redirect to account home page.
   * @protected
   */
  redirectToAccountHomePage() {
    const state = this._store.getState();
    const routerAccId = routerSelector.accountId(state);
    const lastAccessibleAccId = get(this._auth, 'lastAccessibleAccount');
    const ownedAccId = get(this._auth.ownedAccounts, '0');
    const firstAccessibleAccId = get(keys(this._auth.accessibleAccounts), '0');

    const accountId = routerAccId || lastAccessibleAccId || ownedAccId || firstAccessibleAccId;

    if (!accountId) {
      throw new Error(
        `No accountId found: routerAccId: ${routerAccId}, lastAccessibleAccId: ${lastAccessibleAccId}, ownedAccId: ${ownedAccId}, firstAccessibleAccId: ${firstAccessibleAccId}`
      );
    }
    this.replaceState({}, '', `/${accountId}/home/favorite/boards`);
  }

  /**
   * Start reading auth's data from store
   */
  _subscribeAuthData() {
    ReduxUtils.subscribe(this._store, 'auth', (auth) => {
      if (auth !== null && isEmpty(auth)) {
        return;
      }

      this._auth = auth || {};
      this._user = this._auth.user || null;
      this._ownedAccounts = this._auth.ownedAccounts || [];
      this._accessibleAccounts = this._auth.accessibleAccounts || {};
      this._lastAccessibleAccount = this._auth.lastAccessibleAccount || '0';
      this._accessibleBoards = this._auth.accessibleBoards || {};
      this.navigate();
    });
  }

  /**
   * Dispatch 'UPDATE_ROUTER' event on store to update redux state
   */
  _updateRouter(router) {
    if (!router) {
      return;
    }
    const state = this._store.getState();
    if (state && state.router && isEqual(state.router, router)) {
      return;
    }

    const embedContent = routerSelector.embedLoginSignup(state) || routerSelector.embedTrelloImport(state) || false;
    const uri = new URI();
    if(!embedContent) {
      amplitudeLogEvent('page view', {url: uri.toString().replace(`${uri.protocol()}:\/\/${uri.host()}`, '')});
    }

    this._store.dispatch({
      type: UPDATE_ROUTER,
      router,
    });

    //If account is changed, dispatch an action. But, don't dispatch if it's set for the first time.
    let prevAccountId = state.router.accountId;
    if (router.accountId && prevAccountId && prevAccountId !== router.accountId) {
      this._store.dispatch({
        type: ACCOUNT_CHANGED,
        prevAccountId: prevAccountId,
        currentAccountId: router.accountId,
      });
    }
  }
}
