import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import forEach from 'lodash-es/forEach';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';

/**
 * @param {Object} state redux state
 * @returns {Array} card list based on order.
 */
export const cards = createSelector(
  (state) => get(state, 'fresh-user-wizard.cards', {}),
  (cards) => {
    const _cards = [];
    forEach(cards, (attrs, id) => {
      _cards.push({id, ...attrs});
    });
    return sortBy(_cards, ['columnOrder']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {String} current mode of fresh-user-wizard.
 */
export const mode = (state) => get(state, 'fresh-user-wizard.mode');

/**
 * @param {Object} state redux state
 * @returns {String} fresh user create board status. 
 *                   Possible values: INIT, IN_PROGRESS, SUCCESS, FAILED
 */
export const status = (state) => get(state, 'fresh-user-wizard.status', 'INIT');

/**
 * @param {Object} state redux state
 * @returns {String} board name.
 */
export const boardName = (state) => get(state, 'fresh-user-wizard.boardName', '');