import URI from '@dw/urijs-esm';

export const blankPageRouter = (data) => {
  return new Promise(function(resolve, reject) {
    if(data.path.startsWith('/blank-page')) {
      const params = new URI().query(true);
      data.page = {
        name: 'BLANK_PAGE',
        params
      };
      resolve(data);
      return;
    }
    reject(data);
  });
}