import get from 'lodash-es/get';
import URI from '@dw/urijs-esm';
import * as app from '../app';
import * as routerUtils from '../router/routers/router-utils';
import * as signup from '../signup';
import * as board from '../board';
import * as router from '../router';
import * as knownFeaturesSelectors from '../known-features/selectors.js';

/**
 * @param {Object} state Redux state
 * @param {Number} id
 * @returns {Array} breadcrumbs for given canvas.
 */
export const breadcrumbs = (state, id) => get(state, `canvas.${id}.breadcrumbs`);

/**
 * @param {Object} state Redux state
 * @param {Number} id canvas Id.
 * @returns {String} canvas page content link for given canvas. e.g. `{webappUiBaseDomain}/canvas/{canvasId}`.
 */
export const getCanvasPageContentLink = (state, id, cpageView = false) => {
  const config = app.selectors.config(state);
  const uri = new URI(config.webappUIBaseUrl);
  uri.pathname(cpageView ? `/canvas/c/${routerUtils.to64(id)}` : `/canvas/${routerUtils.to64(id)}`);
  const useCaseBoard = !signup.selectors.signupCompleted(state) &&
    board.selectors.isUseCaseBoard(state) &&
    !knownFeaturesSelectors.isKnown({ state, name: 'INNER_CANVAS' });
  if (useCaseBoard) {
    uri.setSearch('use-case-board', "true");
  }
  return uri.toString();
}

/**
 * @param {Object} state Redux state.
 * @param {Number} id Board Id.
 * @returns {Boolean}
 */
export const isCavasPageReady = (state, id) => {
  id = id || router.selectors.canvasRefId(state) || router.selectors.pageBoardId(state);
  return get(state, `canvas.${id}.ready`, false);
}

/**
 * @param {Object} state Redux state.
 * @param {Number} id Board Id.
 * @returns {Object} inner canvas rect
 */
 export const innerCanvasRect = (state, id) => {
  id = id || router.selectors.canvasRefId(state) || router.selectors.pageBoardId(state);
  return get(state, `canvas.${id}.innerCanvasRect`, {});
}