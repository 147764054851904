import { css } from "lit-element";
import { DwInput } from "@dreamworld/dw-input/dw-input";
import typographyLiterals from "../theme/typography-literals.js";

/**
 * Extended version of [dw-input](https://github.com/DreamworldSolutions/dw-input).
 */
export class KerikaInput extends DwInput {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          --mdc-theme-primary: var(--secondary-text-color);
          --dw-input-outlined-hover-border-color: rgba(0, 0, 0, 0.2) !important;
          --dw-input-outlined-idle-border-color: rgba(0, 0, 0, 0.2) !important;
          --dw-input-outlined-disabled-border-color: rgba(
            0,
            0,
            0,
            0.1
          ) !important;
          ${typographyLiterals.body1};
          --kerika-input-background-color: var(--mdc-theme-surface);
        }

        .mdc-text-field.mdc-text-field--outlined,
        :host([showAsFilled]) .mdc-text-field:not(.mdc-text-field--disabled),
        :host([showAsFilled]) .mdc-text-field::before,
        :host([showAsFilled]) .mdc-text-field::after {
          background: var(--kerika-input-background, transparent);
        }

        :host([showAsFilled]) .mdc-text-field,
        :host([showAsFilled]) .mdc-text-field .mdc-text-field__input,
        :host([showAsFilled]) .mdc-text-field .mdc-text-field__input:hover {
          height: var(--kerika-input-show-as-filled-height, 48px);
        }

        :host([showAsFilled]) .mdc-text-field .mdc-text-field__input,
        :host([showAsFilled]) .mdc-text-field--dense .mdc-text-field__input {
          padding: var(--kerika-input-padding, 4px 4px 4px 4px);
        }

        :host([showAsFilled][type="password"]) .mdc-text-field__input,
        :host([showAsFilled][type="password"])
          .mdc-text-field--dense
          .mdc-text-field__input {
          padding: var(--kerika-input-padding, 4px 48px 4px 4px);
        }

        :host([showAsFilled][large]) .mdc-text-field {
          height: 48px;
        }

        :host([showAsFilled][large]) .mdc-text-field .mdc-text-field__input,
        :host([showAsFilled][large])
          .mdc-text-field
          .mdc-text-field__input:hover {
          height: 48px;
          padding: var(--kerika-input-padding, 8px 0px);
          ${typographyLiterals.headline5};
        }

        :host([showAsFilled]) .mdc-text-field .mdc-text-field__input,
        :host([showAsFilled]) .mdc-text-field .mdc-text-field__input:hover {
          border-bottom-color: rgba(0, 0, 0, 0.2) !important;
        }

        :host([showAsFilled])
          .mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--invalid
          .mdc-text-field__input,
        :host([showAsFilled])
          .mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--invalid
          .mdc-text-field__input:hover {
          border-bottom-color: var(--mdc-theme-error, #b00020) !important;
        }

        :host([showAsFilled])
          .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-text-field__input,
        :host([showAsFilled])
          .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-text-field__input:hover {
          border-bottom-color: var(--mdc-theme-secondary) !important;
        }

        :host([showAsFilled][type="password"])
          .mdc-text-field:not(.mdc-text-field--disabled)
          .mdc-text-field__icon,
        :host([showAsFilled][type="password"])
          .mdc-text-field--disabled
          .mdc-text-field__icon {
          position: absolute;
          right: 0;
          top: 0;
        }

        :host([showAsFilled]) .mdc-text-field + .mdc-text-field-helper-line {
          padding: 0px 4px;
        }

        :host([showAsFilled][large])
          .mdc-text-field
          + .mdc-text-field-helper-line {
          padding: 0px 8px;
        }

        .mdc-text-field--outlined .mdc-notched-outline__leading,
        .mdc-text-field--outlined .mdc-notched-outline__notch,
        .mdc-text-field--outlined .mdc-notched-outline__trailing {
          border-width: 1px !important;
        }

        .mdc-text-field__input {
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          letter-spacing: inherit;
          line-height: inherit;
        }

        .mdc-text-field--outlined .mdc-text-field__input,
        :host([showAsFilled]) .mdc-text-field__input {
          padding: var(--kerika-input-padding, 12px 12px 7px);
        }

        .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
          padding: var(--kerika-input-padding, 7px 12px 7px);
        }

        .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
          .mdc-notched-outline__leading,
        .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
          .mdc-notched-outline__notch,
        .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
          .mdc-notched-outline__trailing {
          border-right-color: var(
            --kerika-input-border-error-color,
            var(--mdc-theme-error, #b00020)
          ) !important;
          border-color: var(
            --kerika-input-border-error-color,
            var(--mdc-theme-error, #b00020)
          ) !important;
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-notched-outline__leading,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-notched-outline__notch,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-notched-outline__trailing,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
          .mdc-notched-outline__leading,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
          .mdc-notched-outline__notch,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
          .mdc-notched-outline__trailing {
          border-right-color: var(
            --kerika-input-border-color,
            rgba(0, 0, 0, 0.2)
          ) !important;
          border-color: var(
            --kerika-input-border-color,
            rgba(0, 0, 0, 0.2)
          ) !important;
        }

        .mdc-text-field--outlined
          .mdc-notched-outline
          .mdc-notched-outline__trailing,
        .mdc-text-field--outlined
          .mdc-notched-outline
          .mdc-notched-outline__leading,
        .mdc-text-field--outlined
          .mdc-notched-outline
          .mdc-notched-outline__notch {
          background-color: var(--kerika-input-background-color, transparent);
        }

        :host([showAsFilled]) .mdc-text-field .mdc-text-field__input {
          border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        }

        :host([showAsFilled]) .mdc-text-field.mdc-text-field--disabled {
          background-color: var(--kerika-input-background-color, transparent);
        }

        :host([showAsFilled])
          .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-text-field__input {
          border-bottom-color: var(--mdc-theme-secondary) !important;
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-notched-outline__leading,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-notched-outline__notch,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
          .mdc-notched-outline__trailing {
          border-right-color: var(
            --kerika-input-border-color,
            var(--mdc-theme-secondary, rgba(0, 0, 0, 0.2))
          ) !important;
          border-color: var(
            --kerika-input-border-color,
            var(--mdc-theme-secondary, rgba(0, 0, 0, 0.2))
          ) !important;
        }

        /* Default padding and margin for textarea */
        .mdc-text-field--outlined.mdc-text-field--textarea
          .mdc-text-field__input,
        .mdc-text-field--textarea .mdc-text-field__input {
          padding: var(--kerika-input-padding, 0px 16px 16px);
          margin: var(--kerika-input-margin, 8px 1px 1px 0px);
        }

        /* Default padding and margin for simple input */
        .mdc-text-field--outlined .mdc-text-field__input {
          padding: var(--kerika-input-padding, 12px 16px 14px);
          margin: var(--kerika-input-margin, 0px);
        }

        /* Everywhere we can use 2 px border-radius for textarea, so updating same to input also.  */
        .mdc-text-field--outlined
          .mdc-notched-outline
          .mdc-notched-outline__trailing {
          border-radius: 0px 2px 2px 0px;
        }
        .mdc-text-field--outlined
          .mdc-notched-outline
          .mdc-notched-outline__leading {
          border-radius: 2px 0px 0px 2px;
        }

        .mdc-text-field--outlined
          .mdc-notched-outline
          .mdc-notched-outline__trailing,
        .mdc-text-field--outlined
          .mdc-notched-outline
          .mdc-notched-outline__leading,
        .mdc-text-field--outlined
          .mdc-notched-outline
          .mdc-notched-outline__notch {
          background-color: var(--kerika-input-background-color, transparent);
        }

        .mdc-text-field.mdc-text-field--with-leading-icon
          .mdc-text-field__icon {
          top: var(--kerika-input-leading-icon-top, inherit);
        }

        .mdc-text-field.mdc-text-field--focused.mdc-text-field--with-leading-icon
          .mdc-text-field__icon {
          --dw-icon-color: var(
            --kerika-input-leading-icon-focused-color,
            inherit
          );
        }

        .mdc-text-field.mdc-text-field--focused .mdc-text-field__input {
          color: var(
            --kerika-input-focused-text-color,
            var(--mdc-theme-text-primary)
          ) !important;
        }

        .mdc-text-field--outlined.mdc-text-field--focused
          .mdc-notched-outline
          .mdc-notched-outline__trailing,
        .mdc-text-field--outlined.mdc-text-field--focused
          .mdc-notched-outline
          .mdc-notched-outline__leading,
        .mdc-text-field--outlined.mdc-text-field--focused
          .mdc-notched-outline
          .mdc-notched-outline__notch {
          background-color: var(
            --kerika-input-focused-background-color,
            var(--mdc-theme-surface, transparent)
          );
        }

        .mdc-text-field.mdc-text-field--focused
          .mdc-text-field__input::placeholder {
          color: var(
            --kerika-input-focused-placeholder-color,
            var(--mdc-theme-text-disabled, rgba(0, 0, 0, 0.2))
          ) !important;
        }

        .mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
          -webkit-transform: var(--kerika-input-floating-label-float-above, translateY(-120%) scale(0.8));
          transform: var(--kerika-input-floating-label-float-above, translateY(-120%) scale(0.8));
        }

        /* STARTS: select style */
        :host([role="select"])
          .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-text-field__input {
          padding-right: 0;
          margin-right: 55px;
          padding-right: 16px;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: var(
            --kerika-input-select-font-family,
            Roboto,
            sans-serif
          );
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined {
          height: var(--kerika-input-select-height, 56px);
        }

        :host([role="select"]) .mdc-text-field + .mdc-text-field-helper-line {
          padding-left: 8px;
          padding-right: 8px;
        }

        :host([role="select"]) .mdc-text-field--with-trailing-icon.mdc-text-field--outlined.mdc-text-field--dense {
          height: 48px;
        }

        :host([role="select"]) .mdc-text-field--with-trailing-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
          right: var(--dw-input-icon-right, 12px);
          top: var(--dw-input-icon-top, 12px);
        }

        :host([wide-layout]) .mdc-text-field--outlined {
          --dw-input-height: 40px;
          --kerika-input-padding: var(
            --kerika-wide-layout-input-padding,
            12px 8px
          );
          ${typographyLiterals.body2};
        }

        :host([wide-layout])
          .mdc-text-field--with-trailing-icon.mdc-text-field--outlined {
          --kerika-input-select-height: 40px;
          ${typographyLiterals.body2};
        }

        :host([wide-layout][multiline]) dw-textarea {
          --kerika-input-padding: 0;
          --dw-textarea-padding: 7px 8px;
          ${typographyLiterals.body2};
        }

        :host([role="select"][wide-layout])
          .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-text-field__input {
          margin: var(--kerika-input-select-margin, 0px 32px 0px 0px);
          padding: var(--kerika-input-select-padding, 12px 0px 12px 12px);
          height: var(--kerika-input-select-input-height, 40px);
          background-color: var(
            --kerika-input-select-input-background-color,
            transparent
          );
          background-image: var(
            --kerika-input-select-input-background-image,
            none
          );
          cursor: pointer;
        }

        :host([role="select"][wide-layout])
          .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-text-field__icon {
          right: var(--dw-input-icon-right, 8px);
          top: var(--dw-input-icon-top, 8px);
        }

        :host([wide-layout]) .mdc-text-field--outlined.mdc-text-field--dense {
          height: 40px;
          ${typographyLiterals.body2};
        }

        :host([wide-layout]) .mdc-text-field--outlined .mdc-floating-label {
          ${typographyLiterals.body2};
          top: 12px !important;
        }

        :host([wide-layout])
          .mdc-floating-label.mdc-floating-label--float-above {
          transform: translateY(-100%) scale(0.75);
        }

        :host(:not([showAsFilled])[large]) .mdc-text-field {
          height: 56px;
        }

        :host(:not([showAsFilled])[large]) .mdc-text-field,
        :host(:not([showAsFilled])[large]) .mdc-text-field .mdc-text-field__input,
        :host(:not([showAsFilled])[large]) .mdc-text-field .mdc-text-field__input:hover {
          height: var(--kerika-input-show-as-filled-height, 56px);
        }

        :host(:not([showAsFilled])[large]) .mdc-text-field .mdc-text-field__input,
        :host(:not([showAsFilled])[large])
          .mdc-text-field
          .mdc-text-field__input:hover {
          height: 56px;
          ${typographyLiterals.headline6};
        }

        :host([role="select"][large])
          .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-text-field__input {
          margin: var(--kerika-input-select-margin, 0px 48px 0px 0px);
          padding: var(--kerika-input-select-padding, 12px 0px 12px 12px);
          height: var(--kerika-input-select-input-height, 56px);
          background-color: var(
            --kerika-input-select-input-background-color,
            transparent
          );
          background-image: var(
            --kerika-input-select-input-background-image,
            none
          );
          cursor: pointer;
        }

        :host([role="select"][large])
          .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-text-field__icon {
          right: var(--dw-input-icon-right, 12px);
          top: var(--dw-input-icon-top, 16px);
        }

        :host([large]) .mdc-text-field--outlined .mdc-floating-label {
          ${typographyLiterals.headline6};
          top: 16px !important;
        }

        :host([large])
          .mdc-floating-label.mdc-floating-label--float-above {
          transform: translateY(-120%) scale(0.75);
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-text-field__icon {
          height: 24px;
          width: 24px;
          top: var(--kerika-input-select-trailing-icon-top, unset);
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-text-field__input {
          border-right-color: rgba(0, 0, 0, 0.2) !important;
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(mdc-text-field--invalid)
          .mdc-text-field__input:hover {
          border-right-color: rgba(0, 0, 0, 0.2) !important;
          transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined.mdc-text-field--focused
          .mdc-text-field__input {
          border-right-color: rgba(0, 0, 0, 0.2) !important;
          transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined.mdc-text-field--invalid
          .mdc-text-field__input {
          border-right-color: var(--mdc-theme-error, #b00020) !important;
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined.mdc-text-field--disabled
          .mdc-text-field__input {
          border-right-color: rgba(0, 0, 0, 0.1) !important;
        }

        :host([role="select"])
          .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-text-field__icon {
          right: var(--dw-input-icon-right, 16px);
          top: var(--dw-input-icon-top, 16px);
        }

        .mdc-text-field__input::placeholder,
        .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
          .mdc-floating-label {
          color: var(--mdc-theme-text-disabled, rgba(0, 0, 0, 0.2)) !important;
        }

        .mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--focused:not(.mdc-text-field--invalid)
          .mdc-floating-label {
          color: var(--mdc-theme-secondary) !important;
        }

        :host(:not([wide-layout])[role="select"]) .mdc-notched-outline__notch {
          max-width: calc(100% - 54px);
        }


        :host([required]) .mdc-text-field--textarea .mdc-floating-label::after {
          content: "*";
          margin-left: 1px;
        }
        /* ENDS: select style */
      `,
    ];
  }

  static get properties() {
    return {
      /**
       * The role of Input. Use to style input as per role
       * possible values: input and select
       * Default value is input
       * E.g. If role is "select" it shows border before sufix icon
       */
      role: { type: String, reflect: true },

      /**
       * Show a large input
       */
      large: { type: Boolean, reflect: true },
    };
  }

  constructor() {
    super();
    this.truncateOnBlur = true;
    this.role = "input";
  }

  /**
   * Getter of large property.
   */
  get large() {
    return this._large;
  }

  /**
   * Setter of large property.
   */
  set large(value) {
    let oldValue = this._large;
    if (oldValue == value) {
      return;
    }
    this._large = value;
    this.requestUpdate("large", oldValue);
  }

  get role() {
    return this.__role;
  }

  set role(value) {
    let oldValue = this.__role;
    if (oldValue === value) {
      return;
    }

    if (value == "select") {
      this.iconTrailing = "keyboard_arrow_down";
    } else {
      this.iconTrailing = "";
    }
    this.__role = value;
    this.requestUpdate("_messages", oldValue);
  }

  /**
   * Reset input value.
   */
  reset() {
    if (this._textFieldInstance) {
      this._textFieldInstance.value = "";
      this._textFieldInstance.valid = true;
      this.value = "";
    }
  }

  /**
   * Blur focus if role is select.
   * @override
   */
  _onFocus(e) {
    super._onFocus && super._onFocus(e);
    if (
      this.role === "select" &&
      this._textFieldInstance &&
      this._textFieldInstance.input_ &&
      this._textFieldInstance.input_.blur
    ) {
      setTimeout(() => {
        this._textFieldInstance &&
          this._textFieldInstance.input_ &&
          this._textFieldInstance.input_.blur();
      });
    }
  }
}

customElements.define("kerika-input", KerikaInput);
