import * as firestoreRedux from '@dreamworld/firestore-redux';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import isEmpty from 'lodash-es/isEmpty';
import values from 'lodash-es/values';
import forEach from 'lodash-es/forEach';
import isEqual from 'lodash-es/isEqual.js';
import * as cardSelectors from '../card/selectors.js';
import * as columnSelectors from '../columns/selectors.js';
import * as authSelectors from '../auth/selectors.js';
import * as board from '../board';
import * as views from '../views';
import * as attachmentsSelectors from '../attachments/selectors.js';
import * as taskSelectors from '../task/selectors.js';
import { createSelector } from 'reselect';
import { getIdWoPrefix } from '../../utils';
import * as chatSelectors from '../chat/selectors.js';

/**
 * @param {*}
 *  @property {Object} state Redux state
 *  @property {String} cardId Card Id.
 * @returns {Object} card unread details. e.g. { created: true, title: true, description: true, dueDate: true, tasks: true, chats: true, attachments: true }
 */
export const cardUnreadChanges = createSelector(
  ({ state, cardId }) => board.selectors.isBoardActive(state, cardSelectors.cardBoardId(state, cardId)),
  ({ state, cardId }) =>
    firestoreRedux.selectors.doc(
      state,
      'card-unread-user-details',
      `cuu_${getIdWoPrefix({ id: cardId, prefix: 'crd_' })}_${getIdWoPrefix({ id: authSelectors.currentUserId(state), prefix: 'usr_' })}`
    ),
  (isBoardActive, doc) => {
    if (!isBoardActive) {
      return {};
    }

    if(!doc || !doc.anyUnread) {
      return {};
    }

    const cardUnreads = get(doc, 'unread', {});
    if (cardUnreads.created) {
      return { created: true };
    }
    return cardUnreads;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 3000,
    },
  }
);

/**
 * @returns { Boolean } card is unread or not.
 */
export const isCardUnread = createSelector(
  ({ state, cardId }) => cardUnreadChanges({state, cardId}),
  (_unreadUpdates) => {
    let _unread = false;
    forEach(_unreadUpdates, (value) => {
      if(value) {
        _unread =  true;
        return false;
      }
    })
    return _unread;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 3000,
    },
  }
);

/**
 * @param {*}
 *  @property {Object} state Redux state
 *  @property {String} columnId Selected Column Id.
 * @returns {Boolean} `true` When one of the selected card has unread highlights. s
 * 											When no card is selected, returns `true` if column has unread highlights
 */
export const isUnreadChangesOnSelectedCards = ({ state, columnId }) => {
  const _selectedCards = board.selectors.selectedCards(state);
  const hasColumnUnread = isColumnUnread({ state, columnId });
  if (!hasColumnUnread || isEmpty(_selectedCards)) {
    return hasColumnUnread;
  }

  let hasUnreaChange = false;
  forEach(_selectedCards, (cardId) => {
    if (values(cardUnreadChanges({ state, cardId })).includes(true)) {
      hasUnreaChange = true;
      return;
    }
  });
  return hasUnreaChange;
};

/*
 * @returns {Boolean} `true` when any of the selected or all cards in VIEWS has unread highlights.
 */
export const isUnreadChangesOnViewsSelectedCards = ({ state, columnId }) => {
  const selectedCards = views.selectors.selectedCards(state);
  const _cardsList = views.selectors.cardsList({ state, columnId });

  const cardsByColumn = get(_cardsList, 'cards');
  let allCards = [];
  if (isEmpty(selectedCards)) {
    forEach(cardsByColumn, (obj) => {
      allCards.push(obj.cardId);
    });
  }

  const cards = !isEmpty(selectedCards) ? selectedCards : allCards;
  let hasUnreaChange = false;
  forEach(cards, (cardId) => {
    if (values(cardUnreadChanges({ state, cardId })).includes(true)) {
      hasUnreaChange = true;
      return false;
    }
  });
  return hasUnreaChange;
};

/**
 * @returns {Object} given column unread details.
 * @param {Object} state State
 * @param {String} columnId Column Id
 */
export const columnUnread = createSelector(
  ({ state, columnId }) => board.selectors.isBoardActive(state, columnSelectors.columnBoardId(state, columnId)),
  ({ state, columnId }) =>
    firestoreRedux.selectors.doc(
      state,
      'column-unread-user-details',
      `coluu_${getIdWoPrefix({ id: columnId, prefix: 'col_' })}_${getIdWoPrefix({
        id: authSelectors.currentUserId(state),
        prefix: 'usr_',
      })}`
    ),
  (isBoardActive, doc) => {
    if (!isBoardActive) {
      return {};
    }

    if(!doc || !doc.anyUnread) {
      return false;
    }
    
    return get(doc, 'unread', {});
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @returns {Boolean} given column is unread or not.
 * @param {Object} state State
 * @param {String} columnId Column Id
 */
export const isColumnUnread = createSelector(
  ({ state, columnId }) => columnUnread({ state, columnId }),
  (unread) => {
    return get(unread, 'cards', false);
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @param {*}
 *  @property {Object} state Redux state
 *  @property {String} boardId Board Id
 * @returns {Object} unread details of board e.g. { chats: true, attachments: true }
 */
export const boardUnread = createSelector(
  ({ state, boardId }) => board.selectors.isBoardActive(state, boardId),
  ({ state, boardId }) =>
    firestoreRedux.selectors.doc(
      state,
      'board-unread-user-details',
      `buu_${getIdWoPrefix({ id: boardId, prefix: 'brd_' })}_${getIdWoPrefix({
        id: authSelectors.currentUserId(state),
        prefix: 'usr_',
      })}`
    ),
  (isBoardActive, doc) => {
    if (!isBoardActive) {
      return {};
    }
    
    if(!doc || !doc.anyUnread) {
      return false;
    }

    return get(doc, 'unread', {});
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param {String} attachmentId 
 * @returns {Object} attachment unread.
 */
export const _attachmentUnread = (state, attachmentId) => {
  return attachmentId && attachmentId.startsWith('ca_')
    ? firestoreRedux.selectors.doc(
        state,
        'card-attachment-unread-user-details',
        `cauu_${getIdWoPrefix({ id: attachmentId, prefix: 'ca_' })}_${getIdWoPrefix({
          id: authSelectors.currentUserId(state),
          prefix: 'usr_',
        })}`
      )
    : firestoreRedux.selectors.doc(
        state,
        'board-attachment-unread-user-details',
        `bauu_${getIdWoPrefix({ id: attachmentId, prefix: 'ba_' })}_${getIdWoPrefix({
          id: authSelectors.currentUserId(state),
          prefix: 'usr_',
        })}`
      );
};

/**
 * @param {Object} state Redux state.
 * @param {String} attachmentId Attachment ID passed to give attachment item model.
 * @return { Object } Card attachment unread model.
 *                    e.g. {created: true, title: true, content: true}
 */
export const cardAttachmentUnread = createSelector(
  (state, attachmentId) => board.selectors.isBoardActive(state, attachmentsSelectors.attachmentBoardId(state, attachmentId)),
  (state, attachmentId) => _attachmentUnread(state, attachmentId),
  (isBoardActive, doc) => {
    if (!isBoardActive) {
      return {};
    }

    if(!doc || !doc.anyUnread) {
      return {};
    }

    const unreads = get(doc, 'unread', {}) || {};
    if (unreads.created) {
      return { created: true };
    }
    return unreads;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 100,
    },
  }
);

/**
 * @param {Object} state Redux state.
 * @param {String} attachmentId Attachment ID passed to give attachment item model.
 * @return { Object } Board attachment unread model.
 *                    e.g. {created: true, title: true, content: true}
 */
export const boardAttachmentUnread = createSelector(
  (state, attachmentId) => board.selectors.isBoardActive(state, attachmentsSelectors.attachmentBoardId(state, attachmentId)),
  (state, attachmentId) => _attachmentUnread(state, attachmentId),
  (isBoardActive, doc) => {
    if (!isBoardActive) {
      return {};
    }

    if(!doc || !doc.anyUnread) {
      return {};
    }

    const unreads = get(doc, 'unread', {}) || {};
    if (unreads.created) {
      return { created: true };
    }
    return unreads;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 100,
    },
  }
);

/**
 * @param {Object} state Redux state.
 * @param {String} taskId task ID passed to give task item model.
 * @return { Object } task unread model.
 *                    e.g. {created, title, dueDate, closed}
 */
export const _taskUnread = (state, taskId) => firestoreRedux.selectors.doc(state, 'task-unread-user-details', `tuu_${getIdWoPrefix({id: taskId, prefix: 't_' })}_${getIdWoPrefix({id: authSelectors.currentUserId(state), prefix: 'usr_'})}`);
export const taskUnread = createSelector(
  (state, taskId) => board.selectors.isBoardActive(state, taskSelectors.taskBoardId(state, taskId)),
  (state, taskId) => _taskUnread(state, taskId),
  (isBoardActive, doc) => {
    if (!isBoardActive) {
      return {};
    }

    if(!doc || !doc.anyUnread) {
      return {};
    }

    const unreads = get(doc, 'unread', {}) || {};
    if (unreads.created) {
      return { created: true };
    }

    if(unreads.closed) {
      unreads.dueDate = false;
    }

    return unreads;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 100,
    },
  }
);

/**
 * @param {String} chatId 
 * @returns {Object} chat unread details.
 */
export const _chatUnred = (state, chatId) => {
  return chatId && chatId.startsWith('ccm_')
    ? firestoreRedux.selectors.doc(
        state,
        'card-chat-unread-user-details',
        `ccuu_${getIdWoPrefix({ id: chatId, prefix: 'ccm_' })}_${getIdWoPrefix({ id: authSelectors.currentUserId(state), prefix: 'usr_' })}`
      )
    : firestoreRedux.selectors.doc(
        state,
        'board-chat-unread-user-details',
        `bcuu_${getIdWoPrefix({ id: chatId, prefix: 'bcm_' })}_${getIdWoPrefix({ id: authSelectors.currentUserId(state), prefix: 'usr_' })}`
      );
};

/**
 * @param {Object} state Redux state.
 * @param {String} attachmentId chat ID passed to give chat item model.
 * @return { Object } Card chat unread model.
 *                    e.g. {created: true, message: true}
 */
export const cardChatUnread = createSelector(
  (state, chatId) => board.selectors.isBoardActive(state, chatSelectors.chatBoardId(state, chatId)),
  (state, chatId) => _chatUnred(state, chatId),
  (isBoardActive, doc) => {
    if (!isBoardActive) {
      return {};
    }

    if(!doc || !doc.anyUnread) {
      return {};
    }

    const unreads = get(doc, 'unread', {});
    if (unreads.created) {
      return { created: true };
    }
    return unreads;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 100,
    },
  }
);

/**
 * @param {Object} state Redux state.
 * @param {String} attachmentId chat ID passed to give chat item model.
 * @return { Object } Board chat unread model.
 *                    e.g. {created: true, message: true}
 */
export const boardChatUnread = createSelector(
  (state, chatId) => board.selectors.isBoardActive(state, chatSelectors.chatBoardId(state, chatId)),
  (state, chatId) => _chatUnred(state, chatId),
  (isBoardActive, doc) => {
    if (!isBoardActive) {
      return {};
    }

    if(!doc || !doc.anyUnread) {
      return {};
    }

    const unreads = get(doc, 'unread', {});
    if (unreads.created) {
      return { created: true };
    }
    return unreads;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 100,
    },
  }
);