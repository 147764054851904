import URI from '@dw/urijs-esm';

export const loginPageRouter = (data) => {
  return new Promise(function (resolve, reject) {
    if (data.path.startsWith('/login')) {
      const params = new URI().query(true);
      if (!data.user) {
        data.page = {
          name: 'LOGIN_AND_SIGNUP',
          params,
        };
        resolve(data);
        return;
      }

      if (params.redirect) {
        location.href = decodeURIComponent(params.redirect);
        return;
      }
    }
    reject(data);
  });
};
