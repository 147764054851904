import get from 'lodash-es/get';
import * as app from '../app';
import * as user from '../user';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as selectorCreator from '../selector-creator.js';

export const currentUser = selectorCreator.createDeepEqualSelector(
  (state) => get(state, `auth.user`),
  (state) => user.selectors.attrs(state, currentUserId(state)),
  (_user, firestoreUserDetails) => {
    return {..._user, ...firestoreUserDetails}
  }
);

/**
 * @returns {String} current user's domains list from primary email and additional auth emails.
 */
export const currentUserDomains = selectorCreator.reselct.createSelector(
  currentUser,
  (user) => {
    return get(user, 'domains', []).join();
  }
);

/**
 * @returns { String } Full name of current user.
 */
export const currentUserName = selectorCreator.reselct.createSelector(
  currentUser,
  (user) => user ? `${user.firstName || ''} ${user.lastName || ''}` : ''
);

/**
 * Select login user id from auth redux state
 */
export const currentUserId = (state) => get(state, 'auth.user.id');

/**
 * @returns { Boolean } current user is template ac owner or not.
 */
export const currentUserTemplateAcOwner = selectorCreator.default({maxSize: 100})(
  (state) => app.selectors.templateAcId(state),
  (state) => currentUserOwnedAccounts(state),
  (templateAcId, ownedAccounts) => {
    ownedAccounts = ownedAccounts || [];
    return !!templateAcId && ownedAccounts.includes(templateAcId);
  }
);

/**
 * Current user owned accounts from redux state.
 */
export const currentUserOwnedAccounts = selectorCreator.createDeepEqualSelector(
  (state) => get(state, 'auth.ownedAccounts', []),
  (ownedAccounts) => {
    return ownedAccounts;
  }
);

/**
 * Current user owned account id from redux state.
 */
export const currentUserOwnedAccountId = selectorCreator.createDeepEqualSelector(
  currentUserOwnedAccounts,
  (ownedAccounts) => {
    return ownedAccounts && ownedAccounts[0];
  }
);

/**
* @returns {Object} Current user's owned account details.
*/
export const ownedAccountDetails = selectorCreator.createDeepEqualSelector(
  (state) => firestoreRedux.selectors.docsByQueryResult(state, 'owned-accounts', 'accounts'),
  (ownedAccounts) => {
    return ownedAccounts && ownedAccounts[0];
  }
);

/**
 * Current user accessible accounts from redux state.
 */
export const currentUserAccessibleAccount = (state) => get(state, 'auth.accessibleAccounts')

/**
 * @param {Object} state redux state
 * @returns {Object} activeAccessible boards of current user.
 */
export const accessibleBoards = state => get(state, `auth.accessibleBoards`);

/**
 * @param {Object} state redux state
 * @returns {Boolean} login process status.
 */
export const loginStatus = (state) => get(state, `login-and-signup.login.status`);

/**
 * @param {Object} state redux state
 * @returns {String} what was the error code for login failed.
 */
export const loginError = (state) => get(state, `login-and-signup.login.error`);

/**
 * @param {Object} state redux state
 * @returns {Object} login service.
 */
export const loginService = (state) => get(state, `login-and-signup.login.service`);

/**
 * @param {Object} state redux state
 * @returns {Object} signup process status.
 */
export const signupStatus = (state) => get(state, `login-and-signup.signup.status`);

/**
 * @param {Object} state redux state
 * @returns {Object} signup service.
 */
 export const signupService = (state) => get(state, `login-and-signup.signup.service`);

/**
 * @param {Object} state redux state
 * @returns {Object} signup process error.
 */
export const signupError = (state) => get(state, `login-and-signup.signup.error`);

/**
 * @param {Object} state redux state
 * @returns {String} request Id of send verification email.
 */
export const requestId = (state) =>  get(state, `login-and-signup.signup.requestId`);

/**
 * @param {Object} state redux state
 * @returns {Object} re-send signup verification email process status.
 */
export const resendVerificationEmailStatus = (state) => get(state, `login-and-signup.signup.resendVerificationEmail.status`);

/**
 * @param {Object} state redux state
 * @returns {Object} re-send signup verification email process failed to due this error.
 */
export const resendVerificationEmailError = (state) => get(state, `login-and-signup.signup.resendVerificationEmail.error`);

/**
 * @returns join-secret.
 */
export const joinSecret = (state) => get(state, `login-and-signup.joinSecretInfo.joinSecret`);

/**
 * @param {Object} state redux state
 * @returns {Object} get join secret process status.
 */
export const getJoinSecretStatus = (state) => get(state, `login-and-signup.joinSecretInfo.status`);

 /**
  * @param {Object} state redux state
  * @returns {Object} get join secret process error.
  */
export const getJoinSecretError = (state) => get(state, `login-and-signup.joinSecretInfo.error`);

/**
 * @param {Object} state redux state
 * @returns {Object} join secret info.
 */
export const joinSecretInfo = (state) => get(state, `login-and-signup.joinSecretInfo.data`);

/**
 *
 * @param {Object} state redux state
 *  @returns {Object} signup user info.
 */
export const signupUserInfo = (state) => get(state, `login-and-signup.signup.user`);

/**
 * @param { Object } state 
 * @returns { Boolean } user is impersonated user or not.
 */
export const impersonatedUser = (state) => {
  const _user = currentUser(state);
  return _user && _user.impersonated || false;
}