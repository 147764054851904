import get from 'lodash-es/get.js';
import isEmpty from 'lodash-es/isEmpty.js';
import forEach from 'lodash-es/forEach';
import find from 'lodash-es/find';
import isArray from 'lodash-es/isArray';
import values from 'lodash-es/values';
import moment from 'moment/src/moment.js';
import { createSelector } from 'reselect';
import { valueFactory } from '@dw/firebase-redux/selectors.js';
import * as router from '../router/index.js';

/**
 * @param {Object} state Redux state
 * @returns {Object} currently opened account subscription all details.
 */
export const subscription = (state) => get(state, `firebase.subscriptions.${router.selectors.accountId(state)}.subscription`);

/**
 * @param {Object} state Redux state
 * @returns {String} currently opened account subscription plan.
 */
export const plan = (state) => {
  const _subscription = subscription(state);
  return _subscription && _subscription.plan;
};

/**
 * @param {Object} state Redux state
 * @returns {Number} currently opened account free-subscriptions.
 */
export const freeSubscritions = (state) => {
  const _subscription = subscription(state);
  if (isEmpty(_subscription) || isPlanExpire(state)) {
    return 0;
  }

  //If total subscription is less than used subscriptions
  if (_subscription.totalAnnualSubscriptions < _subscription.usedAnnualSubscriptions) {
    return 0;
  }

  return _subscription.totalAnnualSubscriptions - _subscription.usedAnnualSubscriptions;
}

/**
 * @param {Object} state Redux state
 * @returns {Boolean} currently opened account subscription plan is expire or not.
 */
export const isPlanExpire = (state) => {
  const expireDate = planExpireDate(state);
  return !(expireDate > Date.now());
}

/**
 * @param {Object} state Redux state
 * @returns {Boolean} currently opened account subscription plan expire date.
 */
export const planExpireDate = (state) => {
  const _subscription = subscription(state);
  return _subscription && _subscription.expirationDate;
}

/**
 * @param {Object} state Redux state
 * @returns {Boolean} currently opened account total subscriptions.
 */
export const totalSubscriptions = (state) => {
  const _subscription = subscription(state);
  return _subscription && _subscription.totalAnnualSubscriptions;
}

/**
 * @param {Object} state Redux state
 * @returns {Number} currently opened account credit balance.
 */
export const creditBalance = (state) => {
  const _subscription = subscription(state);
  return _subscription && _subscription.creditBalance;
}

/**
 * @param {Object} state Redux state
 * @returns {Object} current account billing transactions.
 */
export const transactions = (state) => get(state, `firebase.subscriptions.${router.selectors.accountId(state)}.transactions`);

/**
 * @param {Object} state Redux state
 * @returns {Object} current account invoices.
 */
export const invoices = (state) => get(state, `firebase.subscriptions.${router.selectors.accountId(state)}.invoices`);

/**
 * @param {Object} state Redux state
 * @returns {Number} currently opened account pending invoices count.
 */
export const pendingInvoicesCount = (state) => get(state, `subscription.pendingInvoiceCount`);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} current user emill is from public domains or not.
 */
export const publicDomain = (state) => get(state, `subscription.publicDomain`, false);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when refun request in-progress or not.
 */
export const refundRequestInProgress = (state) => get(state, `subscription.refundRequest.status`) === 'IN_PROGRESS';

/**
 * @param {Object} state Redux state
 * @returns {Array} Billing history for current accounts.
 */
export const billingHistory = createSelector(
  invoices, transactions,
  (invoices, transactions) => {
    // Check if invoices or transactions are undefined
    if (invoices === undefined || transactions === undefined) {
      return undefined;
    }

    // If there are no transactions, return an empty array
    if (isEmpty(transactions)) {
      return [];
    }

    const list = [];

    forEach(transactions, (transaction) => {
      const type = transaction && transaction.transactionType;

      let invoiceStatus = undefined;
      let isOpenedInvoice = false;

      let item = { ...transaction };

      if (transaction.invoiceId) {
        // Find the corresponding invoice object
        const invoice = find(invoices, (inv) => inv.id === transaction.invoiceId);

        // If an invoice is found
        if (invoice) {
          // Get the invoice status
          invoiceStatus = invoice.status;

          // Merge the transaction and invoice data into the item object
          item = { ...transaction, ...{ invoice } };
        }
      }

      // Determine if the invoice is an opened invoice (pending purchase or renewal)
      isOpenedInvoice = ((type === 'PURCHASE' || type === 'RENEW') && invoiceStatus === 'PENDING') || false;

      // Add the item object to the list, including the isOpenedInvoice flag
      list.push({ ...item, isOpenedInvoice });
    });

    // Sort the list by createdAt in descending order
    list.sort((a, b) => b.createdAt - a.createdAt);

    return list;
  }
);

/**
 * @param {Object} state Redux state
 * @param {String} invoiceId
 * @returns {Boolean} `true` when given invoice resend process is in progress or not.
 */
export const resendInvoiceInProgress = (state, invoiceId) => get(state, `subscription.invoices.${invoiceId}.resend.status`) === 'IN_PROGRESS';

/**
 * @param {Object} state Redux state
 * @param {String} invoiceId
 * @returns {Boolean} `true` when given invoice pay online process is in progress or not.
 */
export const payOnlineInvoiceInProgress = (state, invoiceId) => get(state, `subscription.invoices.${invoiceId}.payOnline.status`) === 'IN_PROGRESS';

/**
 * @param {Object} state Redux state
 * @returns {Object} billing address for current account.
 */
export const billingAddress = (state) => {
  const billingAddress = valueFactory(`subscriptions.${router.selectors.accountId(state)}.billing-address`)(state);
  if(!billingAddress || !billingAddress.billingEmails || isArray(billingAddress.billingEmails)) {
    return billingAddress;
  }

  return {...billingAddress, billingEmails: values(billingAddress.billingEmails)}
}

/**
 * @param {Object} state Redux state
 * @returns {String} change plan status.
 */
export const changePlanStatus = (state) => get(state, `subscription.changePlan.status`);

/**
 *
 * @param {Object} state Redux state
 * @returns {String} change plan error.
 */
export const changePlanError = (state) => get(state, `subscription.changePlan.error`);

/**
 *
 * @param {Object} state Redux state
 * @returns {String} error message.
 */
export const errorMessage = (state) => {
  const _plan = plan(state);
  if(_plan === 'PROFESSIONAL') {
    return get(state, `subscription.changeSubsciptions.message`);
  }
  return get(state, `subscription.changePlan.message`);
};

/**
 * @param {Object} state Redux state
 * @returns {String} offline invoice payment due-date.
 */
export const offlineInvoicePaymentDuedate = (state) => {
  return get(state, `subscription.changeSubsciptions.offlineInvoicePaymentDuedate`) || get(state, `subscription.changePlan.offlineInvoicePaymentDuedate`);
};

/**
 * @param {Object} state Redux state
 * @returns {String} request non profit plan status.
 */
export const requestNonProfitStatus = (state) => get(state, `subscription.requestNonprofitePlan.status`);

/**
 * @param {Object} state Redux state
 * @returns {String} request non profit plan error.
 */
 export const requestNonProfitError = (state) => get(state, `subscription.requestNonprofitePlan.error`);

 /**
  * @param {Object} state Redux state
  * @returns {Object} Charges for subscriotions.
  */
 export const charges = (state) => get(state, `subscription.computeCharges`);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when refun request in-progress or not.
 */
export const changeBillingInfoStatus = (state) => get(state, `subscription.changeBillingAddress.status`);

/**
 * @returns manage team pending action data.
 */
export const pendingActionsData = (state) => get(state, `subscription.mangeTeamPendingAction`);

/**
 * @param {Object} state Redux state
 * @returns {String} change subcriptions is failed error.
 */
export const changeSubsciptionsError = (state) => get(state, `subscription.changeSubsciptions.error`);

/**
 * @param {Object} state Redux state
 * @returns {String} change subcriptions status.
 */
export const changeSubsciptionsStatus = (state) => get(state, `subscription.changeSubsciptions.status`);

/**
 * @returns {Boolean} given message is known or not.
 */
export const messageKnown = (state, name) => get(state, `subscription.messages.${router.selectors.accountId(state)}.${name}.known`, false) || false;

/**
 * @returns {Number} number of remaning days for expire plan.
 */
export const planExpireDays = (state) => {
  const expiry = planExpireDate(state);
  return moment(expiry).diff(moment(), 'days');
}

/**
 * @returns {String} message name. Possible values: `TRIAL_NEAR_EXPIRE`, `PROFESSIONAL_NEAR_EXPIRE`, `TRIAL_EXPIRED`, and `PROFESSIONAL_EXPIRED`.
 */
export const messageName = (state) => {
  const _plan = plan(state);
  if(!_plan || (_plan !== 'TRIAL' && _plan !== 'PROFESSIONAL')) {
    return '';
  }

  if(isPlanExpire(state)) {
    return _plan === 'TRIAL' ? 'TRIAL_EXPIRED': 'PROFESSIONAL_EXPIRED';
  }

  const diff = planExpireDays(state);
  if(diff > 5) {
    return '';
  }

  return _plan === 'TRIAL' ? 'TRIAL_NEAR_EXPIRE': 'PROFESSIONAL_NEAR_EXPIRE';
}

/**
 * @returns {Boolean} `truen` when subscription message is shown.
 */
export const showsMessage = (state) => {
  const _message = messageName(state);
  const _known = messageKnown(state, _message);
  const _accountId = router.selectors.accountId(state);
  return _accountId && _message && !_known;
}