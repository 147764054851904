import { takeEvery, call, all, select, put, fork, take } from 'redux-saga/effects';
import { store } from '../../store';
import { requestApi, isNetworkError }  from '../../request-api.js';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import * as actions from './actions';
import * as selectors from './selectors';

import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import firestoreRedux from '@dreamworld/firestore-redux';

import get from 'lodash-es/get';
import merge from 'lodash-es/merge';
import isEqual from 'lodash-es/isEqual';
import moment from 'moment/src/moment';

function* loadData() {
  yield all([
    fork(loadDeviceDetails),
    fork(autoRefreshFcmToken),
    takeEvery(auth.actions.UPDATE_AUTH, autoRefreshFcmToken),
    takeEvery(auth.actions.UPDATE_AUTH, loadDeviceDetails)
  ]);
}

function* loadDeviceDetails(action) {
  const state = yield select();
  const deviceId = selectors.getId(state);
  if(deviceId) {
    try {
      const deviceUIQuery = firestoreRedux.getDocById(`device-ui`, `du_${deviceId}`, { requesterId: `devices` });
      yield deviceUIQuery.result;
    } catch (error) {
      console.error(error);
    }
  }
  
  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return;
  }
  
  try {
    const deviceId = selectors.getId(state);
    firestoreRedux.getDocById(`users/${userId}/devices`, `ud_${deviceId}`, { requesterId: `devices` });
  } catch (error) {
    console.error("device > loadDeviceDetails: failed due to this: ", error);
  }
}

let unsubscribeAutoRefreshFcmTokenRequest;
function* autoRefreshFcmToken() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return;
  }

  const deviceId = selectors.getId(state);
  const config = app.selectors.config(state)
  const fcmTokenRefreshTime = get(config, 'fcmTokenRefreshTime') || 30;
  unsubscribeAutoRefreshFcmTokenRequest && unsubscribeAutoRefreshFcmTokenRequest();
  unsubscribeAutoRefreshFcmTokenRequest = ReduxUtils.subscribe(store, `firestore.docs.devices.${`ud_${deviceId}`}.fcm.refreshedAt`, (refreshedAt) => {
    if(refreshedAt && moment().isSame(moment(refreshedAt).add(fcmTokenRefreshTime, 'd'), 'day')) {
      store.dispatch(actions.autoRefreshFcmToken());
    }
  });
}

function* onAutoTokenRefresh({token}) {
  if(!token) {
    try {
      token = yield window.cordova.plugins.firebase.messaging.getToken();
    } catch (error) {}
  }

  if(token) {
    yield call(onUpdateFcmToken, token);
  }
}

function* onUpdateFcmToken({token}) {
  if(!token) {
    console.error("device > onUpdateFcmToken: failed due to this: token not found");
    return;
  }

  try {
    yield call(requestApi, `/user/users/me/refresh-fcm-token`, {method: 'POST', body: {token}});
    yield put(actions.updateFcmTokenDone(token));
    window && window.localStorage && window.localStorage.setItem && window.localStorage.setItem('device-token', token);
  } catch (error) {
    yield put(actions.updateFcmTokenFailed(token, error && error.code));
    if(isNetworkError(error)) {
      return;
    }
    console.error("device > onUpdateFcmToken: failed due to this: ", error);
  }
}

function* onDeniedToken() {
  try {
    yield call(requestApi, `/user/users/me/denied-fcm-token`, {method: 'POST'});
    yield put(actions.deniedFcmTokenDone());
  } catch (error) {
    yield put(actions.deniedFcmTokenFailed(error && error.code));
    if(isNetworkError(error)) {
      return;
    }
    console.error("device > onDeniedToken: failed due to this: ", error);
  }
}

function* routeChangeHandler() {
  const state = yield select();
  const pageParams = router.selectors.pageParams(state);
  const utmCampaign = pageParams && pageParams['utm_campaign'] || '';
  const utmMedium = pageParams && pageParams['utm_medium'] || '';
  const utmSource = pageParams && pageParams['utm_source'] || '';
  if(utmCampaign || utmMedium || utmSource) {
    yield call(saveDeviceUtmDetails, utmCampaign, utmMedium, utmSource);
  }
}

function* saveDeviceUtmDetails(utmCampaign, utmMedium, utmSource) {
  const state = yield select();
  const deviceId = selectors.getId(state);
  try {
    //Remove query params
    router.actions.setQueryParams({'utm_campaign': undefined, 'utm_medium': undefined, 'utm_source': undefined}, false);
    const deviceReq = firestoreRedux.getDocById(`device-ui`, `du_${deviceId}`);
    const cuurentDoc = yield deviceReq.result || {};
    const newDoc = { id: `du_${deviceId}`, utmDetails: {}, deviceId};
    if(utmCampaign) {
      newDoc.utmDetails['campaign'] = utmCampaign;
    }
    if(utmMedium) {
      newDoc.utmDetails['medium'] = utmMedium;
    }
    if(utmSource) {
      newDoc.utmDetails['source'] = utmSource;
    }
    
    const doc = merge({}, cuurentDoc, newDoc);
    if(isEqual(cuurentDoc, doc)) {
      return;
    }
    firestoreRedux.save('device-ui', doc, { remoteWrite: true, localWrite: true});
  } catch (error) {
    console.error("device > saga > saveDeviceUtmDetails: failed due to this: ", error);
  }
}

/**
 * Watches router change.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

function* init() {
  yield fork(loadData),
  yield fork(watchRouter)
}

/**
 * Init Saga.
 */
function* deviceSaga() {
  yield all([
    takeEvery(actions.INIT, init),
    takeEvery(actions.UPDATE_FCM_TOKEN, onUpdateFcmToken),
    takeEvery(actions.DENIED_FCM_TOKEN, onDeniedToken),
    takeEvery(actions.AUTO_REFRESH_FCM_TOKEN, onAutoTokenRefresh)
  ]);
}

export default deviceSaga;