import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import * as app from '../app';
import * as auth from '../auth';
import * as device from '../device';
import * as routerSelectors from '../router/selectors.js';
import * as importBoardSelectors from '../import-board/selectors.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as selectorCreator from '../selector-creator.js';

import { getUserName } from '../../components/utils';
import { getIdWoPrefix } from '../../utils';

/**
 * @returns {Boolean} `true` when join accounts suggestion list loaded or failed.
 */
export const suggestedListLoaded = (state) => get(state, `signup.suggestedListLoaded`);

/**
 * Prepare suggested join account list e.g [{accountId, owner}]
 */
export const suggestedAccountsList = selectorCreator.createDeepEqualSelector(
  (state) => get(state, `signup.suggestedList`),
  (state) => firestoreRedux.selectors.collection(state, 'users'),
  (suggestedList, allUsers) => {
    let list = [];
    forEach(suggestedList, (acc) => {
      const attrs = get(allUsers, acc.ownerId);
      const name = attrs ? getUserName(attrs) : '';
      const email = get(attrs, 'email', '');
      list.push({
        accountId: acc.id,
        accountName: acc.name,
        userId: acc.ownerId,
        name,
        email,
      });
    });
    return list;
  }
);

/**
 * @return { Boolean } `true` when create account setup request is in progress.
 */
export const createAccountInProgress = (state) => get(state, `signup.createAccountInProgress`);

/**
 * @param {Object} state Redux state
 * @returns { Boolean } `true` when join account request is in progress.
 */
export const joinAccountInProgress = (state) => get(state, `signup.joinAccountInProgress`);

/**
 * @returns {Boolean} `true` when user setup (signup) is completed. (When she has accessible accounts or accessible boards.)
 */
export const signupCompleted = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserAccessibleAccount,
  auth.selectors.currentUserOwnedAccounts,
  (accessibleAccounts, ownedAccounts) => {
    return !isEmpty(accessibleAccounts) || !isEmpty(ownedAccounts);
  }
);

/**
 * @returns {String} Join secret value from query parameter.
 */
export const joinSecret = selectorCreator.reselct.createSelector(
  routerSelectors.page,
  (page) => page.params.join || ''
);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when current user complete sign up in the same session, otherwise false.
 */
export const signupInSameSession = (state) => get(state, 'signup.signupInSameSession');

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when current user complete sign up using joint-secret, otherwise false.
 */
export const signupUsingJoinSecret = (state) => get(state, 'signup.signupUsingJoinSecret');

/**
 * @param {Object} state redux state
 * @returns {Object} signup details.
 */
export const signupDetails = selectorCreator.createDeepEqualSelector(
  (state) => firestoreRedux.selectors.doc(state, `user-ui`, `sud_${getIdWoPrefix({id: auth.selectors.currentUserId(state), prefix: 'usr_'})}`),
  (doc) => {
    return doc || {};
  }
);

/**
 * @param {Object} state redux state
 * @returns {Object} utm details.
 */
export const utmDetails = selectorCreator.createDeepEqualSelector(
  (state) => firestoreRedux.selectors.doc(state, `user-ui`, `uuud_${getIdWoPrefix({id: auth.selectors.currentUserId(state), prefix: 'usr_'})}`),
  (doc) => {
    return doc || {};
  }
);

/**
 * @param {Object} state redux state
 * @returns {Object} signup source.
 */
export const signupSource = selectorCreator.reselct.createSelector(
  signupDetails,
  (_signupDetails) => {
    return get(_signupDetails, 'source');
  }
);

/**
 * @param {Object} state redux state
 * @returns {String} signup boardId.
 */
export const signupBoardId = selectorCreator.reselct.createSelector(
  signupDetails,
  (_signupDetails) => {
    return get(_signupDetails, 'boardId');
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} current user is new user or not.
 *
 * Q: Who is the new user?
 * - Since this "new user guide" feature will be deployed and after that the user will signup.
 *
 * Q: How will you decide that this feature has been deployed?
 * - This feature stores, a Signup details for that user in `user-ui` as a `singupDetails`.
 */
export const isNewUser = selectorCreator.reselct.createSelector(
  importBoardSelectors.isTrelloUser,
  signupSource,
  (isTrelloUser, signupSource) => {
    if(isTrelloUser) {
      return false;
    }
    return signupSource && (signupSource === 'USE_TEMPLATE' || signupSource === 'BOARD_INVITATION' || signupSource === 'ACCOUNT_INVITATION' || signupSource === 'JOIN_ACCOUNT' || signupSource === 'FRESH_USER') || false;
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} current user is fresh user or not.
 */
export const isFreshUser = selectorCreator.reselct.createSelector(
  importBoardSelectors.isTrelloUser,
  signupSource,
  (isTrelloUser, signupSource) => {
    if(isTrelloUser) {
      return false;
    }
    return signupSource && signupSource === 'FRESH_USER';
  }
);

/**
 * @returns {Boolean} `true` when Fresh user wizard presented.
 */
export const freshUserWizardCompleted = selectorCreator.reselct.createSelector(
  isFreshUser,
  (state) => firestoreRedux.selectors.doc(state, 'user-ui', `fuw_${getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`),
  (isFreshUser, doc) => {
    return isFreshUser && get(doc, 'completed', false);
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} join account welcome dialog is open or not.
 */
export const joinAccountWelcomeDialogOpened = selectorCreator.reselct.createSelector(
  auth.selectors.impersonatedUser,
  isNewUser,
  routerSelectors.pageName,
  signupSource,
  (state) => app.selectors.isVideoAvailable(state, 'join-account'),
  (state) => firestoreRedux.selectors.doc(state, `user-ui`, `jawd_${getIdWoPrefix({id: auth.selectors.currentUserId(state), prefix: 'usr_'})}`),
  (impersonatedUser, isNewUser, pageName, signupSource, videoAvailable, welcomeDialogDetails) => {
    if(impersonatedUser) {
      return false;
    }
    
    if(!videoAvailable || !isNewUser) {
      return false;
    }

    if(welcomeDialogDetails && welcomeDialogDetails.presented) {
      return false;
    }

    return signupSource && pageName === 'BOARD_EXPLORER' && (signupSource === 'ACCOUNT_INVITATION' || signupSource === 'JOIN_ACCOUNT');
  }
);

/**
 * @returns {String} user latest utm-campaign.
 */
export const utmCampaign = selectorCreator.reselct.createSelector(
   (state) => utmDetails(state),
   (state) => firestoreRedux.selectors.doc(state, `device-ui`, `du_${device.selectors.getId(state)}`),
   (userUtmDetails, deviceUIUtmDetails) => {
    return get(userUtmDetails, 'utmDetails.campaign') || get(deviceUIUtmDetails, 'utmDetails.campaign') || '';
   }
);

/**
 * @returns {Boolean} login user competitors campaigns or not.
 */
export const isCompetitorsCampaign = (state) => {
  const campaign = utmCampaign(state);
  return campaign === 'trello' || campaign === 'asana' || campaign === 'jira' || campaign === 'clickup' || campaign === 'misc-competitors' || false;
}