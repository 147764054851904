export const CHANGE_MODE = 'FRESH_USER_WIZARD_CHANGE_MODE';

export const ADD_NEW_CARD = 'FRESH_USER_WIZARD_ADD_NEW_CARD';
export const ADD_NEW_CARD_WITH_ORDER = 'FRESH_USER_WIZARD_ADD_NEW_CARD_WITH_ORDER';
export const REMOVE_CARD = 'FRESH_USER_WIZARD_REMOVE_CARD';
export const UPDATE_CARD_TITLE = 'FRESH_USER_WIZARD_UPDATE_CARD_TITLE';

export const CREATE_BOARD = 'FRESH_USER_WIZARD_CREATE_BOARD';
export const CREATE_BOARD_SUCCESS = 'FRESH_USER_WIZARD_CREATE_BOARD_SUCEESS';
export const CREATE_BOARD_FAILED = 'FRESH_USER_WIZARD_CREATE_BOARD_FAILED';

/**
 * change mode of fresh user wizard
 */
export const changeMode = (mode, boardName) => {
  return { 
    type: CHANGE_MODE, 
    mode, 
    boardName 
  };
};

export const addNewCard = (title) => {
  return { 
    type: ADD_NEW_CARD, 
    title
  };
};

export const _addNewCardWithOrder = ({id, title, columnOrder}) => {
  return { 
    type: ADD_NEW_CARD_WITH_ORDER, 
    id,
    title,
    columnOrder
  };
};

export const removeCard = (id) => {
  return { 
    type: REMOVE_CARD, 
    id
  };
};

export const updateCardTitle = (id, title) => {
  return { 
    type: UPDATE_CARD_TITLE, 
    id,
    title
  };
};

export const createBoard = () => {
  return { 
    type: CREATE_BOARD
  };
};

export const _createBoardSuccess = () => {
  return { 
    type: CREATE_BOARD_SUCCESS
  };
};

export const _createBoardFailed = (error) => {
  return { 
    type: CREATE_BOARD_FAILED,
    error
  };
};