import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import find from 'lodash-es/find';
import { config } from './config.js';
import * as selectorCreator from '../selector-creator.js';

import * as app from '../app';
import * as auth from '../auth';
import * as board from '../board';
import * as router from '../router';
import * as signup from '../signup';
import * as canvasSelectors from '../canvas/selectors.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';

/**
 * @returns {Boolean} `true` when known-featuers are loaded otherwise `false`
 */
export const isLoaded = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  (state) => firestoreRedux.selectors.queryStatus(state, `known-features`),
  (userId, queryStatus) => {
    if (!userId) {
      return true;
    }
    return queryStatus === 'LIVE';
  }
);

/**
 * @returns {Object} single known-feature document.
 */
export const knownFeature = ({state, name}) => _knownFeature(state, name);
const _knownFeature = selectorCreator.default({maxSize: 50})(
  (state, name) => name,
  auth.selectors.currentUserId,
  (state) => firestoreRedux.selectors.collection(state, 'known-features'),
  (name, userId, knownFeatures) => {
    if(userId) {
      return find(knownFeatures, { name });
    } else {
      return {};
    }
  }
);

/**
 * @param {*}
 *  @property {Object} state state
 *  @property {String} name Feature name
 * @returns `true` when requested feature is known to the user.
 */
export const isKnown = ({state, name}) => {
  const _dataLoaded = isLoaded(state);
  const userId = auth.selectors.currentUserId(state);

  //Returns undefined, if firestore data isn't yet loaded
  if (!_dataLoaded && userId) {
    return true;
  }

  if(userId) {
    const _knownFeature = knownFeature({ state, name });
    if (!_knownFeature || config[name].skipMarkAsRead) {
      return false;
    }
    return _knownFeature.known;
  }

  const _localStorageValue = window.localStorage.getItem(`knownFeatures.${name}`);
  return _localStorageValue === 'true' || _localStorageValue === true;
};

/**
 * Board page tip is shown or not.
 */
const isBoardPageTipOpen = selectorCreator.reselct.createSelector(
  router.selectors.actionName,
  router.selectors.dialogName,
  router.selectors.pageName,
  board.selectors.loaded,
  board.selectors.useTemplateWelcomeDialogOpened,
  board.selectors.needToLoadWelcomeDetail,
  board.selectors.welcomeDialogPresented,
  signup.selectors.joinAccountWelcomeDialogOpened,
  board.selectors.freshUserWizardCompletedInThisSession,
  (action, dialogName, pageName, isBoardLoaded, useTemplateWelcomeDialogOpened, needToLoadWelcomeDetail, welcomeDialogPresented, joinAccountWelcomeDialogOpened, freshUserWizardCompletedInThisSession) => {
    return pageName === 'BOARD' && !freshUserWizardCompletedInThisSession && isBoardLoaded && !action && !dialogName && !joinAccountWelcomeDialogOpened && !useTemplateWelcomeDialogOpened && (!needToLoadWelcomeDetail || welcomeDialogPresented);
  }
);

/**
 * @returns {Boolean} `true` tip is already opened once for the user.
 */
export const onceOpened = (state, feature) => get(state, `known-features.${feature}.openedOnce`, false);

/**
 * @returns {Boolean} Add new card tip is shown or not.
 */
export const addNewCardTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  isBoardPageTipOpen,
  (state) => board.selectors.cardCounts({state}),
  (state) => onceOpened(state, 'ADD_NEW_CARD'),
  (currentUserId, isNewUser, isBoardPageTipOpen, cardCounts, onceOpened) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(!isNewUser || !isBoardPageTipOpen) {
      return false;
    }

    return cardCounts == 0;
  }
);

/**
 * @returns {Boolean} Open card tip is shown or not.
 */
export const openCardTipOpen = selectorCreator.reselct.createSelector(
  signup.selectors.isNewUser,
  signup.selectors.isFreshUser,
  board.selectors.freshUserWizardStage,
  isBoardPageTipOpen,
  (state) => board.selectors.cardCounts({state}),
  (state) => onceOpened(state, 'OPEN_CARD_1'),
  (state) => onceOpened(state, 'OPEN_CARD_2'),
  (state) => isKnown({state, name: 'LOOK_AROUND_OPEN_CARD'}),
  (isNewUser, isFreshUser, freshUserWizardStage, isBoardPageTipOpen, cardCounts, onceOpened1, onceOpened2, isKnown) => {
    if (onceOpened1 || onceOpened2 || isKnown) {
      return false;
    }

    if(isFreshUser && (freshUserWizardStage && freshUserWizardStage !== 'COMPLETED')) {
      return false;
    }

    if(!isNewUser || !isBoardPageTipOpen) {
      return false;
    }

    return cardCounts > 0;
  }
);

/**
 * @returns {Boolean} share board tip is open or not.
 */
export const shareBoardTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  isBoardPageTipOpen,
  (state) => onceOpened(state, 'SHARE_BOARD'),
  (state) => board.selectors.boardEffectivePermission(state, router.selectors.pageBoardId(state)),
  (state) => board.selectors.boardTeam(state, router.selectors.pageBoardId(state)),
  (currentUserId, isNewUser, isBoardPageTipOpen, onceOpened, effectieBoardPermission, members) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(!isNewUser || !isBoardPageTipOpen) {
      return false;
    }

    if(!effectieBoardPermission || !effectieBoardPermission.hasAdmin()) {
      return false;
    }

    return !members || members.length < 2;
  }
);

/**
 * Open invite trello team tip or not.
 */
export const inviteTrelloTeamTipOpened = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  isBoardPageTipOpen,
  (state) => onceOpened(state, 'INVITED_TRELLO_TEAM_DESKTOP'),
  (state) => onceOpened(state, 'INVITED_TRELLO_TEAM_MOBILE'),
  (state) => onceOpened(state, 'INVITED_TRELLO_TEAM_DESKTOP_TRIAL'),
  (state) => onceOpened(state, 'INVITED_TRELLO_TEAM_MOBILE_TRIAL'),
  (state) => isKnown({state, name: 'INVITED_TRELLO_TEAM_DESKTOP'}),
  (state) => isKnown({state, name: 'INVITED_TRELLO_TEAM_MOBILE'}),
  (state) => isKnown({state, name: 'INVITED_TRELLO_TEAM_DESKTOP_TRIAL'}),
  (state) => isKnown({state, name: 'INVITED_TRELLO_TEAM_MOBILE_TRIAL'}),
  (state) => board.selectors.boardEffectivePermission(state, router.selectors.pageBoardId(state)),
  (state) => board.selectors.uninvitedMembers({state, boardId: router.selectors.pageBoardId(state)}),
  (currentUserId, isBoardPageTipOpen, onceOpened1, onceOpened2, onceOpened3, onceOpened4, isKnown1, isKnown2, isKnown3, isKnown4, effectieBoardPermission, uninvitedMembers) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened1 || onceOpened2 || onceOpened3 || onceOpened4) {
      return false;
    }

    if(isKnown1 || isKnown2 || isKnown3 || isKnown4) {
      return false;
    }

    if(!isBoardPageTipOpen) {
      return false;
    }

    if(!effectieBoardPermission || !effectieBoardPermission.hasAdmin()) {
      return false;
    }

    return uninvitedMembers && uninvitedMembers.length > 0;
  }
);

/**
 * @param {Object} state
 * @returns {Boolean} `true` when board hihglights tip is shown.
 */
export const assignedToMeHighlightsTipOpened = selectorCreator.reselct.createSelector(
  app.selectors.wideLayout,
  signup.selectors.isNewUser,
  signup.selectors.isFreshUser,
  router.selectors.pageType,
  isBoardPageTipOpen,
  (state) => onceOpened(state, 'ASSIGNED_TO_ME_HIGHLIGHTS'),
  (state) => get(state, `board.cardSummary.${router.selectors.pageBoardId(state)}.assignedToMe`, 0),
  (wideLayout, isNewUser, isFreshUser, pageType, isBoardPageTipOpen, onceOpened, assignedToMe) => {
    //assigned to me highlights tip is not shown for mobile.
    if(!wideLayout) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if (pageType !== 'TASKBOARD') {
      return false;
    }

    if (isFreshUser) {
      return false;
    }

    if(!isNewUser || !isBoardPageTipOpen) {
      return false;
    }

    return !!assignedToMe;
  }
);

/**
 * @param {Object} state
 * @returns {Boolean} `true` when whiteboard tip is shown.
 */
export const whiteboardTipOpened = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  router.selectors.pageType,
  isBoardPageTipOpen,
  board.selectors.needToShowWhiteboardParticipantVideo,
  board.selectors.needToShowWhiteboardNonParticipantVideo,
  (state) => onceOpened(state, 'WHITEBOARD'),
  (currentUserId, isNewUser, pageType, isBoardPageTipOpen, needToShowWhiteboardParticipantVideo, needToShowWhiteboardNonParticipantVideo, onceOpened) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(pageType !== 'WHITEBOARD') {
      return false;
    }

    if(!isNewUser || !isBoardPageTipOpen) {
      return false;
    }

    return needToShowWhiteboardParticipantVideo || needToShowWhiteboardNonParticipantVideo;
  }
);

/**
 * @param {Object} state
 * @returns {Boolean} `true` when taskboard tip is shown.
 */
export const taskboardTipOpened = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  signup.selectors.isFreshUser,
  router.selectors.pageType,
  isBoardPageTipOpen,
  board.selectors.needToShowTaskboardParticipantVideo,
  board.selectors.needToShowTaskboardNonParticipantVideo,
  (state) => onceOpened(state, 'TASKBOARD'),
  (currentUserId, isNewUser, isFreshUser, pageType, isBoardPageTipOpen, needToShowTaskboardParticipantVideo, needToShowTaskboardNonParticipantVideo, onceOpened) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(isFreshUser) {
      return false;
    }

    if(pageType !== 'TASKBOARD') {
      return false;
    }

    if(!isNewUser || !isBoardPageTipOpen) {
      return false;
    }

    return needToShowTaskboardParticipantVideo || needToShowTaskboardNonParticipantVideo;
  }
);

/**
 * @returns {Boolean} Add new task tip is shown or not.
 */
export const addNewTaskTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  router.selectors.actionName,
  router.selectors.dialogName,
  signup.selectors.joinAccountWelcomeDialogOpened,
  (state) => onceOpened(state, 'ADD_NEW_TASK'),
  (currentUserId, isNewUser, action, dialogName, joinAccountWelcomeDialogOpened, onceOpened) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    return dialogName === 'CARD' && isNewUser && !action && !joinAccountWelcomeDialogOpened;
  }
);

/**
 * @returns {Boolean} Add new attachment tip is shown or not.
 */
export const addNewAttachmentTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  router.selectors.actionName,
  router.selectors.dialogName,
  signup.selectors.joinAccountWelcomeDialogOpened,
  (state) => get(state, 'router.dialog.params.attachment-action') || get(state, 'router.page.params.attachment-action'),
  (state) => onceOpened(state, 'ADD_NEW_ATTACHMENT'),
  (currentUserId, isNewUser, action, dialogName, joinAccountWelcomeDialogOpened, attachmentAction, onceOpened) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    return dialogName === 'CARD' && isNewUser && !action && !attachmentAction && !joinAccountWelcomeDialogOpened;
  }
);

/**
 * @returns {Boolean} Add card details tip is shown or not.
 */
export const addCardDetailsTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  router.selectors.dialogName,
  signup.selectors.joinAccountWelcomeDialogOpened,
  (state) => onceOpened(state, 'ADD_CARD_DETAILS'),
  (currentUserId, isNewUser, dialogName, joinAccountWelcomeDialogOpened, onceOpened) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    return dialogName === 'CARD' && isNewUser && !joinAccountWelcomeDialogOpened;
  }
);

/**
 * @returns {Boolean} boards explorer tip is shown or not.
 */
export const boardExplorerTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  router.selectors.pageName,
  router.selectors.dialogName,
  router.selectors.actionName,
  signup.selectors.joinAccountWelcomeDialogOpened,
  (state) => app.selectors.isVideoAvailable(state, 'board-explorer'),
  (state) => onceOpened(state, 'BOARDS_EXPLORER'),
  (state) => isKnown({state, name: 'BOARDS_EXPLORER'}),
  (currentUserId, isNewUser, pageName, dialogName, action, joinAccountWelcomeDialogOpened, isVideoAvailable, onceOpened, isKnown) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened || isKnown) {
      return false;
    }

    if(!isVideoAvailable) {
      return false;
    }

    return pageName === 'BOARD_EXPLORER' && isNewUser && !action && !dialogName && !joinAccountWelcomeDialogOpened;
  }
);


/**
 * @returns {Boolean} templates explorer tip is shown or not.
 */
export const templateExplorerTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  router.selectors.pageName,
  router.selectors.dialogName,
  router.selectors.actionName,
  signup.selectors.joinAccountWelcomeDialogOpened,
  (state) => app.selectors.isVideoAvailable(state, 'template-explorer'),
  (state) => onceOpened(state, 'TEMPLATES_EXPLORER'),
  (state) => isKnown({state, name: 'TEMPLATES_EXPLORER'}),
  (currentUserId, isNewUser, pageName, dialogName, action, joinAccountWelcomeDialogOpened, isVideoAvailable, onceOpened, isKnown) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened || isKnown) {
      return false;
    }

    if(!isVideoAvailable) {
      return false;
    }

    return pageName === 'BOARD_EXPLORER' && isNewUser && !action && !dialogName && !joinAccountWelcomeDialogOpened;
  }
);

/**
 * @returns {Boolean} dashboard explorer tip is shown or not.
 */
export const dashboardExplorerTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  router.selectors.pageName,
  router.selectors.dialogName,
  router.selectors.actionName,
  signup.selectors.joinAccountWelcomeDialogOpened,
  (state) => app.selectors.isVideoAvailable(state, 'dashboard-explorer'),
  (state) => onceOpened(state, 'DASHBOARD_EXPLORER'),
  (state) => isKnown({state, name: 'DASHBOARD_EXPLORER'}),
  (currentUserId, isNewUser, pageName, dialogName, action, joinAccountWelcomeDialogOpened, isVideoAvailable, onceOpened, isKnown) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened || isKnown) {
      return false;
    }

    if(!isVideoAvailable) {
      return false;
    }

    return pageName === 'BOARD_EXPLORER' && isNewUser && !action && !dialogName && !joinAccountWelcomeDialogOpened;
  }
);

/**
 * @param {Object} state Redux state.
 * @returns {Boolean} `true` when open card tip to be presented in use-case board.
 */
export const useCaseOpenCardTipOpen = selectorCreator.reselct.createSelector(
  board.selectors.isUseCaseBoard,
  signup.selectors.signupCompleted,
  board.selectors.isUseCaseWelcomeDialogOpen,
  router.selectors.dialogName,
  (isUseCaseBoard, signupComleted, useCaseWelcomeDialogOpen, dialogName) => {
    if(dialogName || useCaseWelcomeDialogOpen || !isUseCaseBoard || signupComleted) {
      return false;
    }
    return true;
  }
);

/**
 * @param {Object} state Redux state.
 * @returns {Boolean} `true` when this tip is not presetend ever, curernt board is use-case board,
 *                      welcome dialog is not opened & any other page tip is not opened at present.
 */
export const isLookAroundHomeTipOpen = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.signupCompleted,
  board.selectors.isUseCaseBoard,
  board.selectors.loaded,
  router.selectors.dialogName,
  board.selectors.isUseCaseWelcomeDialogOpen,
  router.selectors.pageType,
  canvasSelectors.isCavasPageReady,
  canvasSelectors.innerCanvasRect,
  (state) => isKnown({state, name: 'LOOK_AROUND_OPEN_CARD'}),
  (state) => isKnown({state, name: 'INNER_CANVAS'}),
  (currentUserId, signupComleted, isUseCaseBoard, boardLoaded, dialogName, useCaseWelcomeDialogOpen, boardType, canvasPageReady, innerCanvasRect, lookAroundOpenCardTipKnown, innerCanvasTipKnown) => {
    if(!currentUserId) {
      return false;
    }

    if (!boardLoaded || dialogName || signupComleted || !isUseCaseBoard || useCaseWelcomeDialogOpen) {
      return false;
    }

    if (boardType === 'TASKBOARD') {
      return lookAroundOpenCardTipKnown;
    }

    if (boardType === 'WHITEBOARD') {
      if (canvasPageReady && !isEmpty(innerCanvasRect)) {
        return innerCanvasTipKnown;
      }

      return canvasPageReady;
    }

    return false;
  }
);

/**
 * @param {Object} state Redux state.
 * @returns {Boolean} `true` when current page is use-case whiteboard & this tip is not presetend ever to the current user.
 */
export const isInnerCanvasTipOpen = selectorCreator.reselct.createSelector(
  canvasSelectors.isCavasPageReady,
  signup.selectors.signupCompleted,
  board.selectors.isUseCaseBoard,
  router.selectors.dialogName,
  board.selectors.isUseCaseWelcomeDialogOpen,
  canvasSelectors.innerCanvasRect,
  (state) => isKnown({state, name: 'INNER_CANVAS'}),
  (canvasPageReady, signupCompleted, useCaseBoard, dialogName, useCaseWelcomeDialogOpen, innerCanvasRect, innerCanvasTipKnown) => {
    if (isEmpty(innerCanvasRect) || !canvasPageReady || signupCompleted || !useCaseBoard || dialogName || useCaseWelcomeDialogOpen || innerCanvasTipKnown) {
      return false;
    }

    return true;
  }
);

export const boardSetupTipOpened = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  signup.selectors.isFreshUser,
  board.selectors.freshUserWizardStage,
  board.selectors.loaded,
  (state) => onceOpened(state, 'BOARD_SETUP'),
  (currentUserId, isNewUser, isFreshUser, freshUserWizardStage, isBoardLoaded, onceOpened) => {
    if(!currentUserId) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(!isBoardLoaded) {
      return false;
    }

    if(!isFreshUser) {
      return false;
    }

    if(!isNewUser) {
      return false;
    }

    return freshUserWizardStage === 'BOARD_SETUP';
  }
);

export const cardDndTipOpened = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  signup.selectors.isNewUser,
  signup.selectors.isFreshUser,
  board.selectors.freshUserWizardStage,
  board.selectors.loaded,
  (state) => onceOpened(state, 'CARD_DND'),
  (currentUserId, isNewUser, isFreshUser, freshUserWizardStage, isBoardLoaded, onceOpened) => {
    if(!currentUserId) {
      return false;
    }

    if(!isBoardLoaded) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(!isFreshUser) {
      return false;
    }

    if(!isNewUser) {
      return false;
    }

    return freshUserWizardStage === 'CARD_DND';
  }
);

/**
 * Determines whether the cloud permission tip feature is available for the current user.
 * 
 * The cloud permission tip feature is available if the user's signup time is greater than or equal to the
 * `cloudPermissionTipApplyForm` configuration value.
 * 
 * @param {object} state - The Redux state
 * @returns {boolean} `true` if the cloud permission tip feature is available, `false` otherwise
 */
const cloudPermissionTipFeatureAvailable = selectorCreator.reselct.createSelector(
  (state) => app.selectors.config(state).cloudPermissionTipApplyForm,
  (state) => auth.selectors.currentUser(state).signupTime,
  (cloudPermissionTipApplyForm, signupTime) => {
    return cloudPermissionTipApplyForm && signupTime >= cloudPermissionTipApplyForm;
  }
);

/**
 * Determines whether the first attachment created tip has been opened or not.
 * @returns {boolean}
 */
export const firstAttachmentCreatedTipOpened = selectorCreator.reselct.createSelector(
  (state) => auth.selectors.currentUserId(state),
  (state) => cloudPermissionTipFeatureAvailable(state),
  (state) => onceOpened(state, 'FIRST_ATTACHMENT_AO_NO_TEAM_PRIVATE'),
  (state) => onceOpened(state, 'FIRST_ATTACHMENT_AO_NO_TEAM_NON_PRIVATE'),
  (state) => onceOpened(state, 'FIRST_ATTACHMENT_AO_WITH_TEAM'),
  (state) => onceOpened(state, 'FIRST_ATTACHMENT_TM'),
  (state) => isKnown({state, name: 'FIRST_ATTACHMENT_AO_NO_TEAM_PRIVATE'}),
  (state) => isKnown({state, name: 'FIRST_ATTACHMENT_AO_NO_TEAM_NON_PRIVATE'}),
  (state) => isKnown({state, name: 'FIRST_ATTACHMENT_AO_WITH_TEAM'}),
  (state) => isKnown({state, name: 'FIRST_ATTACHMENT_TM'}),
  (state) => board.selectors.boardEffectivePermission(state, router.selectors.pageBoardId(state)),
  (currentUserId, cloudPermissionTipFeatureAvailable, onceOpened1, onceOpened2, onceOpened3, onceOpened4, isKnown1, isKnown2, isKnown3, isKnown4, effectieBoardPermission) => {
    if(!currentUserId) {
      return false;
    }

    if(!cloudPermissionTipFeatureAvailable) {
      return false;
    }

    if(onceOpened1 || onceOpened2 || onceOpened3 || onceOpened4) {
      return false;
    }

    if(isKnown1 || isKnown2 || isKnown3 || isKnown4) {
      return false;
    }

    if(!effectieBoardPermission || !effectieBoardPermission.hasWrite()) {
      return false;
    }

    return true;
  }
);

/**
 * Determines the name of the first attachment created tip to be displayed based on the current board state.
 * 
 * @param {object} state - The Redux state
 * @returns {string} The name of the first attachment created tip to be displayed
 */
export const firstAttachmentCreatedTipName = selectorCreator.reselct.createSelector(
  (state) => firstAttachmentCreatedTipOpened(state),
  (state) => board.selectors.isBoardOwner({state, boardId: router.selectors.pageBoardId(state), userId: auth.selectors.currentUserId(state)}),
  (state) => board.selectors.privacy(state, router.selectors.pageBoardId(state)),
  (state) => board.selectors.hashTeam(state,  router.selectors.pageBoardId(state)), 
  (firstAttachmentCreatedTipOpened, isBoardOwner, privacy, hashTeam) => {
    if(!firstAttachmentCreatedTipOpened) {
      return '';
    }

    if(!isBoardOwner) {
      return 'FIRST_ATTACHMENT_TM';
    }

    if(hashTeam) {
      return 'FIRST_ATTACHMENT_AO_WITH_TEAM';
    }

    if(privacy === 'PRIVATE') {
      return 'FIRST_ATTACHMENT_AO_NO_TEAM_PRIVATE';
    }

    return 'FIRST_ATTACHMENT_AO_NO_TEAM_NON_PRIVATE';
  }
);
/**
 * Determines whether the "New Member Added" tip should be displayed to the current user.
 * @param {object} state - The Redux state
 * @returns {boolean} Whether the "New Member Added" tip should be displayed
 */
export const boardMemberAddedTipOpened = selectorCreator.reselct.createSelector(
  (state) => auth.selectors.currentUserId(state),
  (state) => cloudPermissionTipFeatureAvailable(state),
  (state) => onceOpened(state, 'NEW_MEMBER_ADDED'),
  (state) => isKnown({state, name: 'NEW_MEMBER_ADDED'}),
  (state) => board.selectors.boardEffectivePermission(state, router.selectors.pageBoardId(state)),
  (currentUserId, cloudPermissionTipFeatureAvailable, onceOpened, isKnown, effectieBoardPermission) => {
    if(!currentUserId) {
      return false;
    }

    if(!cloudPermissionTipFeatureAvailable) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(isKnown) {
      return false;
    }

    if(!effectieBoardPermission || !effectieBoardPermission.hasWrite()) {
      return false;
    }

    return true;
  }
);

export const boardMemberRemovedTipOpened = selectorCreator.reselct.createSelector(
  (state) => auth.selectors.currentUserId(state),
  (state) => cloudPermissionTipFeatureAvailable(state),
  (state) => onceOpened(state, 'MEMBER_REMOVED'),
  (state) => isKnown({state, name: 'MEMBER_REMOVED'}),
  (state) => board.selectors.boardEffectivePermission(state, router.selectors.pageBoardId(state)),
  (currentUserId, cloudPermissionTipFeatureAvailable, onceOpened, isKnown, effectieBoardPermission) => {
    if(!currentUserId) {
      return false;
    }

    if(!cloudPermissionTipFeatureAvailable) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(isKnown) {
      return false;
    }

    if(!effectieBoardPermission || !effectieBoardPermission.hasWrite()) {
      return false;
    }

    return true;
  }
);

/**
 * Determines whether the "Member Role Upgraded" tip should be displayed to the current user.
 * @param {object} state - The Redux state
 * @returns {boolean} Whether the "Member Role Upgraded" tip should be displayed
 */
export const boardMemberUpgradedTipOpened = selectorCreator.reselct.createSelector(
  (state) => auth.selectors.currentUserId(state),
  (state) => cloudPermissionTipFeatureAvailable(state),
  (state) => onceOpened(state, 'MEMBER_ROLE_UPGRADE'),
  (state) => isKnown({state, name: 'MEMBER_ROLE_UPGRADE'}),
  (state) => board.selectors.boardEffectivePermission(state, router.selectors.pageBoardId(state)),
  (currentUserId, cloudPermissionTipFeatureAvailable, onceOpened, isKnown, effectieBoardPermission) => {
    if(!currentUserId) {
      return false;
    }

    if(!cloudPermissionTipFeatureAvailable) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(isKnown) {
      return false;
    }

    if(!effectieBoardPermission || !effectieBoardPermission.hasWrite()) {
      return false;
    }

    return true;
  }
);

/**
 * Determines whether the "Member Role Downgraded" tip should be displayed to the current user.
 * @param {object} state - The Redux state
 * @returns {boolean} Whether the "Member Role Downgraded" tip should be displayed
 */
export const boardMemberDowngradedTipOpened = selectorCreator.reselct.createSelector(
  (state) => auth.selectors.currentUserId(state),
  (state) => cloudPermissionTipFeatureAvailable(state),
  (state) => onceOpened(state, 'MEMBER_ROLE_DOWNGRADE'),
  (state) => isKnown({state, name: 'MEMBER_ROLE_DOWNGRADE'}),
  (state) => board.selectors.boardEffectivePermission(state, router.selectors.pageBoardId(state)),
  (currentUserId, cloudPermissionTipFeatureAvailable, onceOpened, isKnown, effectieBoardPermission) => {
    if(!currentUserId) {
      return false;
    }

    if(!cloudPermissionTipFeatureAvailable) {
      return false;
    }

    if(onceOpened) {
      return false;
    }

    if(isKnown) {
      return false;
    }

    if(!effectieBoardPermission || !effectieBoardPermission.hasWrite()) {
      return false;
    }

    return true;
  }
);