import { getIdWoPrefix, getCookieVal } from '../../utils.js';
import forEach from 'lodash-es/forEach.js';
import orderBy from 'lodash-es/orderBy.js';
import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import URI from '@dw/urijs-esm';
import * as selectorCreator from '../selector-creator.js';
import * as authSelectors from '../auth/selectors.js';
import * as userSelectors from '../user/selectors.js';

/**
 * Current language of user.
 */
export const currentLanguage = (state) => {
  const _lang = routerLanguage(state) || preferredLanguage(state) || deviceLanguage(state) || osLanguage(state) || countryLanguage(state) || defaultLanguage(state) || 'en';
  const _langSplit = _lang && _lang.split('-') || [];
  const lang = (_langSplit && _langSplit[0]) || '';
  return isSupportedLanguage(state, lang) ? lang : '';
};

const _routerLanguage = (state) => {
  const params = new URI().query(true);
  return get(state, 'router.page.params.lang') || get(state, 'router.dialog.params.lang') || get(params, 'lang');
}

/**
 * @returns {Boolean} embed login ans signup page is opened or not.
 */
const embedLoginSignup = (state) => {
  let _embedLoginSignup= get(state, 'router.page.params.embed-login-signup');
  if(_embedLoginSignup === 'true' || _embedLoginSignup === true){
    return true;
  }
  const params = new URI().query(true);
  _embedLoginSignup = params && params['embed-login-signup'] || false;
  return _embedLoginSignup === 'true' || _embedLoginSignup === true;
}

/**
 * @returns {Boolean} embed import trello board is opened or not.
 */
const embedTrelloImport = (state) => {
 let _embedTrelloImport= get(state, 'router.page.params.embed-trello-import');
 if(_embedTrelloImport === 'true' || _embedTrelloImport === true){
   return true;
 }
 const params = new URI().query(true);
 _embedTrelloImport = params && params['embed-trello-import'] || false;
 return _embedTrelloImport === 'true' || _embedTrelloImport === true;
}

/**
 * @returns {Boolean} embed use case boards is opened or not.
 */
const embedUseCaseBoards = (state) => {
  let _embedUseCaseBoards= get(state, 'router.page.params.embed-use-case-boards');
  if(_embedUseCaseBoards === 'true' || _embedUseCaseBoards === true){
    return true;
  }
  const params = new URI().query(true);
  _embedUseCaseBoards = params && params['embed-use-case-boards'] || false;
  return _embedUseCaseBoards === 'true' || _embedUseCaseBoards === true;
}

/**
 * @returns {Boolean} embed view is opened or not.
 */
export const embedView = (state) => {
  return embedLoginSignup(state) || embedTrelloImport(state) || embedUseCaseBoards(state);
}

/**
 * @returns {String} routing lang when current view is embedView.
 */
export const routerLanguage = (state) => {
  const _embedView = embedView(state);
  if(!_embedView) {
    return '';
  }

  const lang = _routerLanguage(state);
  return isSupportedLanguage(state, lang) ? lang : '';
}

/**
 * Defualt application language.
 */
export const defaultLanguage = (state) => {
  const __routerLanguage = routerLanguage(state);
  if(__routerLanguage) {
    return __routerLanguage;
  }
  return systemDefaultLanguage(state);
};

export const systemDefaultLanguage = (state) => {
  const systemLanguage = firestoreRedux.selectors.doc(state, `system-language`, `default`);
  const lang = (systemLanguage && systemLanguage.baseLang) || '';
  return isSupportedLanguage(state, lang) ? lang : '';
};

/**
 * Current os language.
 */
export const osLanguage = (state) => {
  const _osLang = navigator.userLanguage || navigator.language || navigator.browserLanguage || '';
  if (!_osLang) {
    return '';
  }

  const osLanguageSplit = _osLang.split('-');
  const lang = (osLanguageSplit && osLanguageSplit[0]) || '';
  const _defaultLang = defaultLanguage(state);
  if(lang === _defaultLang) {
    return '';
  }

  return isSupportedLanguage(state, lang) ? lang : '';
};

/**
 * @param {Object} state 
 * @returns {String} based on user country return a language
 */
export const countryLanguage = (state) => {
  const userId = get(state, 'auth.user.id') || authSelectors.currentUserId(state);
  if(!userId) {
    return '';
  }
  const lastSeenDetails = userSelectors.lastSeenDetails(state, userId);
  if(isEmpty(lastSeenDetails)) {
    return '';
  }

  const countryCode = lastSeenDetails && lastSeenDetails.countryCode || '';
  if(!countryCode) {
    return '';
  }

  const doc = firestoreRedux.selectors.doc(state, `country-wise-lang`, `default`);
  const _countryWiseLang = doc && doc.countryWiseLang || {};
  if(isEmpty(_countryWiseLang)) {
    return '';
  }

  const lang = _countryWiseLang[countryCode];
  return lang && isSupportedLanguage(state, lang) ? lang : '';
}

/**
 * Current device language for user.
 */
export const deviceLanguage = (state) => {
  const langSelectCookie = getCookieVal('lang-select');
  const langSelectStorage = (localStorage && localStorage.getItem && localStorage.getItem('lang-select')) || '';
  if (langSelectCookie != 'true' && langSelectStorage != 'true') {
    return '';
  }

  const lang = getCookieVal('lang') || (localStorage && localStorage.getItem && localStorage.getItem('lang')) || '';
  return lang && isSupportedLanguage(state, lang) ? lang : '';
};

/**
 * Current user preferred language.
 */
export const preferredLanguage = (state) => {
  const __routerLanguage = routerLanguage(state);
  if(__routerLanguage) {
    return __routerLanguage;
  }
  const _userPreferences = userPreferences(state);
  const lang = (_userPreferences && _userPreferences.lang) || '';
  return lang && isSupportedLanguage(state, lang) ? lang : '';
};

/**
 * language dialog is preseneted or not.
 */
export const presentLangSelection = selectorCreator.reselct.createSelector(
  (state) => userPreferences(state),
  (_userPreferences) => {
    return get(_userPreferences, 'presentLangSelection', undefined);
  }
);

export const userPreferences = selectorCreator.createDeepEqualSelector(
  (state) => {
    const userId = get(state, 'auth.user.id') || authSelectors.currentUserId(state);
    const id = `up_${getIdWoPrefix({ id: userId, prefix: 'usr_' })}`;
    return firestoreRedux.selectors.doc(state, `user-preferences`, id) || {};
  },
  (userPreferences) => {
    return userPreferences;
  }
);

/**
 * @returns {Array} supported languages e.g. [{code: 'hi', name: 'Hindi'}, {code: 'en', name: 'English'}]
 */
export const supportedLanguages = selectorCreator.default({maxSize: 10})(
  (state) => get(state, 'auth.user.id') || authSelectors.currentUserId(state),
  (state) => firestoreRedux.selectors.doc(state, `system-language`, `default`),
  (state) => firestoreRedux.selectors.doc(state, 'beta-users', 'bus_0'),
  (userId, systemLanguage, betaUsersData) => {
    if (!systemLanguage || !systemLanguage.langs) {
      return [];
    }

    //Sorting language.
    const systemLangs = orderBy(systemLanguage.langs || [], ['priority', 'code'], ['desc', 'asc']);
    const betaUsers = get(betaUsersData, 'users', []);
    
    const _languages = [];
    forEach(systemLangs, (item) => {
      if (!item || item.availability === 'NONE') {
        return true;
      }
      
      if (item.availability === 'PUBLIC') {
        _languages.push({ name: item.name, code: item.code, englishName: item.englishName });
        return true;
      }

      if(!userId) {
        return true;
      }
      
      const selectedUsers = item.users || [];
      if((item.availability === 'BETA' && betaUsers.includes(userId)) || (item.availability === 'SELECTED_USERS' && selectedUsers.includes(userId))) {
        _languages.push({name: item.name, code: item.code});
      }
    });

    return _languages;
  }
);

/**
 * @returns {Array} supported languages code e.g. ['hi', 'en']
 */
export const supportedLanguagesCode = selectorCreator.default({maxSize: 20})(
  supportedLanguages,
  (_languages) => {
    const _languagesCode = [];
    forEach(_languages, (item) => {
      item && item.code && _languagesCode.push(item.code);
    });
    return _languagesCode;
  }
);

/**
 * @returns {Boolean} `true` when `lang` is supported language.
 */
export const isSupportedLanguage = selectorCreator.reselct.createSelector(
  supportedLanguagesCode,
  (state, lang) => lang,
  (_supportedLanguages, lang) => {
    return (_supportedLanguages && _supportedLanguages.includes && _supportedLanguages.includes(lang)) || false;
  }
);

/**
 * @returns {String} language change status.
 */
export const status = (state) => get(state, 'multiple-language.request.status');

/**
 * @returns {String} given `lang` name.
 */
export const languageName = selectorCreator.reselct.createSelector(
  supportedLanguages,
  (state, lang) => lang || currentLanguage(state),
  (_languages, lang) => {
    let name = '';
    forEach(_languages, (item) => {
      if (item && item.code === lang) {
        name = item.name;
        return false;
      }
    });
    return name;
  }
);

/**
 * @returns {String} given `lang` english name.
 */
export const languageEnglishName = selectorCreator.reselct.createSelector(
  supportedLanguages,
  (state, lang) => lang || currentLanguage(state),
  (_languages, lang) => {
    let name = '';
    forEach(_languages, (item) => {
      if (item && item.code === lang) {
        name = item.englishName;
        return false;
      }
    });
    return name;
  }
);