import URI from '@dw/urijs-esm/index.js';
import { isJoinBoardPage } from './validate-url.js';

/**
 * @param {Object} data - Router data context
 * @return Promise -  If url path is join board page then resolve promise with join board page data.
 *                    Otherwise reject a promise.
 */
export const joinBoardPageRouter = (data) => {
  return new Promise((resolve, reject) => {
    if(isJoinBoardPage(data.path)) {
      const params = new URI().query(true);
      const uri = new URI(data.path);
      const accountId = uri.segment()[0];
      const boardId = uri.segment()[2];
      params.boardId = boardId;
      data.accountId = accountId;
      data.page = {
        name: 'JOIN_BOARD',
        params
      };
      resolve(data);
      return;
    }
    reject(data);
  });
}