import { Router } from './routers/router.js';
import URI from '@dw/urijs-esm';
import buildURL from './build-url';
import findLastIndex from 'lodash-es/findLastIndex';
import forEach  from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import merge from 'lodash-es/merge';
import * as history from '@dreamworld/web-util/history.js';
import * as auth from '../auth';
import * as amplitude from '../../analytics/amplitude.js';
import * as linkedIn from '../../analytics/linkedin.js';
import * as board from '../board';
import * as boardExplorerSelectors from '../board-explorer/selectors.js';
import * as signup from '../signup';
import * as selectors from './selectors.js';
import findIndex from 'lodash-es/findIndex';

export const UPDATE_ROUTER = 'UPDATE_ROUTER';
export const ACCOUNT_CHANGED = 'ACCOUNT_CHANGED';
export const UPDATE_PRIVATE_BOARD = 'UPDATE_PRIVATE_BOARD';
/**
 * Instance of `Router` class
 */
let oRouter;

/**
 * Redux store.
 */
let _store;

/**
 * Initialize application routing
 */
export const init = (store) => {
  oRouter = new Router(store);
  _store = store;
}

/**
 * update private board.
 */
export const updatePrivateBoard = (value) => {
  return {
    type: UPDATE_PRIVATE_BOARD,
    value
  }
}

/**
 * Update browser url (using replace or push state based on parameter) and and trigger router flow if `dontInitRouting` is not true.
 * `navigate (url, replace)`
 *  - `url`: Its mandatory. Update browser url with new.
 *    - `url` is given as an router page object the it's convert to page url.
 *  - `replace`: It is a optional. Boolean value. Pass `true` to replace current url with new in browser history otherwise push into history.
 */
export const navigate = (url, replace, autoBack, autoBackCount) => {
  if(typeof url === 'object') {
    url = buildURL(url);
  }

  if(!url) {
    throw new Error("Invalid arguments passed.");
  }

  let uri = new URI();
  let newUri = new URI(url);

  if(!newUri.protocol()) {
    newUri.protocol(uri.protocol());
  }

  if(!newUri.host()) {
    newUri.host(uri.host());
  }

  if(newUri.toString() == uri.toString()) {
    console.warn('New navigate url is same as current url. So return without doing anything');
    return;
  }

  if(replace) {
    oRouter.replaceState({}, '', url, autoBack, autoBackCount);
  } else {
    oRouter.pushState({}, '', url, autoBack, autoBackCount);
  }
}

/**
 * Refresh route data for the current page.
 * It's mainly used when page's context is changed. e.g. Card is moved to another board etc.
 * When such condition is detected by the UI app, it instructs router to refresh the data, so view can be updated
 * accordingly. e.g. View can read data from the updated path. Until router doesn't update the data, view can't know
 * where the card/page is moved.
 * @public
 */
export const refresh = () => {
  oRouter.refresh();
}

/**
 * Update query parameters of url and update browser url. If `push` is true then add new entry into history otherwise replace current entry
 * @param { Object } queryParams - Map of query parameters.
 *  - If value is `null | undefined` then remove query parameters from url otherwise add as new query parameters inot URL.
 * @param { Boolean } push - When it is `true`, pass new entry into history.
 */
export const setQueryParams = (queryParams, push) => {
  if(!queryParams) {
    return;
  }

  let uri = new URI();
  for(let param in queryParams) {
    if(queryParams[param] !== undefined && queryParams[param] !== null) {
      //Set new query parameter
      uri.setSearch(param, queryParams[param])
    }else {
      //Remove query parameter
      uri.removeSearch(param);
    }
  }

  navigate(uri.toString(), !push);
}

/**
 * Update hash query parameters of url and update browser url. If `push` is true then add new entry into history otherwise replace current entry
 * @param { Object } queryParams - Map of query parameters.
 *  - If value is `null | undefined` then remove query parameters from url otherwise add as new query parameters inot URL.
 * @param { Boolean } push - When it is `true`, pass new entry into history.
 */
export const setHashQueryParams = (queryParams, push) => {
  if(!queryParams) {
    return;
  }

  let uri = new URI();
  let uriHash = new URI(uri.fragment());
  for(let param in queryParams) {
    if(queryParams[param] !== undefined && queryParams[param] !== null) {
      //Set new query parameter
      uriHash.setSearch(param, queryParams[param])
    }else {
      //Remove query parameter
      uriHash.removeSearch(param);
    }
  }
  uri.fragment(uriHash.toString());
  navigate(uri.toString(), !push);
}

/**
 * Add action dialog query parameters into url.
 * When any action dialog is opened then replace new url into history.
 *  - e.g. open board context menu and after choose board setting option then open board setting dialog.
 * Otherwise push new url into history.
 * @param { String } action - Name of action dialog
 * @param { Object } actionParams - Action dialog additional query parameters
 */
export const setActionParam = (action, actionParams) => {
  if(!action) {
    return;
  }

  let addParams = { action };

  //Add additional query parameter in map
  if(actionParams) {
    for(let param in actionParams) {
      addParams[param] = actionParams[param];
    }
  }

  let uri = URI();
  if(!!uri.query(true).action) {
    setQueryParams(addParams);
    return;
  }

  setQueryParams(addParams, true);
}

/**
 * Remove action dialog query parameters from url and replace final URL with current url
 * @param { String } action - Name of action dialog
 * @param { Object } actionParams - Action dialog query paramerters which also removed from url
 */
export const removeActionParam = (action, actionParams) => {
  if(!action) {
    return;
  }

  //Return if url has no action query parameter with given value
  if(!new URI().hasSearch('action', action)) {
    return;
  }

  let removeParams = { action: null };

  //Add additional query parameter in remove params list
  if(actionParams) {
    for(let param in actionParams) {
      removeParams[param] = null;
    }
  }

  setQueryParams(removeParams);
}

/**
 * Router `back` method called.
 * @public
 */
export const back = () => {
  oRouter.back();
}

/**
 * Router `forward` method called.
 * @public
 */
export const forward = () => {
  oRouter.forward();
}

/**
 * Router `go` method called.
 * @param {Number} count you can move forward 2 pages by passing 2 and move backward 2 pages by passing -2.
 * @protected
 */
export const go = (count) => {
  oRouter.go(count);
}

/**
 * @param {Object} state state for this `url`.
 * @param {String} title title of opened page
 * @param {String} url New push url.
 * Router `pushState` method called.
 * @public
 */
export const pushState = (state = {}, title = '', url, autoBack, autoBackCount) => {
  oRouter.pushState(state, title, url, autoBack, autoBackCount);
}

/**
 * @param {Object} state state for this `url`.
 * @param {String} title title of opened page
 * @param {String} url New replace url.
 * Router `replaceState` method called.
 * @public
 */
export const replaceState = (state = {}, title = '', url, autoBack, autoBackCount) => {
  oRouter.replaceState(state, title, url, autoBack, autoBackCount);
}

/**
 *
 * @param { String } sUrl - Internal or Extenal URL
 * @return { Boolean } - `true` if passed url is an internal (application) url, otherwise return `false`
 */
export const isAppUrl = (sUrl) => {
  if(!sUrl) {
    return false;
  }

  const uri = new URI(sUrl);
  const urlHost = uri.protocol() + '://' + uri.host();
  const appUrl = window.location.protocol + '//' + window.location.host;
  const baseUrl = window.K && window.K.config && window.K.config.baseUrl;
  if(urlHost === appUrl || urlHost === baseUrl) {
    return true;
  }
  
  const webAppBaseUrl = window.K && window.K.config && window.K.config.webAppBaseUrl;
  if(urlHost !== webAppBaseUrl) {
    return false;
  }
  
  const path = uri.pathname();
  const legacyUrls = selectors.legacyUrlsRegex();

  //Check url is internal link or not.
  let internalLink = false;
  forEach(legacyUrls, value => {
    const re = new RegExp(value);
    if (re.test(path)) {
      internalLink = true;
      return false;
    }
  });

  return internalLink;
}

/**
 * @param {String} sUrl
 * @returns {Boolean} given url path is same as a current url path.
 */
export const isSameUrlPath = (sUrl) => {
  if(!sUrl || sUrl.includes('/api')) {
    return false;
  }

  let uri = new URI(sUrl);
  let currentUri = new URI();
  return uri.pathname() === currentUri.pathname();
}

/**
 * If passed url is a PWA application then return it otherwise convert into PWA application url and return it.
 * @param { String } sUrl - Application URL
 * @return { String } - PWA application url
 */
export const getPWAUrl = (sUrl) => {
  if(!sUrl || !isAppUrl(sUrl)) {
    return sUrl;
  }

  const uri = new URI(sUrl);
  let path = uri.pathname();

  const webAppBaseUrl = window.K && window.K.config && window.K.config.webAppBaseUrl;
  uri.protocol(window.location.protocol);
  uri.host(window.location.host);

  if(webAppBaseUrl && sUrl.startsWith(webAppBaseUrl) && !path.startsWith('/app')) {
    uri.pathname(`/app/v1${path}`);
  }

  return uri.toString();
}

/**
 * Sets `user-actions` hash path.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openUserActionDialog = (replace = false) => {
  setHashPath('user-actions', replace);
}

/**
 * Sets `auth` hash path.
 * @param {Object} options auth dialog options.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openAuthDialog = ({mode = 'login', source, sourceParams = {}}, replace = false) => {
  let url = `auth?mode=${mode}`;
  if(source) {
    url = `${url}&source=${source}`;
    forEach(sourceParams, (value, key) => {
      url = `${url}&${key}=${value}`;
    });
  }
  let uri = new URI();
  uri.fragment(url);
  if(sourceParams && sourceParams.embedLoginSignup) {
    uri.addQuery('embed-login-signup', 'true');
  }
  navigate(uri.toString(), replace);
}

/**
 * Sets `leave-account` hash path with accountId.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openLeaveAccountDialog = (accountId, replace = false) => {
  setHashPath(`leave-account/${accountId}`, replace);
}

/**
 * Sets `account-switcher` hash path.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openAccountSwitcherDialog = (replace = false, forValue = '') => {
  if(forValue) {
    setHashPath(`account-switcher?for=${forValue}`, replace);
  } else {
    setHashPath('account-switcher', replace);
  }
}

/**
 * Sets `videos` hash path to open videos dialog.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openVideoTutorialDialog = ({name = '', trigger = ''}, replace = false) => {
  setHashPath(trigger ? `videos/${name}?trigger=${trigger}`: `videos/${forValue}`, replace);
}

/**
 * Sets `get-help` hash path.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openGetHelpDialog = (replace = false) => {
  setHashPath('get-help', replace);
}

/**
 * Sets `profile` hash path.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openProfileDialog = (replace = false) => {
  setHashPath('profile', replace);
}

/**
 * Sets `profile` hash path.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openLeaveKerikaDialog = (replace = false) => {
  setHashPath('leave-app', replace);
}

/**
 * Sets `calendar` hash path.
 * @param {String} service Name of the service. Possible values: 'google', 'outlook' or 'apple'
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openCalendarDialog = (service, replace = false) => {
  setHashPath(`calendar?service=${service}`, replace);
}

/**
 * Sets `search` hash path to open search dialog.
 * @param {Boolean} replace Updates with replaceState or not.
 */
export const openSearchDialog = (replace = false) => {
  setHashPath(`search`, replace);
}

/**
 * Sets `invite-account-team` hash path to open invite account team dialog.
 * @param {Boolean} replace Updates with replaceState or not.
 */
 export const openInviteAccountTeamDialog = (replace = false) => {
  setHashPath(`invite-account-team`, replace);
}

/**
 * Sets `join-board` hash path to open join board dialog.
 * @param {Boolean} replace Updates with replaceState or not.
 */
export const openGenerateJoinBoardLinkDialog = (replace = false) => {
  setHashPath(`join-board`, replace);
}

/**
 * Sets `card-actions` hash path with provided query params.
 * @param {Object} params
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openCardActinToolbar = ({cardId, accountId, boardId, columnId}, replace = false) => {
  setHashPath(`card-actions?cardId=${cardId}&accountId=${accountId}&boardId=${boardId}&columnId=${columnId}`, replace);
}

/**
 * Sets `date-picker` hash path.
 * @param {Boolean} replace Navigate with replaceState.
 */
export const openDatePicker = (replace = false) => {
  setHashPath('date-picker', replace);
}

/**
 * Opens `messages-dialog`.
 */
export const openMessagesDialog = (replace = false) => {
  setHashPath('messages', replace);
}

/**
 * Opens `broadcast-message-dialog`.
 */
export const openBroadcastMessagesDialog = (replace = false) => {
  setHashPath('broadcast-messages', replace);
}

/**
 * Open view as webpage for currently opened canvas page.
 */
export const openViewAsWebpageDialog = () => {
  setActionParam('view-as-webpage');
}

/**
 * Open translations dialog for currently opened board page.
 */
export const openTranslationsDialog = (replace = false) => {
  setHashPath('translations', replace);
}

/**
 * Open change file storage dialog.
 * @param {String} storage new storage name.
 */
export const openChangeFileStorageDialog = (storage, replace = false) => {
  setQueryParams({'action': 'change-file-storage', 'storage': storage}, !replace);
}

/**
 * Open manage team confirm dialog.
 */
export const openManageTeamConfirmDialog = (action = 'add', type = 'board-team', as = 1, replace = false) => {
  setHashPath(`manage-team-confirm?action=${action}&type=${type}&as=${as}`, replace);
}

/**
 * Open switch to professional plan dialog.
 */
export const openSwitchToProfessionalDialog = (additionalSubscriptions, replace = false) => {
  if(additionalSubscriptions) {
    setHashPath(`switch-to-professional?as=${additionalSubscriptions}`, replace);
  } else {
    setHashPath(`switch-to-professional`, replace);
  }
}

/**
 * Open manage subscription dialog.
 */
 export const openMangeSubscriptionsDialog = (additionalSubscriptions, replace = false) => {
  if(additionalSubscriptions) {
    setHashPath(`manage-subscriptions?as=${additionalSubscriptions}`, replace);
  } else {
    setHashPath(`manage-subscriptions`, replace);
  }
}

/**
 * Open renew plan dialog.
 */
 export const openRenewPlanDialog = (additionalSubscriptions, replace = false) => {
  if(additionalSubscriptions) {
    setHashPath(`renew-plan?as=${additionalSubscriptions}`, replace);
  } else {
    setHashPath(`renew-plan`, replace);
  }
}

/**
 * Open template learn-more dialog.
 * @param {Boolean} autoOpened learn more dialog is auto opened or not.
 */
export const openLearnMoreDialog = (autoOpened = false) => {
  if(autoOpened) {
    amplitude.logTemplateEvent('template learn more auto presented');
    setQueryParams({'action': 'learn-more', 'auto-opened': 'true'}, true);
  } else {
    amplitude.logTemplateEvent('template learn more opened');
    setActionParam('learn-more');
  }
}

/**
 * Open create-board dialog when user is login otherwise open a login-and-signup dialog.
 * @param {Number} id template id.
 * @param {String} type board type.
 */
export const useTemplate = ({id, type = 'TASKBOARD', params = {}}, replace = false) => {
  const state = _store.getState();
  const userId = auth.selectors.currentUserId(state);
  id = id || selectors.pageBoardId(state);
  params = !isEmpty(params) ? params: {};
  params = merge({}, params, {'template': id});
  const mode = params && params.mode || 'signup';
  if(userId) {
    setActionParam('create-board', {'template': id, 'action-type': type === 'TASKBOARD' ? 'T': 'W'});
  } else {
    openAuthDialog({mode: mode, source: 'template', sourceParams: params}, replace);
  }
  amplitude.logTemplateEvent('template used');
  linkedIn.logEvent(7099266);
}

/**
 * Sets hash path in URL.
 * @param {String} hashPath Hash path
 * @param {Boolean} replace Navigate with replaceState.
 */
export const setHashPath = (hashPath, replace = false) => {
  let uri = new URI();
  uri.fragment(hashPath);
  navigate(uri.toString(), replace);
}

/**
 * History Back to card given cardId.
 * @param {Number} cardId
 * @param {Number} boardId
 */
export const backToCard = ({accountId, cardId, boardId, tab, autoBackCount}) => {
  const historyList = history.getHistoryList();
  const urlWithoutTab = `/${accountId}/board/${boardId}/${cardId}`;
  const url = tab ? `${urlWithoutTab}?tab=${tab}`: urlWithoutTab;

  if(!historyList) {
    navigate(url);
    return;
  }

  if(historyList.length < 2) {
    navigate(url);
    return;
  }

  const backCount = history.getBackCount(url, autoBackCount);
  if(backCount) {
    navigate(url, false, true, autoBackCount);
    return;
  }

  const urlIndex = findLastIndex(historyList, (item) => {
    return item === urlWithoutTab || item.startsWith(urlWithoutTab);
  });

  let count = urlIndex >= 0 && urlIndex !== undefined ? (historyList.length - 1) - urlIndex: 0;
  count = autoBackCount && autoBackCount >= count ? count: 0;
  if(urlIndex < 0 || count < 1) {
    navigate(url);
    return;
  }
  oRouter.go(-count);
}

/**
 * History back to explore page of given account.
 * @returns
 */
export const backToHomeExplorePage = ({accountId, wideLayout, autoBackCount = 1}) => {
  const historyList = history.getHistoryList();
  const tab = getNewExplorerPageTab();
  const filter = getNewExplorerPageFilter();
  const signupCompleted = signup.selectors.signupCompleted(_store.getState());
  const url = wideLayout ? `/home/${filter}/${tab}` : `/${accountId}/home/${filter}/${tab}`;

  if (!signupCompleted) {
    const useCaseBoardIndex = findIndex(historyList, (history) => history.includes('mode=use-case-boards'));
    if (useCaseBoardIndex >= 0) {
      oRouter.go(-((historyList.length - 1) - useCaseBoardIndex));
      return;
    }
  }

  if(!historyList) {
    navigate(url);
    return;
  }

  if(historyList.length < 2) {
    navigate(url);
    return;
  }

  const backCount = history.getBackCount(url, autoBackCount);
  if(backCount) {
    navigate(url, false, true, autoBackCount);
    return;
  }

  const urlIndex = findLastIndex(historyList, (item) => {
    if(wideLayout) {
      return item.startsWith('/home/');
    }
    return item.startsWith(`/${accountId}/home/`);
  });

  let count = urlIndex >= 0 && urlIndex !== undefined ? (historyList.length - 1) - urlIndex: 0;
  count = autoBackCount && autoBackCount >= count ? count: 0;
  if(urlIndex < 0 || count < 1) {
    navigate(url);
    return;
  }

  oRouter.go(-count);
}

const getNewExplorerPageTab = () => {
  const state = _store.getState();
  const currentPageName = selectors.pageName(state);
  if(currentPageName === 'VIEWS') {
    return 'views';
  }

  if(currentPageName === 'BOARD') {
    const boardId = selectors.pageBoardId(state);
    const isTemplate = board.selectors.isTemplate(state, boardId);
    return isTemplate ? 'templates': 'boards';
  }

  return 'boards';
}

const getNewExplorerPageFilter = () => {
  const state = _store.getState();
  const currentPageName = selectors.pageName(state);
  if(currentPageName === 'VIEWS') {
    return 'favorite';
  }

  if(currentPageName === 'BOARD') {
    const boardId = selectors.pageBoardId(state);
    const isFavorite = boardExplorerSelectors.favoriteBoard(state, boardId);
    return  isFavorite ? 'favorite': 'other';
  }

  return 'favorite';
}