import URI from '@dw/urijs-esm';
import { isManageAccountPage } from './validate-url';
import isEmpty from 'lodash-es/isEmpty';

/**
 * This promise function is resolved with parse page data if currently accessed url is an manage account page otherwise reject promise.
 */
export const manageAccountRouter = (data) => {
  return new Promise((resolve, reject) => {
    let uri = new URI(data.path);
    const currentAccountId =uri.segment()[0];

    if (!data.user || !data.user.id || !isManageAccountPage(data.path)) {
      reject(data);
      return;
    }

    if(isEmpty(data.ownedAccounts) && isEmpty(data.accessibleAccounts)) {
      reject(data);
      return;
    }

    if(!data.ownedAccounts.includes(currentAccountId) && !data.accessibleAccounts[currentAccountId]) {
      reject(data);
      return;
    }

    data.accountId = currentAccountId;
    const params = new URI().query(true);
    const action = params.action || '';
    data.page = {
      name: 'MANAGE_ACCOUNT',
      params: {
        tab: uri.segment()[2] || 'summary',
        action,
        ...params
      }
    };

    resolve(data);
  });
}
