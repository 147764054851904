import { DwCheckbox } from '@dreamworld/dw-checkbox/dw-checkbox';
import { css, html } from 'lit-element';
import { body1 } from '@dreamworld/material-styles/typography-literals.js';

/**
 * Extended version of [dw-checkbox](https://github.com/DreamworldSolutions/dw-checkbox/blob/master/dw-checkbox.js). 
 * It's mainly element is used to provide autogrow textarea.
 * 
 * When 'align-top' attribute is set, align checkbox verticlly top instead of center.
 */
export class KerikaCheckbox extends DwCheckbox {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          --mdc-theme-secondary: var(--light-green-color);
          ${body1};
        }

        :host([align-top]){
          --dw-form-field-label-min-height: 40px;
        }

        :host([align-top]) mwc-checkbox{
          align-self: flex-start;
          margin-top: -16px;
        }
    `];
  }

  static get properties() {
    return {
      /**
       * Default value is false.
       * When true, the checkbox remove padding for touchscreens and increase density. Note, the checkbox will no longer meet accessibility guidelines for touch.
       */
      reducedTouchTarget: { type: Boolean },

      /**
       * When `true`, align checkbox at top side.
       */
      alignTop: { type: Boolean, reflect: true, attribute: 'align-top'}
    }
  }

  constructor() {
    super();
    this.reducedTouchTarget = false;
  }

  render() {
    return html`
      <dw-form-field .alignTop=${this.alignTop} .label="${this.label}" ?alignEnd="${this.alignEnd}" ?disabled="${this.disabled}">

        <mwc-checkbox
         ?disabled="${this.disabled}"
         ?checked="${this.checked}"
         ?indeterminate="${this.indeterminate}"
         .reducedTouchTarget=${this.reducedTouchTarget}
         @change="${this._onChange}"
         @click="${(e) => { setTimeout(() => { e.target.blur(); }, 1)}}">
        </mwc-checkbox>

      </dw-form-field>
    `;
  }
}

customElements.define('kerika-checkbox', KerikaCheckbox);