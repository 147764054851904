import { html, css } from 'lit-element';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin';
import { store } from '../store';
import { KerikaCompositeDialog } from '../components/kerika-composite-dialog.js';
import { repeat } from 'lit-html/directives/repeat.js';
import { sharedStyles } from '../theme/shared-styles.js';
import { scrollStyles } from '../theme/scroll-styles.js';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { waitForEntryAnimation } from '../utils.js';
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';
import typographyLiterals from '../theme/typography-literals.js';
import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import uniq from 'lodash-es/uniq';
import without from 'lodash-es/without';
import cloneDeep from 'lodash-es/cloneDeep';
import debounce from 'lodash-es/debounce';
import concat from 'lodash-es/concat';
import uniqBy from 'lodash-es/uniqBy';
import remove from 'lodash-es/remove';

import * as app from '../redux/app';
import * as router from '../redux/router';
import * as importBoard from '../redux/import-board';
import * as amplitude from '../analytics/amplitude.js';


import '../components/common/kerika-button';
import '../components/kerika-loader';
import '../components/kerika-checkbox';
import { isEmpty } from 'lodash-es';

/**
 * Provides a way to import boards from other service, like trello.
 *
 * ## Behaviour
 *  - Loads a Trello details on element connect.
 *  - Shows a board list group by the organization.
 *
 * Usage Pattern:
 *    - <import-board-dialog></import-board-dialog>
 * */
class ImportBoardDialog extends connect(store)(localize(i18next)(KerikaCompositeDialog)) {
  constructor() {
    super();
    this.i18nextNameSpaces = ['signup'];
    this.queryParamValue = 'import-board';
    this.largeCloseIcon = true;
    this.__onResize = debounce(this.__onResize.bind(this), 50);
  }

  static get styles() {
    return [
      super.styles,
      sharedStyles,
      scrollStyles,
      css`
        .loader {
          display: flex;
          align-items: center;
        }

        :host([type="modal"]) .mdc-dialog #dialog-content {
          padding-left: 0px;
          padding-right: 0px;
        }

        :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content {
          padding-left: 0px;
          padding-right: 0px;
        }

        :host([placement="center"][type="modal"][mode="LOADING"]) .mdc-dialog__title .header,
        :host([placement="center"][type="modal"][mode="IMPORTING"]) .mdc-dialog__title .header {
          justify-content: center;
        }

        :host([type="modal"][mode="LOADING"]) .mdc-dialog #dialog-content,
        :host([type="modal"][mode="IMPORTING"]) .mdc-dialog #dialog-content,
        :host([type="fit"][mode="LOADING"]) #dialog-content,
        :host([type="fit"][mode="IMPORTING"]) #dialog-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        :host([placement="center"][type="modal"]) .mdc-dialog__surface {
          min-width: 700px;
          max-width: 700px;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          max-height: 100% !important;
        }

        :host([type="fit"][mode="LOADING"]) .header,
        :host([type="fit"][mode="IMPORTING"]) .header {
          justify-content: center;
          padding: 8px 16px;
        }

        :host([placement="bottom"][type="modal"][mode="LOADING"]) .mdc-dialog__content,
        :host([placement="bottom"][type="modal"][mode="IMPORTING"]) .mdc-dialog__content,
        :host([placement="bottom"][type="modal"][mode="IMPORT_FAILED"]) .mdc-dialog__content,
        :host([placement="bottom"][type="modal"][mode="NORMAL"]) p.message {
          padding-top: 24px;
        }

        :host([type="modal"][mode="LOADING"]) kerika-loader,
        :host([type="modal"][mode="IMPORTING"]) kerika-loader,
        :host([type="fit"][mode="LOADING"]) kerika-loader,
        :host([type="fit"][mode="IMPORTING"]) kerika-loader {
          margin-bottom: 24px;
        }

        :host([type="fit"][mode="LOADING"]) kerika-loader,
        :host([type="fit"][mode="IMPORTING"]) kerika-loader {
          margin-top: 16px;
        }

        .import-failed-container {
          display: flex;
          justify-content: flex-start;
          padding-left: 40px;
          padding-right: 40px;
        }

        :host([placement="center"][type="modal"][mode="NORMAL"]) #dialog-footer {
          justify-content: space-between;
        }

        :host([placement="center"][type="modal"][mode="NORMAL"]) #dialog-footer .select-buttons-container kerika-button {
          margin: 0px 16px 0px 0px;
        }

        :host([type="fit"]) .import-failed-container {
          padding-left: 20px;
          padding-right: 20px;
        }

        :host([placement="bottom"][type="modal"]) .import-failed-container {
          padding-left: 20px;
          padding-right: 20px;
        }

        :host([placement="bottom"][type="modal"]) .import-failed-container,
        :host([type="fit"]) .import-failed-container {
          flex-direction: column;
          align-items: center;
        }

        :host([placement="center"][type="modal"]) .import-failed-container p.message, :host([mode="SUCCESS"]) p.message {
          text-align: left;
        }

        :host([placement="center"][type="modal"]) .import-failed-container p.message,
        :host([placement="bottom"][type="modal"]) .import-failed-container p.message,
        :host([type="fit"]) .import-failed-container p.message {
          padding: 0px;
        }

        .import-failed-container kerika-icon {
          margin-right: 12px;
          --dw-icon-color: var(--error-color);
        }

        :host([placement="bottom"][type="modal"]) .import-failed-container kerika-icon,
        :host([type="fit"]) .import-failed-container kerika-icon {
          margin-bottom: 16px;
        }

        .import-failed-container p {
          margin: 0;
        }

        .items-container {
          padding-left: 40px;
          padding-right: 40px;
        }

        :host([type="fit"]) .items-container {
          padding-left: 20px;
          padding-right: 20px;
        }

        :host([placement="bottom"][type="modal"]) .items-container {
          padding-left: 20px;
          padding-right: 20px;
        }

        .item-group-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-top: none;
          padding: 0px;
          height: 56px;
          background-color: #fff;
          position: sticky;
          top: 34px;
          z-index: 1;
          padding-left: 40px;
          padding-right: 40px;
        }

        :host([type="fit"]) .item-group-header {
          padding-left: 20px;
          padding-right: 20px;
        }

        :host([placement="bottom"][type="modal"]) .item-group-header {
          padding-left: 20px;
          padding-right: 20px;
          top: 40px;
        }

        :host([wide-layout]) .item-group-header {
          padding: 0px;
          padding-left: 40px;
          padding-right: 40px;
        }

        :host([type="fit"][wide-layout]) .item-group-header {
          padding-left: 32px;
          padding-right: 32px;
        }

        kerika-checkbox.item {
          margin-left: -14px;
          ${typographyLiterals.body2};
        }

        p.message {
          text-align: center;
          padding-left: 40px;
          padding-right: 40px;
        }

        p.message.empty {
          text-align: left;
        }

        :host([type="fit"]) p.message {
          padding-left: 20px;
          padding-right: 20px;
        }

        :host([placement="bottom"][type="modal"]) p.message {
          padding-left: 20px;
          padding-right: 20px;
        }

        p.sub-header {
          margin: 0;
          padding-bottom: 16px;
          background-color: #fff;
          position: sticky;
          top: 0;
          z-index: 1;
          padding-left: 40px;
          padding-right: 40px;
        }

        :host([type="fit"]) p.sub-header {
          padding-left: 20px;
          padding-right: 20px;
        }

        :host([placement="bottom"][type="modal"]) p.sub-header {
          padding-left: 20px;
          padding-right: 20px;
        }

        :host([type="fit"]) #dialog-content {
          padding-right: 0px;
          padding-left: 0px;
        }

        :host([type="fit"]) .import-failed-container {
          margin-top: 16px;
        }

        :host([type="fit"][mode="NORMAL"]) p.message {
          margin-top: 16px;
        }

        :host([type="fit"][mode="NORMAL"]) .close-header .title {
          justify-content: flex-start;
        }

        :host([type="fit"][opened][has-footer][mode="NORMAL"]) .mdc-dialog__content,
        :host([type="fit"][opened][has-footer][mode="NORMAL"]) #dialog-content {
          padding-bottom: 72px;
        }

        :host([type="fit"][mode="NORMAL"]) p.sub-header {
          margin-top: 16px;
        }

      `
    ]
  }

  static get properties() {
    return {
      /**
       * Shows dialog content based on this.
       * Possible value: 'LOADING', 'NORMAL', 'IMPORTING', and 'IMPORT_FAILED'.
       */
      _mode: { type: String, reflect: true, attribute: 'mode' },

      /**
       * Import board list.
       */
      _boards: { type: Array },

      /**
       * Selected boards.
       */
      _selectedBoards: { type: Array },

      /**
       * Import trello boards status.
       */
      _importBoardStatus: { type: String },

      /**
       * Import trello start time.
       */
      _importTrelloBoardStartedAt: { type: Number },

      /**
       * `true` when opened in desktop.
       */
      wideLayout: { type: Boolean, reflect: true, attribute: 'wide-layout' },

      /**
       * embeded import trello flow is trigger or not.
       */
      _embedImportTrello: { type: Boolean, reflect: true, attribute: "embed-import-trello" },

      /**
       * import board dialog open source.
       */
      _source: { type: String }
    }
  }

  connectedCallback() {
    super.connectedCallback && super.connectedCallback();
    this.__computeMode();
  }

  update(changedProps) {
    if(changedProps.has('_importBoardStatus') || changedProps.has('_importTrelloBoardStartedAt')) {
      this._trackTakeMoreTimeToImportBoards();
    }
    super.update && super.update(changedProps);
  }

  /**
   * Track server will take more than 5 minutes to import the requested boards.
   */
  _trackTakeMoreTimeToImportBoards() {
    this.__trackTakeMoreTimeToImportBoardsTimer && clearTimeout(this.__trackTakeMoreTimeToImportBoardsTimer);
    if(this._importBoardStatus !== 'IN_PROGRESS' || !this._importTrelloBoardStartedAt) {
      return;
    }

    const currentTime = Date.now();
    const startedAt = this._importTrelloBoardStartedAt;
    const diff = currentTime - startedAt;
    if(diff > (1000 * 60 * 5)) {
      console.error("server will take more than 5 minutes to import the requested boards.");
      return;
    }
    
    //Re-check on every 30 seconds
    this.__trackTakeMoreTimeToImportBoardsTimer = setTimeout(()=> {
      this._trackTakeMoreTimeToImportBoards();
    }, 1000 * 30);
  }

  firstUpdated(changedProps) {
    super.firstUpdated && super.firstUpdated(changedProps);
    const contentEl = this.renderRoot.querySelector('.mdc-dialog__content');
    if(contentEl && this._embedImportTrello && this._isDesktopDevice) {
      this._resizeObserver && this._resizeObserver.disconnect && this._resizeObserver.disconnect();
      this._resizeObserver = new ResizeObserver(this.__onResize.bind(this));
      if(contentEl) {
        this._resizeObserver.observe(contentEl);
      }
    }
  }

  disconnectedCallback() {
    this.__trackTakeMoreTimeToImportBoardsTimer && clearTimeout(this.__trackTakeMoreTimeToImportBoardsTimer);
    this._resizeObserver && this._resizeObserver.disconnect();
    super.disconnectedCallback && super.disconnectedCallback();
  }

  /**
   * Sets minimum height to text editor.
   */
   __onResize(entries) {
    for (let entry of entries) {
      const headerEl = this.renderRoot.querySelector('#dialog-header');
      const headerHeight = headerEl && headerEl.offsetHeight || 0;

      const footerEl = this.renderRoot.querySelector('#dialog-footer');
      const footerHeight = footerEl && footerEl.offsetHeight || 0;

      const contentHeight = get(entry, 'target.scrollHeight', get(entry, 'contentRect.height'));
      const height = headerHeight + footerHeight + contentHeight;

      if (height) {
        const opener = window.top || window.opener;
        if(opener) {
          opener.postMessage({
            type: 'IMPORT_TRELLO_BOARD_DIALOG_HEIGHT_CHANGED',
            height: height
          }, "*");
          return;
        }
      }
    }
  }

  get _contentTemplate() {
    return html`
      ${this.__contentTemplateBasedOnMode}
    `
  }

  get _footerTemplate() {
    if(this._mode === 'SUCCESS') {
      return html`
        <kerika-button filled dismiss>
          ${i18next.t('signup:importTrelloBoards.buttons.okay')}
        </kerika-button>
      `;
    }

    if(this._mode !== 'NORMAL') {
      return;
    }

    if(!this._boards || !this._boards.length) {
      return;
    }

    return html`
      <div class="select-buttons-container">
        <kerika-button
          outlined
          ?hidden=${!this.__canDeselectAll()}
          ?large=${this.placement === 'center'}
          @click=${this.__resetBoardsSelection}>
            ${i18next.t('signup:importTrelloBoards.buttons.deselectAll')}
        </kerika-button>
        <kerika-button
          outlined
          ?hidden=${!this.__canSelectAll()}
          ?large=${this.placement === 'center'}
          @click=${this.__selectAllBoards}>
            ${i18next.t('signup:importTrelloBoards.buttons.selectAll')}
        </kerika-button>
      </div>
      <kerika-button
        filled
        .disabled=${!this.__canImportBoard()}
        ?large=${this.placement === 'center'}
        @click=${this.__onImport}>
          ${i18next.t('signup:importTrelloBoards.buttons.import')}
      </kerika-button>
    `;
  }

  get __contentTemplateBasedOnMode() {
    if(this._mode === 'LOADING' || this._mode === 'IMPORTING') {
      return html`
        <kerika-loader ?large=${this.placement === 'center' || this.type === 'fit'}></kerika-loader>
        <p class="message">${unsafeHTML(this._mode === 'LOADING' ? i18next.t('signup:importTrelloBoards.message.loading'): i18next.t('signup:importTrelloBoards.message.importingBoards'))}</p>
      `;
    }

    if(this._mode === 'IMPORT_FAILED') {
      return html `
        <div class="import-failed-container">
          <kerika-icon .size=${40} name="error_outline"></kerika-icon>
          <p class="message">${unsafeHTML(i18next.t('signup:importTrelloBoards.message.importFailed'))}</p>
        </div>
      `;
    }

    if(this._mode === 'SUCCESS') {
      return html `
        <p class="message">${unsafeHTML(i18next.t('signup:importTrelloBoards.message.success'))}</p>
      `;
    }

    if(!this._boards || !this._boards.length) {
      return html `
        <p class="message empty">${unsafeHTML(i18next.t('signup:importTrelloBoards.message.empty'))}</p>
      `;
    }

    return html`
      <p class="sub-header">${i18next.t('signup:importTrelloBoards.message.selectBoards')}</p>
      ${repeat(this._boards, (account) => account.id, (account) => html`
        <div class="item-group-header ellipsis">${ account.name }</div>
        <div class="items-container">
          ${repeat(account.boards, (board) => board.boardId, (board) => html`
            <kerika-checkbox
              class="item"
              .checked=${this._selectedBoards && this._selectedBoards.includes(board.boardId)}
              .label=${board.name}
              @checked-changed=${(e)=> {
                this._selectedBoards = this.__updateItem(board.boardId, !get(e, 'target.checked'));
              }}>
            </kerika-checkbox>
          `)}
        </div>
      `)}
    `;

  }

  updated(changedProps) {
    super.updated && super.updated(changedProps);
    if(changedProps.has('_mode') || changedProps.has('language')) {
      this.__setDialogTitle();
    }

    if(changedProps.has('_mode')) {
      this.noCancelOnOutsideClick = this._mode === 'LOADING' || this._mode === 'IMPORTING';
      this.noCancelOnEscKey = this._mode === 'LOADING' || this._mode === 'IMPORTING';
      this.closeIconDisabled = this._mode === 'LOADING' || this._mode === 'IMPORTING';
      this.closeIconHide = this._mode === 'LOADING' || this._mode === 'IMPORTING';
      this.layout && this.layout();
    }

    if(changedProps.has('_mode') && this._embedImportTrello) {
      const opener = window.top || window.opener;
      if(opener) {
        opener.postMessage({
          type: 'IMPORT_TRELLO_BOARD_DIALOG_MODE_CHANGED',
          mode: this._mode
        }, "*");
        return;
      }
    }

    if(changedProps.has('_boards') || changedProps.has('_importBoardStatus')) {
      this.__computeMode(changedProps.get('_importBoardStatus'));
    }
  }

  __computeMode(importBoardStatusOldValue) {
    //If suceess mode is set then not set other mode.
    if(this._mode === 'SUCCESS') {
      return;
    }

    if(this._importBoardStatus === 'IN_PROGRESS') {
      this._mode = 'IMPORTING';
      return;
    }

    if(importBoardStatusOldValue === 'IN_PROGRESS' && this._importBoardStatus === 'SUCCESS') {
      this._mode = this._source === 'create-board' ? 'SUCCESS': 'IMPORTING';
      return;
    }

    if(importBoardStatusOldValue === 'IN_PROGRESS' && this._importBoardStatus === 'FAILED') {
      this._mode = 'IMPORT_FAILED';
      return;
    }

    if(this._boards === undefined) {
      this._mode = 'LOADING';
      return;
    }

    if(this._mode !== 'NORMAL') {
      this.__selectAllBoards();
      amplitude.logEvent('trello import boards presented', this._accountId ? { 'account_id': this._accountId, workspaces: this.__totalWorkspaces(), boards: this.__totalBoards() }: { workspaces: this.__totalWorkspaces(), boards: this.__totalBoards() });
      this._mode = 'NORMAL';
      return;
    }
  }

  /**
   * Close dialog.
   * @protected
   */
  async _onCloseClick(e) {
    await waitForEntryAnimation(e);
    if(this._embedImportTrello) {
      const opener = window.top || window.opener;
      if(opener) {
        opener.postMessage({
          type: 'IMPORT_TRELLO_BOARD_DIALOG_CLOSE',
          closingReason: 'CLOSE_ICON_BUTTON'
        }, "*");
        return;
      }
    }
    this.__lastClosingReason = 'CLOSE_ICON_BUTTON';
    this.close();
  }

  /**
   * @returns {Boolean} import is possible or not.
   * @private
   */
  __canImportBoard() {
    if(!this._boards || !this._boards.length) {
      return false;
    }

    return this._selectedBoards && !!this._selectedBoards.length;
  }

  /**
   * Invoked when user click on `IMPORT` button.
   * @private
   */
  __onImport() {
    if(!this.__canImportBoard()) {
      return;
    }

    const uninvitedMemberCount = this.__totalUninvitedUsersCount();
    this.__hasTrelloImportBoardsStarted = true;
    amplitude.logEvent(
      'trello import boards started',
      this._accountId
        ? {
            'account_id': this._accountId,
            'boards': this.__totalSelectedBoards(),
            'total_boards': this.__totalBoards(),
            'total_users': this.__totalUsersCount(),
            'uninvited_users': uninvitedMemberCount,
          }
        : {
            'boards': this.__totalSelectedBoards(),
            'total_boards': this.__totalBoards(),
            'total_users': this.__totalUsersCount(),
            'uninvited_users': uninvitedMemberCount,
          }
    );
    store.dispatch(importBoard.actions.importBoards(this._selectedBoards, uninvitedMemberCount, this._accountId));
  }

  __totalUser() {
    if(isEmpty(this._selectedBoards) || isEmpty(this._boards)) {
      return [];
    }

    let members = [];
    forEach(this._selectedBoards, (boadId) => {
      forEach(this._boards, (account) => {
        let matched = false;
        forEach(account.boards, (board) => {
          if(board.boardId == boadId) {
            members = concat(members, board.members);
            matched = true;
            return false;
          }
        });

        if(matched) {
          return false;
        }
      });
    });
    return uniqBy(members, (member)=> member.id);
  }

  __totalUninvitedUsers() {
    const users = this.__totalUser() || [];
    return remove(users, (member)=> member.uninvited);
  }

  __totalUsersCount() {
    const users = this.__totalUser() || [];
    return users && users.length || 0;
  }

  __totalUninvitedUsersCount() {
    const users = this.__totalUninvitedUsers() || [];
    return users && users.length || 0;
  }

  __totalUser() {
    if(isEmpty(this._selectedBoards) || isEmpty(this._boards)) {
      return [];
    }

    let members = [];
    forEach(this._selectedBoards, (boadId) => {
      forEach(this._boards, (account) => {
        let matched = false;
        forEach(account.boards, (board) => {
          if(board.boardId == boadId) {
            members = concat(members, board.members);
            matched = true;
            return false;
          }
        });

        if(matched) {
          return false;
        }
      });
    });
    return uniqBy(members, (member)=> member.id);
  }

  __totalUninvitedUsers() {
    const users = this.__totalUser() || [];
    return remove(users, (member)=> member.uninvited);
  }

  __totalUsersCount() {
    const users = this.__totalUser() || [];
    return users && users.length || 0;
  }

  __totalUninvitedUsersCount() {
    const users = this.__totalUninvitedUsers() || [];
    return users && users.length || 0;
  }

  /**
   * reselect all boards.
   * @private
   */
  __resetBoardsSelection() {
    this._selectedBoards = [];
  }

  /**
   * select all boards.
   * @private
   */
  __selectAllBoards(){
    let selectedBoards = [];
    forEach(this._boards, (account) => {
      forEach(account.boards, (board) => {
        selectedBoards.push(board.boardId);
      });
    });
    this._selectedBoards = selectedBoards;
  }

  __totalSelectedBoards() {
    return this._selectedBoards && this._selectedBoards.length || 0;
  }

  __totalBoards() {
    let totlaBoards = 0;
    forEach(this._boards, (account) => {
      forEach(account.boards, (board) => {
        if (board.boardId) {
          totlaBoards++;
        }
      });
    });
    return totlaBoards;
  }

  __canSelectAll() {
    return this.__totalBoards() !== this.__totalSelectedBoards();
  }

  __canDeselectAll() {
    return !!this.__totalSelectedBoards();
  }

  __totalWorkspaces() {
    return this._boards && this._boards.length || 0;
  }

  /**
   * @override
   * @param {Object} e Event
   */
  _onDialogClosed(e) {
    super._onDialogClosed && super._onDialogClosed(e);
    if(!this.__hasTrelloImportBoardsStarted) {
      amplitude.logEvent('trello import boards cancelled', this._accountId ? { 'account_id': this._accountId, ux: this.__lastClosingReason || 'UNKNOWN' }: { ux: this.__lastClosingReason || 'UNKNOWN' });
    }
    this.__hasTrelloImportBoardsStarted = undefined;
  }

    /**
   * @param {String} item
   * @param {Boolean} remove
   * @returns {Array} add/remove item based on `remove` argument from current board lists and return uniq array.
   * @private
   */
  __updateItem(item, remove) {
    const bords = cloneDeep(this._selectedBoards || []);
    const resutls = remove ? this.__removeItem(bords, item): this.__addItem(bords, item);
    return resutls;
  }

  /**
   * @param {Array} list
   * @param {String} item
   * @returns {Array} remove item from given lists and return uniq array.
   * @private
   */
  __removeItem(list, item) {
    return uniq(without(list, item));
  }

  /**
   * @param {Array} list
   * @param {String} item
   * @returns {Array} add item from given lists and return uniq array.
   * @private
   */
  __addItem(list, item) {
    list.push(item);
    return uniq(list);
  }

  /**
   * Invoked When language is changed.
   * @override
   */
  _setLanguage(newLanguage) {
    super._setLanguage && super._setLanguage(newLanguage);
    this.__setDialogTitle();
  }

  /**
   * Sets a dialog title based on mode.
   * @private
   */
  __setDialogTitle() {
    if(this._mode === 'LOADING') {
      this.title = i18next.t('signup:importTrelloBoards.title.loading');
      return;
    }

    if(this._mode === 'NORMAL') {
      this.title = i18next.t('signup:importTrelloBoards.title.selectBoards');
      return;
    }

    if(this._mode === 'IMPORTING' || this._mode === 'IMPORT_FAILED') {
      this.title = i18next.t('signup:importTrelloBoards.title.importingBoards');
      return;
    }

    if(this._mode === 'SUCCESS') {
      this.title = i18next.t('signup:importTrelloBoards.title.success');
      return;
    }
  }

  stateChanged(state) {
    const page = router.selectors.page(state);
    this._accountId = get(page, 'params.accountId', '');

    this._source = router.selectors.source(state);
    this._isDesktopDevice = app.selectors.isDesktopDevice(state);
    this._embedImportTrello = router.selectors.embedTrelloImport(state);
    this._importTrelloBoardStartedAt = importBoard.selectors.importTrelloBoardStartedAt(state);
    this._importBoardStatus = importBoard.selectors.importBoardsStatus(state);
    this._boards = importBoard.selectors.boardList(state);

    this.noCancelOnOutsideClick = this._embedImportTrello || this._importBoardStatus === 'IN_PROGRESS';
    this.noCancelOnEscKey = this._embedImportTrello || this._importBoardStatus === 'IN_PROGRESS';
  }
}

window.customElements.define('import-board-dialog', ImportBoardDialog);
