import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';

import * as auth from '../auth';
import * as signupSelectors from '../signup/selectors.js';
import * as utils from '../../utils';
import * as selectorCreator from '../selector-creator.js';
import firestoreRedux from '@dreamworld/firestore-redux';


/**
 * @param {Object} state redux state.
 * @returns {Object} authentication process details.
 */
export const authenticate = (state) => get(state, 'import-board.authentication');

/**
 * @returns {Array} Organization wise board list.
 *                   e.g. [
 *                          {
 *                            id: "61f245bc01a797634a6809b8",
 *                            name: "Trello Workspace - 2",
 *                            boards: [
 *                              {
 *                                id: "61f3d4165fa8b74edf7a11fe",
 *                                name: "1"
 *                              },
 *                              {
 *                                id: "61f245ec950dc13cbcf43158",
 *                                name: "Research Project"
 *                              },
 *                              ...
 *                            ]
 *                          },
 *                          ...
 *                        ]
 */
export const boardList = selectorCreator.default({maxSize: 50})(
  (state) => get(state, `import-board.data`),
  (data) => {
    if (data === undefined) {
      return undefined;
    }

    if (isEmpty(data) || isEmpty(data.boards) || isEmpty(data.accounts)) {
      return [];
    }

    let _boardList = [];
    let _unknownOrginazations = [];
    forEach(data.accounts, (account) => {
      let boards = [];
      forEach(data.boards, (board) => {
        if (account.id === board.accountId) {
          boards.push(board);
        }
      });
      if (!isEmpty(boards)) {
        boards = sortBy(boards, (o) => o && o.name && o.name.toLowerCase());
        let item = { boards, id: account.id, name: account.name };
        if (account.name === 'unknown') {
          _unknownOrginazations.push({ ...item, ...{ name: 'Personal' } });
        } else {
          _boardList.push(item);
        }
      }
    });

    _boardList = sortBy(_boardList, (o) => {
      return o && o.name && o.name.toLowerCase();
    });

    //Shows unknown origanization to last of the list.
    if (!isEmpty(_unknownOrginazations)) {
      _boardList = concat(_boardList, _unknownOrginazations);
    }

    return _boardList;
  }
);

/**
 * @param {Object} state redux state.
 * @returns {Object} boards import process details.
 */
export const importBoards = (state) => get(state, 'import-board.import');

/**
 * @returns {Object} given account last import-board-request.
 */
const importBoardRequestData = selectorCreator.default({maxSize: 10})(
  ({state, accountId}) => accountId,
  ({state, accountId}) => firestoreRedux.selectors.collection(state, 'import-boards-request') || [],
  (accountId, allImportBoardRequestData) => {
    const accountImportBoardRequestData = sortBy(filter(allImportBoardRequestData, { accountId }) || [], ['startedAt']).reverse();
    return accountImportBoardRequestData && accountImportBoardRequestData[0] || {};
  }
);

const boards = selectorCreator.createDeepEqualSelector(
  (state) => importBoardRequestData({state, accountId: importTrelloBoardAccountId(state)}),
  (importBoardRequestData) => {
    return get(importBoardRequestData, `boards`);
  }
);

/**
 * Current import board summary.
 * @returns {Object} 
 *  - e.g {total, completed}
 */
export const boardSummary = selectorCreator.createDeepEqualSelector(
  (state) => importBoardsStatus(state),
  (state) => boards(state),
  (status, boards) => {
    if(isEmpty(boards) || status === 'IN_PROGRESS') {
      return {};
    }

    const completedBoards = filter(boards, (item) => item && (item.status === 'COMPLETED' || item.status === 'FAILED' || item.status === 'PARTIAL_FAILED'));
    const completed = completedBoards && completedBoards.length || 0;
    const total = boards && boards.length || 0;
    if(total === completed) {
      return {};
    }

    return { total,  completed};
  }
);

/**
 * Import trello performe action user id.
 * @returns {Number}
 */
export const importTrelloBoardActionBy = selectorCreator.reselct.createSelector(
  (state) => importBoardRequestData({state, accountId: importTrelloBoardAccountId(state)}),
  (importBoardRequestData) => {
    return get(importBoardRequestData, `actionBy`);
  }
);

/**
 * Import trello performe startedAt.
 * @returns {Number}
 */
export const importTrelloBoardStartedAt = selectorCreator.reselct.createSelector(
  (state) => importBoardRequestData({state, accountId: importTrelloBoardAccountId(state)}),
  (importBoardRequestData) => {
    return get(importBoardRequestData, `startedAt`);
  }
);

/**
 * Import trello accountId.
 * @returns {Number}
 */
export const importTrelloBoardAccountId = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserOwnedAccountId,
  (state) => get(state, 'router.page.params.accountId'),
  (ownedAccountId, pageAccountId) => {
    return pageAccountId || ownedAccountId;
  }
);

/**
 * Import trello account owner id.
 * @returns {Number}
 */
export const importTrelloBoardAccountOwnerId = selectorCreator.reselct.createSelector(
  (state) => importBoardRequestData({state, accountId: importTrelloBoardAccountId(state)}),
  (importBoardRequestData) => {
    return get(importBoardRequestData, `ownerId`);
  }
);

/**
 * Import trello board creation completed or not.
 * @returns {Boolean}
 */
export const importTrelloboardsCreationCompleted = selectorCreator.reselct.createSelector(
  (state) => importBoardRequestData({state, accountId: importTrelloBoardAccountId(state)}),
  (importBoardRequestData) => {
    return get(importBoardRequestData, `boardsCreationCompleted`);
  }
);

/**
 * @param {Object} state redux state.
 * @returns {String}
 */
export const importBoardsStatus = selectorCreator.reselct.createSelector(
  (state) => importTrelloboardsCreationCompleted(state),
  (state) => importBoardRequestData({state, accountId: importTrelloBoardAccountId(state)}),
  (state) => get(state, 'import-board.import.status'),
  (importTrelloboardsCreationCompleted, importBoardRequestData, _firebaseStatus) => {
    let firebaseStatus = importBoardRequestData && importBoardRequestData.status || '';
    firebaseStatus = firebaseStatus === 'COMPLETED' ||  firebaseStatus === 'PARTIAL_FAILED' || importTrelloboardsCreationCompleted === true ? 'SUCCESS' : firebaseStatus === 'PENDING'? 'IN_PROGRESS': firebaseStatus;

    firebaseStatus = firebaseStatus || _firebaseStatus;
    return firebaseStatus;
  }
);

export const isTrelloUser = (state) => {
  return signupSelectors.signupSource(state) === 'TRELLO' || importBoardsStatus(state) === 'SUCCESS';
};

/**
 * @param {Object} state redux state
 * @returns {Number} successfully imported board count.
 */
export const trelloBoardsCount = selectorCreator.reselct.createSelector(
  (state) => importBoardRequestData({state, accountId: importTrelloBoardAccountId(state)}),
  (importBoardRequestData) => {
    let counts = 0;
    const trelloBoards = get(importBoardRequestData, `boards`) || {};
    forEach(trelloBoards, (board) => {
      if (board && board.status == 'COMPLETED') {
        counts++;
      }
    });
    return counts;
  }
);
