import { html, css } from 'lit-element';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin';
import { store } from '../store';
import { KerikaCompositeDialog } from '../components/kerika-composite-dialog.js';
import { sharedStyles } from '../theme/shared-styles.js';
import { scrollStyles } from '../theme/scroll-styles.js';
import { waitForEntryAnimation } from '../utils.js';
import { repeat } from 'lit-html/directives/repeat.js';
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';

import get from 'lodash-es/get';
import debounce from 'lodash-es/debounce';
import filter from 'lodash-es/filter';
import forEach from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';

import * as app from '../redux/app';
import * as router from '../redux/router';

import '../components/kerika-loader';
import './use-case-board-card.js';
import '../components/filter-chips/filter-chips.js';

/**
 * Provides a way to shows use-case boards of applications.
 *
 * Usage Pattern:
 *    - <use-case-boards-dialog></use-case-boards-dialog>
 * */
class UseCaseBoardsDialog extends connect(store)(localize(i18next)(KerikaCompositeDialog)) {
  constructor() {
    super();
    this.doNotDelayRendering = true;
    this.i18nextNameSpaces = ['signup'];
    this.queryParamValue = 'use-case-boards';
    this.largeCloseIcon = true;
    this.__onResize = debounce(this.__onResize.bind(this), 50);
    this._currentCategory = 'something-else';
  }

  static get styles() {
    return [
      super.styles,
      sharedStyles,
      scrollStyles,
      css`
        .loader {
          display: flex;
          align-items: center;
        }

        :host([type="modal"]) .mdc-dialog #dialog-content {
          padding-left: 0px;
          padding-right: 0px;
        }

        :host([placement="center"][type="modal"]) .mdc-dialog__title .header {
          justify-content: center;
        }

        :host([placement="center"][type="modal"]) .header.close-header .title {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        :host([type="modal"]) .mdc-dialog #dialog-content,
        :host([type="fit"]) #dialog-content {
          display: flex;
          flex-direction: column;
        }

        :host([placement="center"][type="modal"]) .mdc-dialog__surface {
          min-width: 990px;
          max-width: 990px;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          max-height: 100% !important;
          min-height: 650px;
        }

        :host([placement="center"][type="modal"][is-tablet-device]) .mdc-dialog__surface {
          min-width: 700px;
          max-width: 700px;
        }

        :host([placement="center"][type="modal"][is-tablet-device][is-portrait]) .mdc-dialog__surface {
          min-height: 800px;
        }

        :host([type="fit"]) .header {
          padding: 8px 16px;
        }

        :host([type="fit"]) #dialog-content {
          padding-right: 0px;
          padding-left: 0px;
        }

        :host([type="fit"]) .close-header .title {
          justify-content: flex-start;
        }

        :host([type="fit"]) .sub-title,
        :host([placement="bottom"][type="modal"]) .sub-title,
        :host([type="fit"]) .filter-container,
        :host([placement="bottom"][type="modal"]) .filter-container {
          padding: 0px 16px;
        }

        :host([type="fit"]) .items,
        :host([placement="bottom"][type="modal"]) .items {
          justify-content: space-evenly;
        }

        use-case-board-card {
          margin: 0 16px 16px 16px;
          text-align: center;
          --use-case-board-card-surface-margin: 0;
          --use-case-board-card-width: 264px;
        }


        :host(:not([is-tablet-device]):not([is-desktop-device]))  use-case-board-card {
          --use-case-board-card-width: calc(100vw - 40px);
          --use-case-board-card-surface-margin: 0;
          margin-right: 0;
          margin-left: 0;
        }

        .items {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
        }

        .loading-container {
          flex: 1;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .filter-container {
          width: 100%;
          box-sizing: border-box;
          margin-top: 24px;
          margin-bottom: 16px;
        }
      `
    ]
  }

  static get properties() {
    return {
      /**
       * List of use case boards. e.g. [{id, name, description, thumbnail}]
       */
      _useCaseBoards: { type: Array },

      /**
       * embeded use case boards flow is trigger or not.
       */
      _embedUseCaseBoards: { type: Boolean, reflect: true, attribute: "embed-use-case-boards" },

      /**
       * When tablet device, it is `true`
       */
      _isTabletDevice: { type: Boolean, reflect: true, attribute: 'is-tablet-device'},

      /**
       * When desktop device, it is `true`
       */
      _isDesktopDevice: { type: Boolean, reflect: true, attribute: 'is-desktop-device' },

      /**
       * When orientation is landscape, it is `true`
       */
      _isLandscape: { type: Boolean, reflect: true, attribute: 'is-landscape' },

      /**
       * When orientation is portrait, it is `true`
       */
      _isPortrait: { type: Boolean, reflect: true, attribute: 'is-portrait' },

      /**
       * job-role options
       */
      _jobRoleOptions: { type: Array },

      /**
       * Current category.
       */
      _currentCategory: { type: String },

      _availableIndustries: { type: Array },
    }
  }

  firstUpdated(changedProps) {
    super.firstUpdated && super.firstUpdated(changedProps);
    const contentEl = this.renderRoot.querySelector('.mdc-dialog__content');
    if(contentEl && this._embedUseCaseBoards && this._isDesktopDevice) {
      this._resizeObserver && this._resizeObserver.disconnect && this._resizeObserver.disconnect();
      this._resizeObserver = new ResizeObserver(this.__onResize.bind(this));
      if(contentEl) {
        this._resizeObserver.observe(contentEl);
      }
    }
  }

  disconnectedCallback() {
    this._resizeObserver && this._resizeObserver.disconnect();
    super.disconnectedCallback && super.disconnectedCallback();
  }

  update(changedProps) {
    if(changedProps.has('_currentCategory') || changedProps.has('_availableIndustries')) {
      this._computeDefaultCategory();
    }

    if(changedProps.has('_currentCategory')) {
      this._computeUseCaseBoards(store.getState())
    }

    super.update && super.update(changedProps);
  }

  _computeDefaultCategory() {
    const industries = this._availableIndustries || [];
    if(isEmpty(industries)) {
      return;
    }

    if(!industries.includes || industries.includes(this._currentCategory)) {
      return;
    }

    this._currentCategory = industries.includes('something-else') ? 'something-else': industries[0];
  }

  /**
   * Sets minimum height to text editor.
   */
   __onResize(entries) {
    for (let entry of entries) {
      const headerEl = this.renderRoot.querySelector('#dialog-header');
      const headerHeight = headerEl && headerEl.offsetHeight || 0;

      const footerEl = this.renderRoot.querySelector('#dialog-footer');
      const footerHeight = footerEl && footerEl.offsetHeight || 0;

      const contentHeight = get(entry, 'target.scrollHeight', get(entry, 'contentRect.height'));
      const contentWidth = get(entry, 'target.scrollWidth', get(entry, 'contentRect.width'));
      const height = headerHeight + footerHeight + contentHeight;

      if (height) {
        const opener = window.top || window.opener;
        if(opener) {
          opener.postMessage({
            type: 'USE_CASE_BOARDS_DIALOG_HEIGHT_CHANGED',
            height: height,
            width: contentWidth
          }, "*");
          return;
        }
      }
    }
  }

  get _contentTemplate() {
    return html`
      ${this._useCaseBoards === undefined? html`
        <div class="loading-container">
          <kerika-loader ?large=${this.placement === 'center' || this.type === 'fit'}></kerika-loader>
        </div>
      `: html`
        <div class="sub-title">${i18next.t('signup:useCaseBoards.subTitle2')}</div>
        <div class="filter-container">
          <filter-chips 
            .items=${this._getFilterItems()} 
            .value=${this._currentCategory}
            .mobileMode=${this.placement === 'bottom'}
            @value-changed=${this._onFilterChipsValueChanged}>
          </filter-chips>
        </div>
        <div class="items">
          ${this._useCaseBoards && repeat(
            this._useCaseBoards,
            (board) => board.id,
            (board) => {
              return html` <use-case-board-card disable-user-selection .model=${board} .openInNewTab=${true} .embedUseCaseBoards=${this._embedUseCaseBoards}></use-case-board-card> `;
            }
          )}
        </div>
      `}
    `;
  }

  _getFilterItems() {
    let items = this._jobRoleOptions || [];
    if(isEmpty(items)) {
      return items;
    }
    items = filter(items, (item) => item.value !== 'something-else');
    items.unshift({value: 'something-else', name: i18next.t('signup:useCaseBoards.options.default')});
    const newItems = [];
    forEach(items, (item) => {
      const value = item && item.value;
      if(this._availableIndustries && this._availableIndustries.includes(value)) {
        newItems.push(item);
      }
    });

    if(isEqual(newItems, this._lastItems)) {
      return this._lastItems;
    }
    this._lastItems = newItems;
    return newItems;
  }

  _onFilterChipsValueChanged(e) {
    this._currentCategory =  e.detail.value;
  }

  /**
   * Close dialog.
   * @protected
   */
  async _onCloseClick(e) {
    await waitForEntryAnimation(e);
    if(this._embedUseCaseBoards) {
      const opener = window.top || window.opener;
      if(opener) {
        opener.postMessage({
          type: 'USE_CASE_BOARDS_DIALOG_CLOSE',
          closingReason: 'CLOSE_ICON_BUTTON'
        }, "*");
        return;
      }
    }
    this.__lastClosingReason = 'CLOSE_ICON_BUTTON';
    this.close();
  }

  /**
   * Invoked When language is changed.
   * @override
   */
  _setLanguage(newLanguage) {
    super._setLanguage && super._setLanguage(newLanguage);
    this.__setDialogTitle();
  }

  /**
   * Sets a dialog title based on mode.
   * @private
   */
  __setDialogTitle() {
    this.title = i18next.t('signup:useCaseBoards.title');
  }

  _computeUseCaseBoards(state) {
    this._useCaseBoards = app.selectors.categoryUseCaseBoard(state, this._currentCategory);
  }

  stateChanged(state) {
    this._isTabletDevice = app.selectors.isTabletDevice(state);
    this._isDesktopDevice = app.selectors.isDesktopDevice(state);
    this._isLandscape = app.selectors.isLandscape(state);
    this._isPortrait = app.selectors.isPortrait(state);
    this._embedUseCaseBoards = router.selectors.embedUseCaseBoards(state);
    this.noCancelOnOutsideClick = this._embedUseCaseBoards;
    this.noCancelOnEscKey = this._embedUseCaseBoards;
    const jobRole = app.selectors.jobRole(state) || {};
    this._jobRoleName = jobRole.title || '';
    this._jobRoleOptions = jobRole.options || [];
    this._availableIndustries = app.selectors.availableIndustries(state);

    this._computeUseCaseBoards(state);
  }
}

window.customElements.define('use-case-boards-dialog', UseCaseBoardsDialog);
