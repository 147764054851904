import { LitElement, html, css } from 'lit-element';
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';

import { repeat } from 'lit-html/directives/repeat.js';

//Lodash
import isEmpty from 'lodash-es/isEmpty.js';
import get from 'lodash-es/get.js';
import forEach from 'lodash-es/forEach.js';
import isEqual from 'lodash-es/isEqual.js';

// Components
import './filter-chip-item.js';
import './filter-chips-more-select.js';
import '../common/kerika-button.js';

/**
 * Provides a way to filter a categories.
 * 
 * Material guide: https://m3.material.io/components/chips/guidelines#11bc122b-5203-4cf5-bb12-470d86ecd3b5
 */
class FilterChips extends localize(i18next)(LitElement) {
  static get properties() {
    return {
      /**
       * Filter chips items.
       */
      items: { type: Array },

      /**
       * Currently selected value.
       */
      value: { type: String },

      /**
       * Mobile mode or not.
       */
      mobileMode: { type: Boolean },

      /**
       * hidden items list.
       */
      _hiddenItems: { type: Object },
    }
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
          --dw-select-dialog-border-radius: 8px;
        }
        
        .container {
          position: relative;
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        filter-chip-item {
          outline: none;
          margin: 4px 8px 4px 0px;
        }

        filter-chip-item[visibility="hidden"] {
          visibility: hidden;
        }

        kerika-button.more {
          position: absolute;
          right: 0px;
          top: 4px;
          z-index: 1;
          max-width: 224px;
          background-color: var(--primary-background-color);
          height: 34px;
          --mdc-theme-primary: var(--primary-text-color);
          --kerika-button-height: 34px;
          --kerika-button-box-sizing: content-box;
          --kerika-button-padding: 0px 12px;
          --kerika-button-outline-color: var(--divider-color);
          --kerika-button-border-radius: 8px;
          --kerika-button-box-sizing: border-box;
        }

        kerika-button.more[selected] {
          background-color: rgb(0, 128, 0, 0.12);
          --kerika-button-outline-color: transparent;
        }
      `
    ]
  }

  constructor() {
    super();
    this.doNotDelayRendering = true;
    this._onClickChipItem = this._onClickChipItem.bind(this);
    this._onConnectedChangedChipItem = this._onConnectedChangedChipItem.bind(this);
  }

  render(){
    if(isEmpty(this.items)) {
      return html``;
    }

    return html`
      <div class="container">
        ${repeat(this.items, (item) => item.value, (item, index) => {
          return html`
            <filter-chip-item
              tabindex="0"
              visibility=${this._isHiddenItem(item) ? 'hidden': 'visible'}
              .item=${item}
              .selected=${this._isSelected(item)}
              @click=${() => this._onClickChipItem(item)}
              @connected-changed=${this._onConnectedChangedChipItem}
              @keydown=${(e) => this._onKeyDownChipItem(e, item)}>
            </filter-chip-item>
          `;
        })}
  
        <kerika-button ?hidden=${!this._hasHiddenItems()} ?selected=${this._isMoreSelected()} outlined .trailingIcon=${true} icon="keyboard_arrow_down" text-inherit class="more left-align-text" @click=${this._onMoreClick} .label=${this._getMoreButtonText()}></kerika-button>
      </div>
      ${this._hasHiddenItems() ? html`
        <filter-chips-more-select
          .customTrigger=${true}
          .items=${this._getMoreItems()}
          .value=${this.value}
          .dialogTitle=${i18next.t('signup:useCaseBoards.options.moreTitle')}
          .appendTo=${this.renderRoot}
          .noHeader=${!this.mobileMode}
          .mobileMode=${this.mobileMode}
          .large=${!this.mobileMode}
          .positionTarget=${this._getdialogPositionTarget()}
          @value-changed=${this._onMoreFilterChipsChanged}>
        </filter-chips-more-select>
      `: ''}
    `;
  }

  _getMoreButtonText() {
    if(!this._isMoreSelected()) {
      return i18next.t('signup:useCaseBoards.options.more');
    }

    let text = i18next.t('signup:useCaseBoards.options.more');
    const items = this._getMoreItems();
    forEach(items, (item) => {
      if(item && item.value && item.value === this.value) {
        text = item.name;
        return false;
      }
    })
    return text;
  }

  _getdialogPositionTarget() {
    return this.renderRoot.querySelector('.more');
  }

  _isMoreSelected() {
    if(!this.value) {
      return false;
    }

    let selected = false;
    const items = this._getMoreItems();
    forEach(items, (item) => {
      if(item && item.value && item.value === this.value) {
        selected = true;
        return false;
      }
    })
    return selected;
  }

  _isSelected(item) {
    if(!item || !item.value) {
      return false;
    }

    if(typeof this.value === 'object') {
      return this.value && this.value.includes && this.value.includes(item.value) || false;
    }

    return this.value === item.value;
  }

  _getMoreItems() {
    const newItems = [];
    forEach(this.items, (item) => {
      if(this._isHiddenItem(item)) {
        newItems.push(item);
      }
    });

    if(isEqual(this._lastMoreItems, newItems)) {
      return this._lastMoreItems;
    }

    this._lastMoreItems = newItems;
    return newItems;
  }

  _hasHiddenItems() {
    return !isEmpty(this._hiddenItems);
  }

  _isHiddenItem(item) {
    if(!this._hasHiddenItems() || !item) {
      return false;
    }

    const value = item && item.value;
    return this._hiddenItems[value] || false;
  }

  _onMoreClick() {
    const dialog = this.renderRoot.querySelector('filter-chips-more-select');
    dialog && dialog.open && dialog.open();
  }

  _onMoreFilterChipsChanged(e) {
    const value = e && e.detail && e.detail.value || '';
    this.dispatchEvent(new CustomEvent('value-changed', { detail: { value: value } }));
    this.value = value;
  }

  _onClickChipItem(item) {
    const value = item.value;
    this.dispatchEvent(new CustomEvent('value-changed', { detail: { value: item.value } }));
    this.value = value;
  }

  _onKeyDownChipItem(e, item) {
    const key = e && e.key;
    const keyCode = e && e.keyCode;
    if(key === 'Enter' || keyCode === 13) {
      this.dispatchEvent(new CustomEvent('value-changed', { detail: { value: item.value } }));
      this.value = value;
    }
  }

  _onConnectedChangedChipItem(event) {
    const value = event.detail.value;
    const item = event.target;

    if(!value) {
      this.__unobserveItem(item);
      return;
    }

    this.__observeItem(item, value);
  }

  __observeItem(item, value) {
    if(!value) {
      this.__unobserveItem(item);
      return;
    }
    if (!this._ioInstance) {
      const root = document.scrollingElement === this ? null : this;
      const options = { root, rootMargin: `0px -230px 0px 0px`, threshold: [0, 0.3, 0.5, 0.9, 1] };
      this._ioInstance = new IntersectionObserver(this.__intersectionObserverCallback.bind(this), options);
      this._ioInstance.observe(item);
    } else {
      this._ioInstance.observe(item);
    }
  }

  __unobserveItem(item) {
    this._ioInstance && this._ioInstance.unobserve(item);
    const value = item && item.item && item.item.value || '';
    value && this.__removeHiddenList(value);
  }

  __intersectionObserverCallback(entries) {
    entries.forEach((entry) => {
      const item = entry.target;
      const value = item && item.item && item.item.value || '';
      if(!value) {
        return;
      }
      const intersectionRatio = entry.intersectionRatio;
      if (intersectionRatio == 1) {
        this.__removeHiddenList(value);
      } else {
        this.__addHiddenList(value);
      }
    });
  }

  __removeHiddenList(item) {
    if (isEmpty(this._hiddenItems)) {
      return;
    }

    if (get(this._hiddenItems, item) === undefined) {
      return;
    }

    delete this._hiddenItems[item];
    this._hiddenItems = { ...this._hiddenItems };
  }

  __addHiddenList(item)  {
    this._hiddenItems = { ...this._hiddenItems, ...{ [item]: true } };
  }
}
customElements.define('filter-chips', FilterChips);