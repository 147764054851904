import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import * as actions from './actions.js';


export const freshUserWizardReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.CHANGE_MODE:
      if(action.boardName) {
        state = ReduxUtils.replace(state, `boardName`, action.boardName);
      }
      return ReduxUtils.replace(state, `mode`, action.mode);
    case actions.ADD_NEW_CARD_WITH_ORDER:
      return ReduxUtils.replace(state, `cards.${action.id}`, {title: action.title, columnOrder: action.columnOrder});
    case actions.REMOVE_CARD:
      return ReduxUtils.replace(state, `cards.${action.id}`, undefined);
    case actions.UPDATE_CARD_TITLE:
      return ReduxUtils.replace(state, `cards.${action.id}.title`, action.title);
    case actions.CREATE_BOARD:
      state = ReduxUtils.replace(state, `error`, undefined);
      return ReduxUtils.replace(state, `status`, 'IN_PROGRESS');
    case actions.CREATE_BOARD_SUCCESS:
      state = {};
      return state;
    case actions.CREATE_BOARD_FAILED:
      state = ReduxUtils.replace(state, `error`, action.error);
      return ReduxUtils.replace(state, `status`, 'FAILED');
    default:
      return state;
  }
};