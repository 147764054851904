import { getAuth, signInWithCustomToken, signOut, onAuthStateChanged } from 'firebase/auth';
import * as app from '../app/index.js';
import * as router from '../router/index.js';
import * as zoomOutView from '../zoomout-view';
import * as utils from '../../utils';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import { requestApi, isNetworkError } from '../../request-api';
import * as lastSeen from './last-seen-update-scheulder.js';
import { store } from '../../store';
import firestoreRedux from '@dreamworld/firestore-redux';

export const UPDATE_AUTH = 'UPDATE_AUTH';
export const LOGIN = 'AUTH_LOGIN';
export const LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const LOGIN_AND_SIGNUP_PAGE_OPENED = 'LOGIN_AND_SIGNUP_PAGE_OPENED';
export const LOGIN_AND_SIGNUP_PAGE_CLOSED = 'LOGIN_AND_SIGNUP_PAGE_CLOSED';
export const LINK_ADDITIONAL_AUTH = 'AUTH_LINK_ADDITIONAL_AUTH';
export const LINK_ADDITIONAL_AUTH_SUCCESS = 'AUTH_LINK_ADDITIONAL_AUTH_SUCCESS';
export const LINK_ADDITIONAL_AUTH_FAILED = 'AUTH_LINK_ADDITIONAL_AUTH_FAILED';

export const SIGNUP = 'AUTH_SIGNUP';
export const SIGNUP_FAILED = 'AUTH_SIGNUP_FAILED';
export const SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';

export const RESEND_VERIFICATION_EMAIL = 'AUTH_RESEND_VERIFICATION_EMAIL';
export const RESEND_VERIFICATION_EMAIL_FAILED = 'AUTH_RESEND_VERIFICATION_EMAIL_FAILED';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'AUTH_RESEND_VERIFICATION_EMAIL_SUCCESS';

export const GET_JOIN_SECRET_INFO = 'AUTH_GET_JOIN_SECRET_INFO';
export const GET_JOIN_SECRET_INFO_FAILED = 'AUTH_GET_JOIN_SECRET_INFO_FAILED';
export const GET_JOIN_SECRET_INFO_SUCCESS = 'AUTH_GET_JOIN_SECRET_INFO_SUCCESS';

export const SIGNUP_USING_JOIN_SECRET = 'AUTH_SIGNUP_USING_JOIN_SECRET';
export const SIGNUP_USING_JOIN_SECRET_FAILED = 'AUTH_SIGNUP_USING_JOIN_SECRET_FAILED';
export const SIGNUP_USING_JOIN_SECRET_SUCCESS = 'AUTH_SIGNUP_USING_JOIN_SECRET_SUCCESS';

export const STOP_IMPERSONATED_SESSIONS = 'AUTH_STOP_IMPERSONATED_SESSIONS';
export const STOP_IMPERSONATED_SESSIONS_FAILED = 'STOP_IMPERSONATED_SESSIONS_FAILED';
export const STOP_IMPERSONATED_SESSIONS_SUCCESS = 'STOP_IMPERSONATED_SESSIONS_SUCCESS';

export const LOGIN_AND_SIGNUP_DATA_RESET = 'AUTH_LOGIN_AND_SIGNUP_DATA_RESET';

/**
 * On success, sets app connection status to `true`, while on failure sets it to `false`
 * When request is failed or auth token is not found, sets auth to `null`.
 * @returns {String} Firebase auth token.
 */
const getFirebaseAuthToken = async () => {
  const excludeErrors = [502, 401];
  try {
    const { token } = await requestApi('/user/users/me/firebase-auth-token', { excludeErrors });
    return token;
  } catch (error) {
    if(isNetworkError(error) || excludeErrors.includes(error.status)) {
      return;
    }

    console.error("Failed to getting login user firebase auth-token due to this: ", error);
  }
};

/**
 * @returns {boolean} - True if the Firebase login process has completed successfully.
 */
const isFirebaseLoginCompleted = (user) => {
  if(!user) {
    return false;
  }

  let resolve;
  const promise = new Promise((res) => { resolve = res });

  const unsubscribe = onAuthStateChanged(getAuth(), (firebaseUser) => {
    unsubscribe();
    if(firebaseUser) {
      resolve(firebaseUser.uid === user.id);
    } else {
      resolve(false);
    }
  });

  return promise;
}

/**
 * Sign in using firebase auth token.
 * @param {String} token - Sign in into firebase auth using firebase-auth custom token
 */
export const signInIntoFirebaseAuth = async (user) => {
  if(await isFirebaseLoginCompleted(user)) {
    return;
  }

  const token = await getFirebaseAuthToken();
  if(!token) {
    return;
  }

  try {
    const auth = getAuth();
    await signInWithCustomToken(auth, token);
  } catch (error) {
    store.dispatch(updateAuth(null));
    const errorCode = error.code;
    const errorMessage = error.message;
    throw `Failed to signIn into firebase > code: ${errorCode}, message: ${errorMessage}`;
  }
};

/**
 * Fetch auth detail (User's basic detail and firebase auth token) using API
 * @retunr - Promise
 */
const getCurrentUserInfo = async () => {
  const excludeErrors = [502, 401];
  try {
    const user = await requestApi('/user/users/me', {
      excludeErrors,
    });
    return user;
  } catch (error) {
    //Do nothing if application is an under-maintenance.
    if (error && error.status == 502) {
      return;
    }
    store.dispatch(updateAuth(null));
  }
};

/**
 * Loads current user's owned account details.
 * @param {String} userId Current user Id.
 * @returns {Array} List of the accounts which is owned by current user. e.g. ['acc_3ETreVKhXOwIEjUfr8Ha0C']
 */
const getOwnedAccounts = async (userId) => {
  const query = firestoreRedux.query('accounts', {
    id: 'owned-accounts',
    where: [['ownerId', '==', userId]],
  });
  const owndAccounts = await query.result;
  const accId = get(owndAccounts, '0.id');
  return accId ? [accId] : [];
};

/**
 * Loads current user's accessible account details.
 * @param {String} userId User Id.
 * @returns {Object} List of accounts in which current user is part of the account team.
 *                    e.g. { 'acc_1FFQH6JrQCt9VlcrQha9gB': 'TEAM_MEMBER', 'acc_3ETreVKhXOwIEjUfr8Ha0C': 'VISITOR' }
 */
const getAccessibleAccounts = async (userId) => {
  const query = firestoreRedux.query('account-team-members', {
    id: 'accessible-accounts',
    where: [['userId', '==', userId]],
    requesterId: `account-team-members-${userId}`
  });
  const array = await query.result;
  let accessibleAccounts = {};
  forEach(array, (doc) => {
    accessibleAccounts[doc.accountId] = doc.role;
  });
  return accessibleAccounts;
};

/**
 * Loads current user's `user-ui` data.
 * @param {String} userId Current user ID.
 * @returns {String} Last used account ID. e.g. `acc_3ETreVKhXOwIEjUfr8Ha0C`
 */
const getLastAccessedAccount = async (userId) => {
  // Current user's user-ui data.
  const query = firestoreRedux.query('user-ui', {
    where: [['userId', '==', userId]],
    requesterId: `user-ui-${userId}`,
  });
  const result = await query.result;
  return get(find(result, { type: 'last-accessed-account' }), 'accountId');
};

/**
 * Loads current user's accessible boards.
 * @param {String} userId Current user Id.
 * @returns {Object}
 */
const getAccessibleBoards = async (userId) => {
  const query = firestoreRedux.query('user-accessible-boards', {
    id: 'user-accessible-boards',
    where: [['userId', '==', userId]],
  });
  const boards = await query.result;
  let accessibleBoards = {};
  forEach(boards, (board) => {
    if (!accessibleBoards[board.accountId]) {
      accessibleBoards[board.accountId] = {};
    }
    accessibleBoards[board.accountId][board.boardId] = board.type;
  });
  return accessibleBoards;
};

/**
 * Updates current user's timezone
 */
const updateTimeZone = async () => {
  try {
    const excludeErrors = [502, 401, 304];
    requestApi('/user/users/me/timezone', {
      method: 'PUT',
      body: {
        timezoneOffset: new Date().getTimezoneOffset(),
        timezone: utils.getTimezoneID(),
      },
      excludeErrors,
    });
  } catch (error) {
    if (isNetworkError(error) || excludeErrors.includes(error.status)) {
      return;
    }
    throw `Failed to update timeZone: ${error}`;
  }
};

/**
 * Do logout from firebase auth and reset user data from local storage and redux store.
 * Here, we can't refresh a router because router internally refresh when auth data is changed.
 */
const onAuthenticationDenined = async () => {
  _stopLastSeenUpdateScheduler(false);
  document.removeEventListener("visibilitychange", documentVisibilityChange);
  store.dispatch(updateAuth(null));
};

/**
 * Sign out from firebase auth.
 */
const signOutFromFirebaseAuth = async () => {
  const auth = getAuth();
  return signOut(auth);
};

/**
 * Gets firebase auth token from the server.
 * Sign In with Firebase
 * Gets Current user details from the server & stores it in redux store.
 * Updates timezone of the current logged in user.
 * Starts last seen scheduler.
 */
export const init = async () => {
  bindFirebaseAuthStateChanged();
  
  //Listen on authenticatin denied action to reset previous authentication state.
  window.addEventListener('authentication-denied', onAuthenticationDenined);

  const user = await getCurrentUserInfo();
  const userId = user && user.id;
  if(!userId) {
    _stopLastSeenUpdateScheduler();
    return;
  }

  await signInIntoFirebaseAuth(user);
  try {
    // Reads user's attrs.
    firestoreRedux.getDocById('users', userId);

    // Load user-ui details
    firestoreRedux.query('user-ui', {
      where: [['userId', '==', userId]],
      requesterId: `user-ui-${userId}`,
    });
  } catch (error) {
    console.error(`Failed to load ${userId} user details due to this: `, error);
  }

  const userIdWOPrefix = utils.getIdWoPrefix({id: userId, prefix: 'usr_'});
  try {
    const userPreferencesReq = firestoreRedux.getDocById(`users/${userId}/user-preferences`, `up_${userIdWOPrefix}`);
    await userPreferencesReq.result;
  } catch (error) {
    console.error(`Failed to load ${userId} user-preference due to this: `, error);
  }

  try {
    const useruPropertiesReq = firestoreRedux.getDocById(`users/${userId}/user-properties`, `ups_${userIdWOPrefix}`);
    await useruPropertiesReq.waitTillResultAvailableInState();
  } catch (error) {
    console.error(`Failed to load ${userId} user-properties due to this: `, error);
  }

  const [ownedAccounts, accessibleAccounts, lastAccessibleAccount, accessibleBoards] = await Promise.all([
    await getOwnedAccounts(userId),
    await getAccessibleAccounts(userId),
    await getLastAccessedAccount(userId),
    await getAccessibleBoards(userId),
  ]);

  store.dispatch(
    updateAuth({
      user,
      ownedAccounts,
      accessibleAccounts,
      lastAccessibleAccount,
      accessibleBoards,
    })
  );
  
  if(user && !user.impersonated) {
    _startLastSeenUpdateScheduler();
    document.addEventListener("visibilitychange", documentVisibilityChange);
    await updateTimeZone();
  }
};

/**
 * Binds the Firebase authentication state change event and handles the case where the user is automatically logged out.
 * 
 * This function listens for changes in the Firebase authentication state. If the user is
 * automatically logged out then logs an error and signs the user back into Firebase authentication.
*/
let isManualLogout = false;
let lastFirebaseAuthUser = null;
const bindFirebaseAuthStateChanged = () => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if(!isManualLogout && !user && lastFirebaseAuthUser) {
      console.error('Firebae user is auto logged out');
      signInIntoFirebaseAuth();
    }
    lastFirebaseAuthUser = user;
  });
};

const _startLastSeenUpdateScheduler = () => {
  const state = store.getState();
  const user = get(state, `auth.user`) || {};
  if(isEmpty(user)) {
    return;
  }

  lastSeen.startLastSeenUpdateScheduler();
}

const _stopLastSeenUpdateScheduler = (trackLastSeen) => {
  lastSeen.stopLastSeenUpdateScheduler(trackLastSeen);
}

const  documentVisibilityChange = () => {
  const visibility = document.visibilityState;
  if(visibility === 'visible') {
    _startLastSeenUpdateScheduler();
  }
  if(visibility === 'hidden') {
    _stopLastSeenUpdateScheduler(true);
  }
}

/**
 * Remove local storage data.
 */
const removeLocalStorageData = () => {
  const localStorageKeys = [
    'last-visited-page',
    'amplitude-login-tracked',
    'auth-service',
    'browsefilters',
    'last-background-color',
    'is-invited-user',
    'pwa-entity-ids'
  ];
  const values = (localStorage && Object.keys(localStorage)) || [];
  values.forEach((key) => {
    if (
      key.startsWith('cardDescription.') ||
      key.startsWith('chat.') ||
      key.startsWith('boardScroll.') ||
      key.startsWith('background-color.') ||
      key.startsWith('cardTitle.') ||
      localStorageKeys.includes(key)
    ) {
      localStorage.removeItem(key);
    }
  });
};

const deleteCookie = (name) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * Remove cookies.
 */
const removeCookieData = () => {
  const cookieKeys = [];

  cookieKeys.forEach((key) => {
    deleteCookie(key);
  });
}

/**
 * Logout from firebase auth and reset auth data in redux state and local storage
 * Logout user from auth and webapp to using end-point
 */
export const logout = (data) => async (dispatch) => {
  console.log('Logout Started: ', data);
  data = data || {};
  const successURL = data.successURL || '';
  const replace =  data.replace || false;
  const skipNavigation = data.skipNavigation || false;
  isManualLogout = true;
  try {
    removeLocalStorageData();
    removeCookieData();
    await signOutFromFirebaseAuth();
    await requestApi('/user/logout');
    dispatch(zoomOutView.actions.reset());
    if(!skipNavigation) {
      dispatch(updateAuth(null));
      router.actions.navigate(successURL || '/', replace);
      setTimeout(() => {
        window.location.reload();
      }, 0);
    }
    console.log('Logout completed');
  } catch (error) {
    console.error("Logout failed due to this: ", error);
  }
};

/**
 *
 * @param {Object} auth - Auth detail
 * @retur {Object} - Redux 'UPDATE_AUTH' action to update auth detail
 */
export const updateAuth = (auth) => {
  return {
    type: UPDATE_AUTH,
    auth,
  };
};

/**
 * Login into application for given `service`.
 */
export const login = ({ service, email, password, autoLogin = false }) => {
  return {
    type: LOGIN,
    service,
    email,
    password,
    autoLogin,
  };
};

/**
 * Based on installed app / PWA, reuqests server to link additional auth.
 * When PWA
 *  - Does oauth2Login through child window.
 * When Installed App
 *  - Does installedAppLogin
 * On success/failure, dispatches `LINK_ADDITIONAL_AUTH_SUCCESS`/LINK_ADDITIONAL_AUTH_FAILED actions.
 * @param {String} service Service for which additional auth is requested. Possible values: 'google' or 'box' or 'microsoft'
 */
export const linkAdditionalAuth = (service) => {
  return {
    type: LINK_ADDITIONAL_AUTH,
    service,
  };
};

/**
 * @param {String} service Service for which additional auth is requested. Possible values: 'google' or 'box' or 'microsoft'
 */
export const _linkAdditionalAuthSuccess = (service) => {
  return {
    type: LINK_ADDITIONAL_AUTH_SUCCESS,
    service,
  };
};

/**
 * @param {String} service Service for which additional auth is requested. Possible values: 'google' or 'box' or 'microsoft'
 * @param {String} error Error Code.
 */
export const _linkAdditionalAuthFailed = (service, error) => {
  return {
    type: LINK_ADDITIONAL_AUTH_FAILED,
    service,
    error,
  };
};

/**
 * Signup into application for given `service`.
 * @param {String} service signup using this service. Possible values: 'google' or 'box' or 'kerika' or 'microsoft'.
 */
export const signup = ({ service, firstName, lastName, email, password }) => {
  return {
    type: SIGNUP,
    service,
    firstName,
    lastName,
    email,
    password,
  };
};

export const _signupFailed = ({ service, error, message }) => {
  return {
    type: SIGNUP_FAILED,
    service,
    error,
    message,
  };
};

export const _signupSuccess = ({ service, requestId }) => {
  return {
    type: SIGNUP_SUCCESS,
    service,
    requestId,
  };
};

/**
 * Signup into application for given `service`.
 * @param {String} service signup using this service. Possible values: 'google' or 'box' or 'kerika' or 'microsoft'.
 */
export const signupUsingJoinSecret = ({ service, firstName, lastName, password, secret, email }) => {
  return {
    type: SIGNUP_USING_JOIN_SECRET,
    service,
    firstName,
    lastName,
    secret,
    password,
    email,
  };
};

export const _signupUsingJoinSecretFailed = ({ service, error, message }) => {
  return {
    type: SIGNUP_USING_JOIN_SECRET_FAILED,
    service,
    error,
    message,
  };
};

export const _signupUsingJoinSecretSuccess = ({ service, email, password }) => {
  return {
    type: SIGNUP_USING_JOIN_SECRET_SUCCESS,
    service,
    email,
    password,
  };
};

/**
 * Dispatch this action to re-send kerika verification email to user.
 */
export const resendVerificationEmail = () => {
  return {
    type: RESEND_VERIFICATION_EMAIL,
  };
};

export const _resendVerificationEmailFailed = (error) => {
  return {
    type: RESEND_VERIFICATION_EMAIL_FAILED,
    error,
  };
};

export const _resendVerificationEmailSuccess = () => {
  return {
    type: RESEND_VERIFICATION_EMAIL_SUCCESS,
  };
};

/**
 * @param {String} joinSecret
 */
export const getJoinSecretInfo = (joinSecret) => {
  return {
    type: GET_JOIN_SECRET_INFO,
    joinSecret,
  };
};

export const _getjoinSecretInfoSuccess = (data) => {
  return {
    type: GET_JOIN_SECRET_INFO_SUCCESS,
    data,
  };
};

export const _getjoinSecretInfoFailed = (error) => {
  return {
    type: GET_JOIN_SECRET_INFO_FAILED,
    error,
  };
};

/**
 * Dispatch this action to stop impersonate session.
 */
export const stopImpersonatedSessions = () => {
  return {
    type: STOP_IMPERSONATED_SESSIONS
  }
}

export const _stopImpersonatedSessionsSuccess = () => {
  return {
    type: STOP_IMPERSONATED_SESSIONS_SUCCESS
  }
}

export const _stopImpersonatedSessionsFailed = (error) => {
  return {
    type: STOP_IMPERSONATED_SESSIONS_FAILED,
    error
  }
}

/**
 * Reset login and signup data.
 */
export const resetLoginAndSignupData = () => {
  return {
    type: LOGIN_AND_SIGNUP_DATA_RESET
  }
}