import get from 'lodash-es/get';
import last from 'lodash-es/last';
import sortBy from 'lodash-es/sortBy';
import entityIdProvider from '../../entity-id-provider';
import { all, call, put, select, takeEvery, delay } from 'redux-saga/effects';
import { requestApi, isNetworkError } from '../../request-api';
import { getUserName } from '../../components/utils.js';

import firestoreRedux from '@dreamworld/firestore-redux';

import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as signup from '../signup';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import * as amplitude from '../../analytics/amplitude.js';
import * as knownFeaturesActions from '../known-features/actions.js';

function* createBoard() {
  try {
    const accountId = yield call(createAccount);
    yield delay(1000);
    const boardId = yield call(createNewBoard, accountId);
    yield call(createCards, boardId);
    yield put(signup.actions.updateSignupDetails('FRESH_USER', {accountId, boardId}));
    yield call(openBoard, { boardId, accountId }); 
    yield put(actions._createBoardSuccess());
  } catch (error) {
    yield put(actions._createBoardFailed());
    console.error("fresh-user-wizard > setup: is failed due to this: ", error);
  }
}

function* createAccount() {
  const state = yield select();
  const user = auth.selectors.currentUser(state);
  const name = user ? `${getUserName(user)}'s Account` : '';
  const ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
  let accountId = ownedAccounts && ownedAccounts[0];
  const accessibleAccounts = auth.selectors.accessibleAccounts(state);
  if (!accountId) {
    const response = yield call(requestApi, `/account/accounts`, {
      method: 'POST',
      body: { name },
      excludeErrors: [409],
    });
    accountId = response.id;
    amplitude.logEvent('account created', {
      account_id: accountId,
      joined_accounts: (accessibleAccounts && accessibleAccounts.length) || 0,
    });
  }
  return accountId;
}

/**
 * Send request to create a new Board.
 */
function* createNewBoard(accountId) {
  const state = yield select();
  const templateId = app.selectors.defaultTemplateTaskBoardId(state);
  const name = selectors.boardName(state);
  const templateDetailsReq = firestoreRedux.getDocById('boards', templateId, { once: true, waitTillSucceed: true });
  const templateDetails = yield templateDetailsReq.result;
  const boardPrivacy = yield call(getBoardPrivacy, accountId);

  const createBoardEventDetails = {
    template_id: templateId,
    template_name: templateDetails && templateDetails.privacy === 'PUBLIC' ? templateDetails.name : 'NA',
    is_template_from_template_account: true,
    type: 'TASKBOARD',
    privacy: boardPrivacy,
    in_own_account: true,
  };

  amplitude.logEvent('board created', createBoardEventDetails);
  const body = { name, accountId, privacy: boardPrivacy, lang: 'en' };
  const response = yield call(requestApi, `/board/boards/${templateId}`, { method: 'POST', body });
  return response.id;
}

function* createCards(boardId) {
  const state = yield select();
  const cards = selectors.cards(state);
  const columnId = yield call(getColumnId, boardId);
  const eventProperties = {
    'board_id': boardId,
    'role': 'BOARD_ADMIN',
    'effective_role': 'BOARD_ADMIN'
  }

  const allPromises = [];
  for(let key in cards) {
    const card = cards[key];
    if(card) {
      amplitude.updateCardsAddedUserProperty(1);
      amplitude.logBoardEvent('card added', eventProperties);
      const body = {
        id: card.id,
        title: card.title,
        boardId,
        columnId,
        columnOrder: card.columnOrder,
        status: 'NORMAL',
        priority: 'NORMAL'
      };
  
      const promises = requestApi('/card/cards', {method: 'POST', body});
      allPromises.push(promises);
    }
  }
  yield Promise.all(allPromises);
}

function* getColumnId(boardId) {
  const columnsquery = firestoreRedux.query('columns', { where: [['boardId', '==', boardId]], waitTillSucceed: true, once: true });
  let columns = yield columnsquery.result;
  columns = sortBy(columns, ['order', 'name'])
  return get(columns, '0.id');
}

function* addNewCard(action) {
  const state = yield select();
  try {
    const id = `crd_${entityIdProvider.getId()}`;
    const cards = selectors.cards(state);
    const _last = last(cards);
    const lastColumnOrder = (_last && _last.columnOrder) || 0;
    const columnOrder = lastColumnOrder + 1000;
    yield put(actions._addNewCardWithOrder({ id, title: action.title, columnOrder }));
    yield put(knownFeaturesActions.markAsKnown('ADD_NEW_CARD'));
  } catch (error) {
    console.error('fresh-user-wizard > addNewCard: failed due to this: ', error);
  }
}

function* openBoard({boardId, accountId}) {
    //Wait for board & it's member's data is ready.

    // const boardQuery = firestoreRedux.getDocById('boards', boardId, { waitTillSucceed: true, once: true });
    // const membersQuery = firestoreRedux.query('board-team-members', { where: [['boardId', '==', boardId]], waitTillSucceed: true, once: true });
    // const userAccessibleBoardsQuery = firestoreRedux.query('user-accessible-boards', { where: [['userId', '==', userId], ['accountId', '==', accountId], ['boardId', '==', boardId]], waitTillSucceed: true, once: true });
  
    // yield boardQuery.result;
    // yield membersQuery.result;
    // yield userAccessibleBoardsQuery.result;
    router.actions.navigate(`/${accountId}/board/${boardId}`, true);
}

function* getBoardPrivacy(accountId) {
  if(accountId) {
    try {
      const accSettingsReq = firestoreRedux.getDocById('account-settings', `as_${accountId.replace('acc_', '')}`, { once: true });
      const accSettings = yield accSettingsReq.result;
      return get(accSettings, 'boardPrivacy');
    } catch (error) {}
  }

  try {
    const defaultAccSettingsReq = firestoreRedux.getDocById('account-settings', `default`, { once: true });
    const defaultAccSettings = yield defaultAccSettingsReq.result;
    return get(defaultAccSettings, 'boardPrivacy');
  } catch (error) {}

  return 'PRIVATE';
}

/**
 * Init Saga.
 */
function* freshUserWizardSaga() {
  yield all([
    takeEvery(actions.CREATE_BOARD, createBoard), 
    takeEvery(actions.ADD_NEW_CARD, addNewCard)
  ]);
}

export default freshUserWizardSaga;
