
import { html, css } from 'lit-element';
import { KerikaCompositeDialog } from '../kerika-composite-dialog.js';
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';
import typographyLiterals from "../../theme/typography-literals.js";

import * as signup from '../../redux/signup';
import * as boardExplore from '../../redux/board-explorer';

/**
 * ## Purpose
 *  - Provides way to shows a create board in progress view.
 *
 *  Usage pattern:
 *  - <create-board-in-progress-dialog></create-board-in-progress-dialog>
 * @class
 */

class CreateBoardInProgressDialog extends localize(i18next)(KerikaCompositeDialog) {
  static get styles() {
    return [
      super.styles,
      css `
        :host([type="fit"]) #dialog-content {
          padding: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        :host([type="fit"]) .close-header .title {
          display: flex;
          flex: 1;
          justify-content: center;
          text-transform: unset;
        }

        :host([type="fit"]) .close-header {
          padding-top: 32px;
          height: 72px;
          display: none;
        }

        :host([type="fit"]) .close-header .title {
          ${typographyLiterals.headline5};
          padding-right: 32px;
        }

        :host([type="fit"]) .close-header kerika-icon-button[icon="close"] {
          position: absolute;
          top: 24px;
          right: 8px;
        }

        :host([type="fit"]) {
          ${typographyLiterals.body1};
        }

        :host([type="fit"]) .message {
          margin-top: 24px;
          text-align: center;
        }

        :host([type="fit"]) #dialog-content h5 {
          color: var(--mdc-theme-secondary);
          margin-top: 0px;
          margin-bottom: 0px;
        }
      `
    ]
  }
  constructor() {
    super();
    this.i18nextNameSpaces = ['board-page'];
    this.title = "Please wait...";
    this.noCancelOnOutsideClick =  true;
    this.noCancelOnEscKey = true;
    this.closeIconDisabled =  true;
    this.closeIconHide = true;
    this.largeCloseIcon = true;
  }

  static get properties() {
    return {
      /**
       * Shows dialog message or not.
       */
      showMessage: { type: Boolean }
    }
  }

  get _contentTemplate() {
    return html`
      ${this.type === 'fit' ? html`
        <h5>${i18next.t('board-page:autoCreatingBoard.title')}</h5>
      `: ''}

      ${this.showMessage ? html`
        <div class="message">${i18next.t('board-page:autoCreatingBoard.message')}</div>
      `: ''}
    `;
  }

  /**
   * Invoked When language is changed.
   * @override
   */
  _setLanguage(newLanguage) {
    super._setLanguage && super._setLanguage(newLanguage);
    if(this.type !== 'fit') {
      this.title = i18next.t('board-page:autoCreatingBoard.title');
    }
  }
}

window.customElements.define('create-board-in-progress-dialog', CreateBoardInProgressDialog);
